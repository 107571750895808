import React from "react";
import { Tooltip } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { invalidEvtImg } from "../../../events/src/assets";
interface Props {
    id: number | string;
    image: string | null;
    title: string;
    time?: string,
    location?: string,
    price?: string,
    isMember?: boolean;
    type?: "event" | "group";
    description?: string;
    groupType?: string;
}


const ExploreCard: React.FC<Props> = (props: Props) => {
    const { title, image, location, time, price, isMember, type, description, groupType } = props;

    return (
        <StyledExploreCard className="Explore_StyledExploreCard">
            {
                isMember && (
                    <div className="member">Member</div>
                )
            }
            {
                image && (
                    <img src={image} alt="" />
                )
            }
            {
                !image && type === "event" && (
                    <img src={invalidEvtImg} alt="invalid event img" />
                )
            }
            <Tooltip title={title} placement="top">
                <p className="title ellipsis">{title}</p>
            </Tooltip>
            {
                time && (
                    <p className="time">
                        <div>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.81473 9.51922L9.51727 8.81669L6.99932 6.29857V3.16795H5.99935V6.70385L8.81473 9.51922ZM6.50045 12.8346C5.62449 12.8346 4.80114 12.6684 4.03038 12.3359C3.25962 12.0035 2.58916 11.5523 2.01902 10.9824C1.44886 10.4125 0.997488 9.74237 0.664899 8.97195C0.33231 8.20154 0.166016 7.37836 0.166016 6.5024C0.166016 5.62645 0.332238 4.80309 0.664682 4.03234C0.997127 3.26157 1.44829 2.59111 2.01818 2.02097C2.58808 1.45081 3.25824 0.999442 4.02867 0.666853C4.79908 0.334264 5.62226 0.167969 6.49822 0.167969C7.37417 0.167969 8.19753 0.334192 8.96828 0.666636C9.73905 0.999081 10.4095 1.45025 10.9796 2.02014C11.5498 2.59004 12.0012 3.2602 12.3338 4.03062C12.6664 4.80103 12.8327 5.62421 12.8327 6.50017C12.8327 7.37612 12.6664 8.19948 12.334 8.97023C12.0015 9.741 11.5504 10.4115 10.9805 10.9816C10.4106 11.5518 9.74042 12.0031 8.97 12.3357C8.19959 12.6683 7.3764 12.8346 6.50045 12.8346ZM6.49933 11.8346C7.97711 11.8346 9.23544 11.3152 10.2743 10.2763C11.3132 9.2374 11.8327 7.97906 11.8327 6.50129C11.8327 5.02351 11.3132 3.76517 10.2743 2.72629C9.23544 1.6874 7.97711 1.16795 6.49933 1.16795C5.02155 1.16795 3.76322 1.6874 2.72433 2.72629C1.68544 3.76517 1.166 5.02351 1.166 6.50129C1.166 7.97906 1.68544 9.2374 2.72433 10.2763C3.76322 11.3152 5.02155 11.8346 6.49933 11.8346Z" fill="white" fill-opacity="0.8" />
                            </svg>
                        </div>

                        <Tooltip title={time} placement="top">
                            <span className="ellipsis">{time}</span>
                        </Tooltip>
                    </p>
                )
            }
            {
                location && (
                    <p className="time">
                        <div>
                            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.00078 11.5103C7.30505 10.3428 8.30313 9.22289 8.99502 8.15065C9.68689 7.07843 10.0328 6.13932 10.0328 5.33334C10.0328 4.11796 9.64672 3.11881 8.8745 2.3359C8.10228 1.55299 7.14437 1.16154 6.00078 1.16154C4.85719 1.16154 3.89929 1.55299 3.12707 2.3359C2.35484 3.11881 1.96873 4.11796 1.96873 5.33334C1.96873 6.13932 2.31467 7.07843 3.00655 8.15065C3.69844 9.22289 4.69652 10.3428 6.00078 11.5103ZM6.00078 12.4615C5.87087 12.4615 5.74096 12.4391 5.61105 12.3942C5.48114 12.3493 5.36362 12.2799 5.25848 12.1859C4.66021 11.6346 4.10038 11.0669 3.57902 10.4827C3.05765 9.89851 2.60444 9.31454 2.2194 8.73079C1.83436 8.14702 1.52966 7.56839 1.3053 6.99489C1.08093 6.42137 0.96875 5.86752 0.96875 5.33334C0.96875 3.79489 1.4664 2.54939 2.4617 1.59682C3.45699 0.644252 4.63668 0.167969 6.00078 0.167969C7.36488 0.167969 8.54458 0.644252 9.53987 1.59682C10.5352 2.54939 11.0328 3.79489 11.0328 5.33334C11.0328 5.86752 10.9206 6.42031 10.6963 6.99169C10.4719 7.56305 10.1683 8.14169 9.78537 8.72759C9.40247 9.31347 8.95033 9.89745 8.42897 10.4795C7.9076 11.0615 7.34777 11.6282 6.74948 12.1795C6.64589 12.2735 6.52818 12.344 6.39633 12.391C6.26448 12.438 6.13263 12.4615 6.00078 12.4615ZM6.00192 6.41154C6.33364 6.41154 6.61723 6.29342 6.8527 6.0572C7.08817 5.82098 7.2059 5.53701 7.2059 5.20529C7.2059 4.87356 7.08779 4.58997 6.85157 4.3545C6.61534 4.11904 6.33137 4.0013 5.99965 4.0013C5.66793 4.0013 5.38433 4.11941 5.14887 4.35564C4.9134 4.59186 4.79567 4.87583 4.79567 5.20755C4.79567 5.53927 4.91378 5.82287 5.15 6.05834C5.38622 6.2938 5.67019 6.41154 6.00192 6.41154Z" fill="white" fill-opacity="0.8" />
                            </svg>
                        </div>
                        {location}
                    </p>
                )
            }
            {
                price && (
                    <p className="price">{price}</p>
                )
            }
            {
                description && (
                    <p className="description ellipsis">{description}</p>
                )
            }

            {
                groupType && (
                    <p className="description ellipsis">{groupType}</p>
                )
            }
        </StyledExploreCard>
    )
}

const StyledExploreCard = styled("div")({
    "&.Explore_StyledExploreCard": {
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "1rem 1rem",
        boxSizing: "border-box",
        position: "relative",
        overflow: "hidden",
        cursor: "pointer",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid #19B5B945",
        borderRadius: "1rem",
        boxShadow: "0px 0px 16px 0px #0000001F",
        "& > img": {
            width: "6.5rem",
            height: "6.5rem",
            borderRadius: "1rem",
            marginBottom: "0.5rem",
        },
        "& p": {
            textAlign: "center",
            marginBottom: "0.3rem",
            maxWidth: "100%",
            "&:last-child": {
                marginBottom: 0
            },
        },
        "& .title": {
            color: "#FFFFFF",
            fontSize: "1.125rem",
            fontWeight: 500
        },
        "& .time": {
            color: "#FFFFFFCC",
            fontSize: "0.875rem",
            display: "flex",
            alignItems: "center",
            gap: "0.3rem",
            fontWeight: 400
        },
        "& .price": {
            background: "rgba(255, 255, 255, 0.12)",
            fontSize: "0.875rem",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 0.8)",
            padding: "0.3rem 0.7rem",
            borderRadius: "1rem",
        },
        "& .member": {
            position: "absolute",
            background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
            color: "rgba(255, 255, 255, 1)",
            fontSize: "0.5rem",
            fontWeight: 700,
            top: 0,
            left: 0,
            transform: "rotate(-45deg) translate(-27%,-70%)",
            width: "5.5rem",
            height: "1.125rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        "& .description":{
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.8)"
        }
    }
})

export default ExploreCard;