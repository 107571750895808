// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    isDatePickerOpen: boolean;
    currentDate: Date;
    routinePageNum: number;
    isAllRoutineFetch: boolean;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export interface IRoutine {
    id: number;
    formattedDate: string;
    items: IRoutineItem[]
}
export interface IRoutineItem {
    title: string;
    description: string;
    recurrence: string;
    start_time: string;
    end_time?: string;
    completed?: boolean;
    lapsed?: boolean;
}

export const routines: IRoutine[] = [
    {
        id: 1,
        formattedDate: "Today / Monday",
        items: [
            {
                title: "Landing Page Design",
                description: "It is about discussion of the  features. It is about discussion of the  features. ",
                recurrence: "Daily",
                start_time: "11:00 AM",
                end_time: "12:00 PM",
            },
            {
                title: "Daily Project",
                description: "It is about discussion of the  features. It is about discussion of the  features. ",
                recurrence: "Daily",
                start_time: "12:30 PM",
                end_time: "01:30 PM"
            }
        ]
    },
    {
        id: 2,
        formattedDate: "16 Apr / Tuesday",
        items: [
            {
                title: "Task Team Meeting",
                description: "It is about discussion of the  features. It is about discussion of the  features. ",
                recurrence: "Weekly",
                start_time: "01:30 PM",
                end_time: "02:30 PM",
                completed: true
            },
            {
                title: "Daily Project",
                description: "It is about discussion of the  features. It is about discussion of the  features. ",
                recurrence: "Annual",
                start_time: "12:30 PM",
                lapsed: true
            }
        ]
    }
]

export default class RoutineController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            isDatePickerOpen: false,
            currentDate: new Date(),
            routinePageNum: 1,
            isAllRoutineFetch: false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }


    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        // Customizable Area End
    };

    // Customizable Area Start
    goToSelectedDate = (selectedDate: Date | null) => {
        if (selectedDate) {
            this.setState({
                currentDate: selectedDate,
                routinePageNum: 1,
                isAllRoutineFetch: false,
                isDatePickerOpen: false
            })
        }
    }

    openDatePicker = () => {
        this.setState({
            isDatePickerOpen: true
        })
    }
    closeDatePicker = () => {
        this.setState({
            isDatePickerOpen: false
        })
    }
    
    redirectTo = (destination: string)=>{
        this.props.navigation.navigate(destination)
    }
    // Customizable Area End
}