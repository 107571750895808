import React, {  useRef, useState } from "react";
import { styled } from "@material-ui/styles";
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import EmojisPicker from "../../../emojis/src/EmojisPicker.web";
import { IconButton } from "@material-ui/core";
import { Mention, MentionsInput } from "react-mentions";
import { IMentionInputType } from "../../../../components/src/CommonTypes";
import { Button } from "../../../../components/src";
const {
    HelperFunctions: helper,
  } = require("../../../../components/src/HelperFunctions");

interface Props {
    onAddReply?:(event:string)=> void;
    replyMentionsList?: IMentionInputType[];
    fetchMentionsUsersList?: (query: string) => void;
    parentId: string;
}

const ReplyTextfield: React.FC<Props> = (props: Props) => {
    const { onAddReply, replyMentionsList=[], parentId } = props;
    const [text, setText] = useState("");
    const [emojiButtonElement, setEmojiButtomElement] = useState<HTMLButtonElement | null>(null);
    const replyFieldRef = useRef<HTMLInputElement | null>(null);

    const onAddReplyHandler = () => {
        if (!text || text.trim() === "") return;
        if (onAddReply) {
            const cleanedText = text.replace(/@\[(.*?)\]\(\d+\)/g, '@$1');
            onAddReply(cleanedText);
        }
        setText("")
    }

    const handleKeyPressCommentReplyField = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            onAddReplyHandler()
        }
    }

    const onEmojiClick = (event: React.MouseEvent, emojiObject: { emoji: string }) => {
        setText((prevText) => prevText + emojiObject.emoji); // Add emoji to text state
    };

    const toggleEmojiPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEmojiButtomElement(event.currentTarget)
    };

    const onCloseEmojiPicker = () => {
        setEmojiButtomElement(null);
        setTimeout(() => {
            if (replyFieldRef.current) {
                replyFieldRef.current.focus();
            }
        }, 10);
    }

    return (
        <StyledReplyTextfield className="ReplyTextfield_StyledReplyTextfield">
            <MentionsInput
                value={text}
                autoComplete="off"
                inputRef={replyFieldRef}
                onChange={event => {
                    const sanitizedReplyValue = event.target.value.replace(/^\s+/, '');
                    const parsedValue = helper.parseEmojis(sanitizedReplyValue);
                    setText(parsedValue);
                }}
                onKeyDown={(event)=> event.stopPropagation()}
                id={`comment_reply_text_field_${parentId}`}
                onKeyPress={handleKeyPressCommentReplyField}
                placeholder="Write a reply"
                maxLength={240}
                className="reply-mention-input"
                aria-multiline
                spellCheck
                style={{
                    control: {
                        color: '#fff',
                    },
                    highlighter: {
                        color: '#fff',
                    },
                    input: {
                        color: '#fff',
                    },
                }}
            >
                <Mention
                    data={replyMentionsList}
                    trigger="@"
                    className="mentions__mention mention-highlight"
                    style={{
                        backgroundColor: 'rgba(247, 83, 103, 1)',
                    }}
                    displayTransform={(_, display) => `@${display}`}
                    appendSpaceOnAdd
                />
            </MentionsInput>
            <div className="reply-actions">
                <IconButton id="comment-emoji-picker" onClick={toggleEmojiPicker}>
                    <InsertEmoticonIcon className="insert-emoticon" />
                </IconButton>
                <EmojisPicker emojiRefElement={emojiButtonElement} onClose={() => onCloseEmojiPicker()} onEmojiClick={onEmojiClick} />
                <Button
                    text={"Reply"}
                    buttonStyles={{
                        width: "90px",
                        height: "40px",
                        background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
                        color: "rgba(255, 255, 255, 0.75)"
                    }}
                    onClick={onAddReplyHandler}
                    disabled={false}
                />
            </div>
        </StyledReplyTextfield>
    )
}

const StyledReplyTextfield = styled("div")({
  "&.ReplyTextfield_StyledReplyTextfield":{
    display: "flex",
    flexDirection: "column",
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid rgba(25, 181, 185, 0.27)",
    borderRadius: "14px",
    padding: "0rem 1rem",
    width: "91%",
    boxSizing: "border-box",
    height: "125px",
    flexShrink: 0,
    "& .reply-mention-input": {
        maxHeight: "100px",
        overflow: "auto",
        width: "99%",
        height: "65px",
        "& div": {
            height: "inherit",
        }
    },
    "& .reply-mention-input__highlighter": {
        padding: "1px 1px",
    },
    "& .reply-mention-input__suggestions__item--focused": {
        background: "#2d4953"
    },
    "& .reply-mention-input__suggestions": {
        backgroundColor: "#19282F !important",
        minHeight: "50px",
        borderRadius: "8px",
        border: "1px solid rgba(255, 255, 255, 0.8) !important",
        minWidth: "150px !important",
        padding: "5px 0px",
        color: "rgba(255, 255, 255, 0.8)",
        maxWidth: "300px",
        overflowY: "scroll",
        marginTop: "0px !important",
        maxHeight: '54px',
        top: "4px !important",
        "& ul": {
            overflow: "scroll",
            height: "100%",
        },
        "& li": {
            borderBottom: "1px solid #2d4953",
            padding: "5px 10px",
            "&:hover": {
                background: "#2d4953"
            },
        },
        "& li:last-child": {
            borderBottom: "none",
        }
    },
    "& textarea":{
        width: "100% !important",
        fontSize: "1rem !important",
        border: "none",
        maxHeight: "100px",
        overflow: "auto !important",
        fontWeight: 400,
        outline: "none",
        background: "transparent",
        '&::placeholder': {
            color: "rgba(255, 255, 255, 0.8)",
        },
    },
    "& .reply-actions":{
        padding: "6px 0px",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        "& > img":{
            cursor: "pointer"
        },
        "& > button":{
            padding: "0px"
        },
        "& .insert-emoticon": {
            color: "rgb(255 255 255 / 65%)"
        }
    }
  }
});

export default ReplyTextfield;