import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Moment } from "moment/moment";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions")
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config.js");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  id: string | number;
  token: string;
  reminderDate: string;
  reminderTime: string;
  isFormTouched: boolean;
  description: string;
  frequency: string;
  title: string;
  editMode: boolean;
  isFormUpdated: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddTaskController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createReminderApiCallId: string = "";
  getReminderDetailApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: 0,
      token: "",
      reminderDate: "",
      reminderTime: "",
      isFormTouched: false,
      description: "",
      frequency: "5",
      title: "",
      editMode: false,
      isFormUpdated: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if(!token){
        token = await getStorageData("authToken");
      }
      this.setToken(token)
    }
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id){
      if(this.createReminderApiCallId === apiCallId){
        this.handleAddReminder(message);
      }else if(this.getReminderDetailApiCallId === apiCallId){
        this.handleGetReminder(message);
      }
    }
    // Customizable Area End
  };

  // Customizable Area Start
  handleAddReminder = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data && !responseJson.errors) {
      const successMsg = this.state.editMode ?  "Reminder successfully updated!": "Reminder successfully created!";
      helper.showSuccessToast(successMsg);
      this.redirectTo("Reminders");
    } else {
      const toastMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      helper.showErrorToast(toastMsg);
    }
    helper.hideLoader();
  }
  handleGetReminder = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data && !responseJson.errors) {
      this.setState({
        title: responseJson.data.attributes.title,
        reminderDate: responseJson.data.attributes.date_reminder,
        reminderTime: responseJson.data.attributes.time_reminder,
        description: responseJson.data.attributes.description,
        frequency: responseJson.data.attributes.reminder_frequency,
      })
    }else{
      this.redirectTo("Reminders")
    }
    helper.hideLoader();
  }
  onInputChangeHandler = (name: string, value: string)=>{
    this.setState((prevState)=>{
      return {
        ...prevState,
        [name]: value,
        isFormUpdated: true
      }
    })
  }
  onDateChangeHandler = (value: Date | Moment | null) => {
    const formatDate = helper.formatDate(value, "YYYY-MM-DD");
    this.setState({
      reminderDate: formatDate,
      isFormUpdated: true
    })
  }
  openDatePicker = () => {
    const datePickerDialog = document.getElementById("mui-date-picker");
    datePickerDialog?.click();
  }
  disableSubmitButton = ()=>{
    const { title, reminderDate, reminderTime, frequency, editMode, isFormUpdated } = this.state;
    if(!title.trim() || !reminderDate || !reminderTime || !frequency || (editMode && !isFormUpdated)){
      return true;
    }
    return false;
  }
  onSubmitHandler = async ()=>{
    const errors = this.validateFormData();
    helper.showLoader();
    if (Object.keys(errors).length === 0) {
      if(this.state.editMode){
        this.createReminderApiCallId = await helper.apiCall({
          method: "PUT",
          endPoint: `${configJSON.addReminder}/${this.state.id}`,
          contentType: configJSON.apiContentType,
          token: this.state.token,
          body: await this.getDataForPost()
        })
      }else{
        this.createReminderApiCallId = await helper.apiCall({
          method: "POST",
          endPoint: configJSON.addReminder,
          contentType: configJSON.apiContentType,
          token: this.state.token,
          body: await this.getDataForPost()
        })
      }
    }
  }
  validateFormData = () => {
    const errors: { [key: string]: {} } = {};
    const { reminderTime, reminderDate } = this.state;
    if (reminderTime && reminderDate && new Date(reminderDate).toDateString() === new Date().toDateString() && helper.isPastTime(reminderTime)) {
      errors.reminderTime = {
        message: 'Please select the valid time'
      }
    }
    return errors;
  }
  getDataForPost = async ()=>{
    const { title, reminderDate, reminderTime, description, frequency } = this.state;
    return {
      title: title.trim(),
      reminder_date: reminderDate,
      reminder_time: reminderTime,
      description: description,
      reminder_frequency: frequency
    }
  }

  redirectTo = (destination: string)=>{
    this.props.navigation.navigate(destination)
  }

  setToken = (token: string | null)=>{
    this.setState({
      token: token as string
    },()=>{
      const reminderId = this.props.navigation.getParam("id");
      if (reminderId) {
        this.setState({
          editMode: true,
          id: reminderId
        }, () => {
          this.getReminderDetails()
        })
      }
    })
  }

  getReminderDetails = async ()=>{
    helper.showLoader();
    this.getReminderDetailApiCallId = await helper.apiCall({
      method: "GET",
      endPoint: `${configJSON.addReminder}/${this.state.id}`,
      contentType: configJSON.apiContentType,
      token: this.state.token,
    })
  }
  
  // Customizable Area End
}