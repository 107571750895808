Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search';
exports.getGlobalSearchApiEndPoint = "bx_block_advanced_search/search/global_search";
exports.errorTitle = 'Error'
// Customizable Area End
