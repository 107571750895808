// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { Moment } from "moment/moment";
import { IInterest } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { fetchInterests, handleFetchInterestsAPICall, onSubmitNewInterest, handleAddNewInterestApiCall } from "../../user-profile-basic/src/actions";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export enum RecurrenceEnum {
    Daily = "daily",
    Weekly = "weekly",
    Monthly = "monthly",
    Annual = "annual"
}

export const days = [
    {
        label: "S",
        value: 0
    },
    {
        label: "M",
        value: 1
    },
    {
        label: "T",
        value: 2
    },
    {
        label: "W",
        value: 3
    },
    {
        label: "T",
        value: 4
    },
    {
        label: "F",
        value: 5
    },
    {
        label: "S",
        value: 6
    }
]

export interface S {
    // Customizable Area Start
    token: string | null;
    title: string;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    datePickerIndex: number;
    description: string;
    recurrence: string;
    isInterestModalOpen: boolean;
    interests: IInterest[];
    selectedInterests: IInterest[];
    currentSelectedInterests: IInterest[];
    isNewInterestAddApiRunning: boolean;
    selectedWeekDay: {
        label: string;
        value: number;
    }[];
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddRoutineController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    fetchInterestsApiCallId: string = "";
    addNewInterestApiCallId: string = "";
    createRoutineApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            title: "",
            start_date: "",
            end_date: "",
            start_time: "",
            end_time: "",
            datePickerIndex: 0,
            description: "",
            recurrence: RecurrenceEnum.Daily,
            isInterestModalOpen: false,
            currentSelectedInterests: [],
            selectedInterests: [],
            interests: [],
            isNewInterestAddApiRunning: false,
            selectedWeekDay: []
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        const token = await helper.getStorageData("authToken");
        if (token) {
            this.setState({
                token
            }, () => {
                this.fetchInterests()
            })
        }
        // Customizable Area End
    }

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (webApiRequestCallId) {
                case this.fetchInterestsApiCallId:
                    this.handleFetchInterestsAPICall(message);
                    break;
                case this.addNewInterestApiCallId:
                    this.handleAddNewInterestApiCall(message);
                    break;
                case this.createRoutineApiCallId:
                    this.handleCreateRoutineApiCall(message);
                    break;
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start
    goBack = () => {
        this.props.navigation.goBack();
    }

    onInputChange = (name: string, value: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    openDatePicker = (datePickerIndex: number) => {
        let datePickerDialog;
        if (datePickerIndex === 1) {
            datePickerDialog = document.getElementById("mui-date-picker1");
        } else if (datePickerIndex === 2) {
            datePickerDialog = document.getElementById("mui-date-picker2");
        }
        if (datePickerDialog) {
            datePickerDialog.click();
        }
        this.setState({
            datePickerIndex
        })
    }

    onDateChangeHandler = (value: Date | Moment | null) => {
        const formatDate = helper.formatDate(value, "YYYY-MM-DD");
        const { datePickerIndex } = this.state;
        if (datePickerIndex === 1) {
            this.setState({
                start_date: formatDate
            })
        } else if (datePickerIndex === 2) {
            this.setState({
                end_date: formatDate
            })
        }
    }

    toggleInterestModal = () => {
        const {
            isInterestModalOpen,
            selectedInterests
        } = this.state;
        this.setState({
            isInterestModalOpen: !isInterestModalOpen,
            currentSelectedInterests: selectedInterests
        })
    }

    removeInterestFromSelectedInterest = (interestId: string) => {
        this.setState(prevState => {
            return {
                selectedInterests: prevState.selectedInterests.filter(interest => interest.id !== interestId)
            }
        })
    }

    fetchInterests = async () => {
        this.fetchInterestsApiCallId = await fetchInterests({
            token: this.state.token
        })
    }

    onSubmitNewInterest = async (event: string) => {
        this.setState({
            isNewInterestAddApiRunning: true
        })
        this.addNewInterestApiCallId = await onSubmitNewInterest({
            token: this.state.token,
            body: {
                name: event
            }
        })
    }

    handleAddNewInterestApiCall = (message: Message) => {
        const responseData = handleAddNewInterestApiCall(message);

        if (responseData) {
            this.setState((prevState) => {
                return {
                    currentSelectedInterests: [...prevState.currentSelectedInterests, responseData.data]
                }
            })
        }

        this.setState({
            isNewInterestAddApiRunning: false
        })
    }

    handleFetchInterestsAPICall = (message: Message) => {
        const responseData = handleFetchInterestsAPICall(message);
        this.setState({
            interests: responseData.data
        })
    }

    setCurrentSelectedInterest = (interests: IInterest[]) => {
        this.setState({
            currentSelectedInterests: interests
        })
    }

    onAddInterests = (interests: IInterest[]) => {
        this.setState({
            selectedInterests: interests,
            isInterestModalOpen: false,
            currentSelectedInterests: []
        })
    }

    onSelectWeekDay = (event: { label: string, value: number }) => {
        const {
          selectedWeekDay
        } = this.state;
        if(selectedWeekDay.find(day=> day.value === event.value)){
            this.setState(prevState=>{
                return {
                    selectedWeekDay: prevState.selectedWeekDay.filter(day=> day.value !== event.value)
                }
            })
        }else{
            this.setState(prevState=>{
                return {
                    selectedWeekDay: [...prevState.selectedWeekDay, event]
                }
            })
        }
    }

    disableSubmit = () => {
        const {
            title,
            start_time,
            recurrence,
            selectedWeekDay,
            start_date
        } = this.state;

        if (!title || !start_time || !start_date || !recurrence) return true;
        if(recurrence === RecurrenceEnum.Weekly && selectedWeekDay.length === 0) return true;
        return false;
    }

    validate = () => {
        const {
            start_date,
            end_date,
            start_time,
            end_time
        } = this.state;

        const errors: { [key: string]: {} } = {};

        if (start_date && end_date && new Date(start_date).toDateString() !== new Date(end_date).toDateString() && !helper.isStartDateLessThanEndDate(start_date, end_date)) {
            errors["end_date"] = {
                message: "End date cannot be earlier than start date. Please select a valid date."
            }
        } else if (start_time && start_date && new Date(start_date).toDateString() === new Date().toDateString() && helper.isPastTime(start_time)) {
            errors["start_time"] = {
                message: "Please select the valid time"
            }
        } else if (start_date && end_date && start_time && end_time && start_date === end_date && start_time === end_time) {
            errors["end_time"] = {
                message: 'Start and end time must be different. Please choose a valid time range.'
            }
        }
        return errors;
    }

    onSubmit = async () => {
        const errors = this.validate();
        if (Object.keys(errors).length > 0) {
            return;
        }

        this.createRoutineApiCallId = await helper.apiCall({
            method: "POST",
            token: this.state.token,
            endPoint: "bx_block_custom_form/tasks",
            body: this.getDataForPost(),
            type: "formData"
        })
    }

    getDataForPost = ()=>{
        const {
          title,
          description,
          start_date,
          end_date,
          start_time,
          end_time,
          recurrence,
          selectedWeekDay,
          selectedInterests
        } = this.state;

        const bodyData = new FormData();

        bodyData.append("task[title]", title.trim());
        
        if(description){
            bodyData.append("task[description]", description.trim());
        }
        if(start_date){
            bodyData.append("task[task_date]", start_date);
        }
        if(start_time){
            bodyData.append("task[task_start_time]", start_time);
        }
        if(recurrence){
            bodyData.append("task[reminder_frequency]", recurrence);
        }

        bodyData.append("task[interest_ids]", JSON.stringify(selectedInterests.map(interest=> interest.id)));

        if(recurrence === RecurrenceEnum.Weekly && selectedWeekDay.length){
            selectedWeekDay.forEach(day=>{
                bodyData.append("task[days_to_reminder][]", day.value.toString())
            })
        }
        if(recurrence === RecurrenceEnum.Monthly){
            bodyData.append("task[select_date_to_reminder][]", "")
        }
        if(end_date){
            bodyData.append("task[task_end_date]", end_date);
        }
        if(end_time){
            bodyData.append("task[task_end_time]", end_time);
        }
        
        return bodyData;
    }

    handleCreateRoutineApiCall = (message: Message)=>{
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(response && !response.error){
            helper.showSuccessToast("Routine created successfully");
            this.props.navigation.history.push("/profile?tab=4");
        }else if(response && response.error && response.error.length){
            response.error.forEach((error: string) => {
                helper.showErrorToast(error);
            });
        }else{
            helper.showErrorToast("Something went wrong please try again");
        }
    }
    // Customizable Area End
}