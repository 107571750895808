import React from "react";
import { styled } from "@material-ui/styles";
import { IReminderCard } from "../types";
import { TimeIcon } from "../assets";
interface Props {
    reminder: IReminderCard
}


const ReminderCard: React.FC<Props> = (props: Props) => {
    const { reminder } = props;

    return (
        <StyledReminderCard className="GlobalSearch_ReminderCard">
            <p className="title ellipsis">{reminder.title}</p>
            <p className="description ellipsis">{reminder.description}</p>
            <div style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
                <img src={TimeIcon} />
                <p className="time">{reminder.time}</p>
            </div>
        </StyledReminderCard>
    )
}

const StyledReminderCard = styled("div")({
    "&.GlobalSearch_ReminderCard": {
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        borderRadius: "1rem",
        width: "18.625rem",
        height: "6.0625rem",
        flexShrink: 0,
        marginRight: "1.5rem",
        padding: "0.875rem 1rem",
        boxSizing: "border-box",
        "& > .title": {
            color: "rgba(255, 255, 255, 1)",
            fontSize: "1rem",
            fontWeight: 500
        },
        "& .time": {
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "0.875rem",
            fontWeight: 400
        },
        "& > .description": {
            color: "rgba(255, 255, 255, 0.8)",
            fontSize: "0.75rem",
            fontWeight: 400
        },
        "&:last-child": {
            marginRight: 0
        }
    }
})

export default ReminderCard;