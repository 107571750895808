const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
const configJSON = require("./config.js");
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";

interface IApiCallData {
    token: string | null,
    page_no?: number;
    per_page?: number;
    query?: string;
    body?: any
}

export const actions = {};

export const getUpcomingAppointments = async (apiCallData: IApiCallData) => {
    const {
        token,
        page_no,
        per_page
    } = apiCallData;

    let endPoint = "bx_block_appointment_management/upcoming_appointments_list";
    if (page_no && per_page) {
        endPoint += `?page_no=${page_no}&per_page=${per_page}`
    }
    return await helper.apiCall({
        method: "GET",
        contentType: configJSON.apiContentType,
        token: token,
        endPoint
    })
}


export const handleGetUpcomingAppointmentsAPICall = (message: Message) => {
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
        return {
            data: responseJson.data,
            meta: responseJson.meta
        }
    } else {
        return {
            data: []
        }
    }
}

export const fetchContacts = async (apiCallData: IApiCallData) => {
    const {
        token
    } = apiCallData;

    return await helper.apiCall({
        method: "GET",
        contentType: configJSON.apiContentType,
        token: token,
        endPoint: `account_block/accounts/registered_users`
    })
}

export const handleFetchContactsAPICall = (message: Message) => {
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data && responseJson.data.users) {
        return {
            data: responseJson.data.users
        }
    } else {
        return {
            data: []
        }
    }
}

export const fetchPhoneBookContacts = async (apiCallData: IApiCallData) => {
    const {
        token
    } = apiCallData;

    return await helper.apiCall({
        method: "GET",
        endPoint: configJSON.phonebookContacts,
        contentType: configJSON.validationApiContentType,
        token: token
    })
}

export const handleFetchPhoneBookContactsAPICall = (message: Message) => {
    const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (response && response.data) {
        const phoneBookContacts = response.data.map((phoneBookContact: { data: { id: string, type: string, attributes: { name: string, phone_number: string } } }) => {
            return {
                name: phoneBookContact.data.attributes.name,
                id: phoneBookContact.data.id,
                isNonRegistered: true,
                full_phone_number: phoneBookContact.data.attributes.phone_number
            }
        })
        return {
            data: phoneBookContacts
        }
    } else {
        return {
            data: []
        }
    }
}

export const onSearchContact = async (apiCallData: IApiCallData) => {
    const {
        token,
        query
    } = apiCallData;

    return await helper.apiCall({
        method: "GET",
        contentType: configJSON.apiContentType,
        token: token,
        endPoint: `${configJSON.searchContactsApiEndPoint}?query=${query}`
    })
}

export const searchContactApiCallHandler = (message: Message) => {
    const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson?.accounts?.data || responseJson?.phonebook_contacts?.data)) {
        const accountContactsList = responseJson.accounts?.data?.map((contactItem: { type: string, id: number, attributes: { email: string, full_phone_number: string, full_name: string, id: number } }) => {
            return {
                id: contactItem.attributes.id,
                full_phone_number: contactItem.attributes.full_phone_number,
                name: contactItem.attributes.full_name,
                email: contactItem.attributes.email,
            }
        })
        const phonebookContactsList = responseJson.phonebook_contacts?.data?.map((contactItem: { type: string, id: number, attributes: { phone_number: string, name: string, id: number } }) => {
            return {
                id: contactItem.id,
                full_phone_number: contactItem.attributes.phone_number,
                name: contactItem.attributes.name,
                isNonRegistered: true,
                email: "",
            }
        })
        return {
            data: accountContactsList,
            phoneBookContactsList: phonebookContactsList
        }
    } else {
        return {
            data: [],
            phoneBookContactsList: []
        }
    }
}

export const searchAppointments = async (apiCallData: IApiCallData)=>{
    const {
        token,
        query
    } = apiCallData;

    return await helper.apiCall({
        method: "GET",
        contentType: configJSON.apiContentType,
        token: token,
        endPoint: `bx_block_appointment_management/user_specific_appointments?query=${query}`
    })
}

export const createChat = async (apiCallData: IApiCallData)=>{
    const {
        token,
        body
    } = apiCallData;

    return await helper.apiCall({
        method: "POST",
        contentType: configJSON.apiContentType,
        token: token,
        endPoint: `bx_block_chat/chats/create_chat_group`,
        body
    })
}