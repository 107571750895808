import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { getStorageData } from '../../../framework/src/Utilities';
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions")

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  type?: string;
  baseURL?: string;
}

interface DrawerItem {
  data: DrawerItemData;
}

interface DrawerItemData {
  id: string;
  type: string;
  attributes: DrawerItemAttributes;
}

interface DrawerItemAttributes {
  id: number;
  position: string;
  items: Item[];
}

interface Item {
  name: string;
}
  

const routing = [
  {
    path:'dashboard',
    block:'Dashboard'
  },
  {
    path:'explore',
    block:'Explore'
  },
  {
    path: 'bucketlist',
    block: 'BucketList'
  },
  {
    path:'event',
    block:'Events'
  },
  {
    path:'reminders',
    block:'Reminders'
  },
  {
    path:'appointments',
    block:'Appointments'
  },
  {
    path:'group',
    block:'Groups'
  },
  {
    path:'chats',
    block:'GroupChat'
  },
  {
    path:'Contact-Us',
    block:'ContactUsNew'
  }
]

interface User {
  id:number;
  full_name:string;
  email:string;
  phone_number:string;
  image_url:string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  children?: React.ReactNode;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: string;
  drawerItems: DrawerItem[];
  selectedNavItem: number;
  profileMenuRef: HTMLElement | null;
  user: User | null;
  searchOptions:{
    [key: string]:{
      label: string;
      selected: boolean;
      value: string;
    }
  }
  searchOptionsMenuAnchorEle: HTMLDivElement | null;
  searchQuery: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  searchDebouncingRef: null | NodeJS.Timeout = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      selectedNavItem: 1,
      profileMenuRef: null,
      user:null,
      searchOptions:{
        all:{
          label: "All",
          selected: true,
          value: "all"
        },
        appointment:{
          label: "Appointment",
          selected: true,
          value: "appointments"
        },
        event:{
          label: "Event",
          selected: true,
          value: "events"
        },
        reminder:{
          label: "Reminder",
          selected: true,
          value: "reminders"
        },
        group:{
          label: "Group",
          selected: true,
          value: "groups"
        }
      },
      searchOptionsMenuAnchorEle: null,
      searchQuery: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      let userData = await helper.getStorageData("userData", true);
      if(!token){
        token = await getStorageData('authToken');
      }
      this.setToken(token, userData);
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== "" &&
      this.apiGetDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ drawerItems: responseJson });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setActiveNavMenuItem = (currentSelectedNavMenuItem: number)=>{
    this.setState({
      selectedNavItem: currentSelectedNavMenuItem
    })
    this.redirectTo(routing[currentSelectedNavMenuItem-1].block)
  }

  openProfileMenu = (event: React.MouseEvent<HTMLDivElement>)=>{
    this.setState({
      profileMenuRef: event.currentTarget
    })
  }

  closeProfileMenu = ()=>{
    this.setState({
      profileMenuRef: null
    })
  }

  logout = async ()=>{
    await helper.logout();
    this.redirectTo('EmailAccountLoginBlock');
  }

  toggleDrawer = () => {
    this.setState({ webDrawer: !this.state.webDrawer }, async ()=>{
      if(this.state.webDrawer){
        await helper.setStorageData("isNavBarCollapsed", "true");
      }else{
        await helper.setStorageData("isNavBarCollapsed", "false");
      }
    });
  };


  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.updateCurrentNavItem()
    const isNavBarCollapsed = await helper.getStorageData("isNavBarCollapsed");
    if(isNavBarCollapsed && isNavBarCollapsed === "true"){
      this.setState({
        webDrawer: true
      })
    }
  }

  async componentWillUnmount(): Promise<void> {
    if (this.searchDebouncingRef) {
      clearTimeout(this.searchDebouncingRef)
    }
  }

  getToken = async () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  setToken = (token: string | null, userData: User | null) => {
    if (token && userData) {
      this.setState({
        token: token,
        user: userData
      })
    } else {
      this.redirectTo('EmailAccountLoginBlock');
    }
  }

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  redirectTo = (destination: string)=>{
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), destination);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  updateCurrentNavItem = ()=>{
    const location = window.location.pathname;
    
    if(location.includes(routing[0].path)){
      this.setState({
        selectedNavItem:1
      })
    }if(location.includes(routing[1].path)){
      this.setState({
        selectedNavItem:2
      })
    }else if(location.includes(routing[2].path)){
      this.setState({
        selectedNavItem:3
      })
    }else if(location.includes(routing[3].path)){
      this.setState({
        selectedNavItem:4
      })
    }else if(location.includes(routing[4].path)){
      this.setState({
        selectedNavItem:5
      })
    }else if(location.includes(routing[5].path)){
      this.setState({
        selectedNavItem:6
      })
    }else if(location.includes(routing[6].path)){
      this.setState({
        selectedNavItem:7
      })
    }else if(location.includes(routing[7].path)){
      this.setState({
        selectedNavItem:8
      })
    }else if(location.includes(routing[8].path)){
      this.setState({
        selectedNavItem:9
      })
    }
    
  }

  openSearchOptionsMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      searchOptionsMenuAnchorEle: event.currentTarget
    })
  }

  closeSearchOptionsMenu = () => {
    this.setState({
      searchOptionsMenuAnchorEle: null
    })
  }

  onSelectSearchOption = (key: string)=>{
    const {
      searchOptions
    } = this.state;
    searchOptions[key].selected = !searchOptions[key].selected;
    if(key === "all"){
      Object.keys(searchOptions).forEach(key=>{
        searchOptions[key].selected = searchOptions["all"].selected;
      })
    }else{
      searchOptions["all"].selected = Object.values(searchOptions).every(option => option.selected);
    }
    this.setState({
      searchOptions
    })
  }

  onSearch = (event: string) => {
    if (this.searchDebouncingRef) {
      clearTimeout(this.searchDebouncingRef)
    }
    this.searchDebouncingRef = setTimeout(() => {
      this.setState({
        searchQuery: event
      })
    }, 1000)
  }

  // Customizable Area End
}
