// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IComment, IMentionInputType, IMetaPagination, IPost } from "../../../components/src/CommonTypes";
import _ from "lodash";
const {
  HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    // Customizable Area Start
  navigation?: any;
  id?: string;
  groupId: number;
  groupPostsList: Array<IPost>;
  commentMentionsList: IMentionInputType[];
  handleFetchNextPagePosts: () => void;
  handleUpdateCommentsCount: (postId: number | string, action?: string) => void;
  handleCommentSectionShow: (postId: number | string) => void;
  handleCommentSectionHide: (postId: number | string) => void;
  handleDeleteGroupPost: (postId: number | string, groupId: string | number) => void;
  handleUpdateLikeGroupPost: (postId: number | string) => void;
  handleEditGroupPost: (postDetails: IPost) => void;
  isPostLoading?: boolean;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string | null;
  userId: number | undefined;
  userName: string | undefined;
  page: number;
  isAllGroupPostsFetched: boolean;
  currentLikedPostId: number | string;
  currentCommentModal: {
    id: string;
  } | null;
  commentsCurrentPage: number;
  currentGroupPostCommentList: IComment[];
  isLoadingComments: boolean;
  isAllGroupPostCommentsFetched: boolean;
  currentCommentedPostId: number | string;
  deletedCommentId: number | string;
  confirmationGroupPostDeletePopUpData: {
    header: string;
    message: string;
    type: string;
    postId: string;
  } | null;
  commentsMetaData: IMetaPagination | null;
  groupPostLikedUserList: { id: string | number, attributes: { like_by_name: string} }[] | undefined;
  currentGroupPostLikedCommentId: string | number;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GroupPostsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  likeGroupPostApiCallId: string = "";
  getGroupPostCommentsApiCallId: string = "";
  addGroupPostCommentApiCallId: string = "";
  deleteGroupPostCommentApiCallId: string = "";
  groupPostLikedUsersListApiCallId: string = "";
  getSearchMentionsListApiCallId: string = "";
  likeDislikeGroupPostCommentApiCallId: string = "";
  groupPostcommentLikedUsersListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      userId: undefined,
      page: 1,
      isAllGroupPostsFetched: false,
      currentLikedPostId: "",
      currentCommentModal: null,
      commentsCurrentPage: 1,
      currentGroupPostCommentList: [],
      isLoadingComments: false,
      userName: "",
      isAllGroupPostCommentsFetched: false,
      currentCommentedPostId: "", 
      deletedCommentId: "",
      confirmationGroupPostDeletePopUpData: null,
      groupPostLikedUserList: undefined,
      commentsMetaData: null,
      currentGroupPostLikedCommentId: "",
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    (async () => {
      const userData = await helper.getUserData();
      if (userData) {
        this.setState({ userId: userData.id, userName: userData.full_name });
      }
    })();
    // Customizable Area End
  }

  // Customizable Area Start

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (!token) {
        token = await getStorageData("authToken");
      }
      this.setToken(token);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      switch (webApiRequestCallId) {
        case this.getGroupPostCommentsApiCallId:
          this.handleGetGroupPostCommentsApiCall(message);
          break;
        case this.addGroupPostCommentApiCallId:
          this.handleAddGroupPostCommentApiCall(message);
          break;
        case this.deleteGroupPostCommentApiCallId:
          this.handleDeletePostGroupCommentApiCall(message);
          break;
        case this.likeGroupPostApiCallId:
          this.likeGroupPostApiCallHandler(message);
          break;
        case this.groupPostLikedUsersListApiCallId:
          this.handleGetGroupPostLikedUsersListCall(message);
          break;
        case this.likeDislikeGroupPostCommentApiCallId:
          this.likeGroupPostCommentApiCallHandler(message);
          break;
        case this.groupPostcommentLikedUsersListApiCallId:
          this.handleGetGroupPostCommentLikedUsersListCall(message);
          break;
      }
    }

    // Customizable Area End
  };

  setToken = async (token: string | null) => {
    this.setState({ token });
  };

  handleHideCommentsSection = (postId: number | string) => {
    this.setState({ currentCommentedPostId: "", currentGroupPostCommentList: [], commentsCurrentPage: 1, commentsMetaData: null })
    this.props.handleCommentSectionHide(postId)
  }

  handleOpenCommentsSection = async (postId: number | string) => {
    this.setState({ currentCommentedPostId: postId, currentGroupPostCommentList: [], commentsCurrentPage: 1, commentsMetaData: null }, async () => {
      await this.getGroupPostComments(postId)
    })
  }

  handleUpdateCommentsReplyCount = (commentId: number | string, action?: string) => {
    const updatedRepliesList = this.state.currentGroupPostCommentList.map((item) => {
      if (item.id === commentId) {
        if (action === "decrease") {
          item.attributes.reply_count -= 1
        } else {
          item.attributes.reply_count += 1
        }
      }
      return item
    })
    this.setState({ currentGroupPostCommentList: updatedRepliesList })
  }

  handleShowReplySection = (commentId: number | string) => {
    const updatedRepliesList = this.state.currentGroupPostCommentList.map((item) => {
      if (item.attributes) {
        if (item.id === commentId) {
          item.attributes.show_replies_section = true
        } else {
          item.attributes.show_replies_section = false
        }
      }
      return item
    })
    this.setState({ currentGroupPostCommentList: updatedRepliesList })
  }

  handleHideReplySection = (commentId: number | string) => {
    const updatedRepliesList = this.state.currentGroupPostCommentList.map((item) => {
      if (item.id === commentId) {
        item.attributes.show_replies_section = false
      }
      return item
    })
    this.setState({ currentGroupPostCommentList: updatedRepliesList })
  }

  scrollToTopOnGroupPostComments = () => {
    const topDiv = document.getElementById("group-post-comment-top-div");
    if (topDiv) {
      topDiv.scrollIntoView({
        behavior: 'smooth', block: 'nearest', inline: 'start'
      })
    }
  }

  handleFetchNextCommentsForCurrentPost = (postId: number | string) => {
    this.setState({ commentsCurrentPage: this.state.commentsCurrentPage + 1}, () => this.getGroupPostComments(postId))
  }

  onLikeGroupPostComment = async (commentId: string | number) => {
    const body = {
      likeable_id: commentId,
      like_type: "comment",
    };
    this.likeDislikeGroupPostCommentApiCallId = await helper.apiCall({
      method: configJSON.postApiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likePostEndPoint}`,
      body,
    });
    this.setState({
      currentGroupPostLikedCommentId: commentId,
    });
  };

  likeGroupPostCommentApiCallHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson.message || responseJson.data)) {
      const { currentGroupPostLikedCommentId, currentGroupPostCommentList } = this.state;
      const updatedCommentsList = currentGroupPostCommentList.map((comment) => {
        if (comment.id === currentGroupPostLikedCommentId) {
          if (comment.attributes.liked_by_me) {
            comment.attributes.total_likes -= 1;
          } else {
            comment.attributes.total_likes += 1;
          }
          comment.attributes.liked_by_me = !comment.attributes.liked_by_me;
        }
        return comment;
      });
      this.setState({
        currentGroupPostCommentList: updatedCommentsList,
      });
    } else {
      const likeCommentErrMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(likeCommentErrMsg);
    }
    helper.hideLoader();
  };

  handleGetGroupPostCommentLikedUsersListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.data) {
      this.setState({
        groupPostLikedUserList: [...responseJson.data.data],
      });
    } else {
      this.setState({ groupPostLikedUserList: [] });
    }
    helper.hideLoader();
  };

  getGroupPostCommentLikedUsersList = async(commentId: number | string) => {
    helper.showLoader();
    this.groupPostcommentLikedUsersListApiCallId = await helper.apiCall({
      contentType: configJSON.apiContentType,
      token: this.state.token,
      method: 'GET',
      endPoint: `${configJSON.commentsLikedListUsers}?likeable_id=${commentId}`,
    });
  }

  getGroupPostComments = async(groupPostId: number | string) => {
    this.props.handleCommentSectionShow(groupPostId)
    this.setState({
      isLoadingComments: true,
    }, async() => {
      this.getGroupPostCommentsApiCallId = await helper.apiCall({
        contentType: configJSON.apiContentType,
        method: configJSON.apiMethod,
        token: this.state.token,
        endPoint: `${configJSON.commentsListApiEndpoint}?commentable_id=${groupPostId}&order=desc&page_no=${this.state.commentsCurrentPage}&per_page=15`,
      });
    });
  }

  handleGetGroupPostCommentsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.meta) {
      this.setState({ commentsMetaData: responseJson.meta })
    }
    if(responseJson?.data?.length){
      this.setState({
        currentGroupPostCommentList: _.uniqBy([...this.state.currentGroupPostCommentList, ...responseJson.data], "id")
      })
    }
    this.setState({
      isLoadingComments: false
    })
  };

  addGroupPostComment = async(groupPostId: number | string, comment: { text: string }) => {
    this.setState({
      isLoadingComments: true,
      currentCommentedPostId: groupPostId
    }, async() => {
      const currentPost = this.props.groupPostsList.find((item) => item.id === groupPostId);
      if(!currentPost?.attributes?.show_comments_section) {
        await this.handleOpenCommentsSection(groupPostId);
      }
      const body = {
        comment: {
          commentable_type: "BxBlockPosts::Post",
          commentable_id: groupPostId,
          comment: comment.text
        }
      }
      this.addGroupPostCommentApiCallId = await helper.apiCall({
        method: configJSON.postApiMethod,
        contentType: configJSON.apiContentType,
        token: this.state.token,
        endPoint: `${configJSON.commentsListApiEndpoint}`,
        body
      });
    })
  }

  handleAddGroupPostCommentApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson?.data){
      const { currentGroupPostCommentList, currentCommentedPostId } = this.state;
      let updatedCommentsList = [ { ...responseJson.data}, ...currentGroupPostCommentList];
      this.setState({
        currentGroupPostCommentList: _.uniqBy(updatedCommentsList, "id"),
      })
      this.props.handleUpdateCommentsCount(currentCommentedPostId)
      this.scrollToTopOnGroupPostComments();
    } else {
      const errorAddPostToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorAddPostToastMsg);
    }
    this.setState({
      isLoadingComments: false
    })
  }

  onDeleteGroupPostComment = async (commentId: string | number, postId: string | number) => {
    this.setState({
      isLoadingComments: true,
      currentCommentedPostId: postId,
      deletedCommentId: commentId,
    });
    const { token } = this.state;
    this.deleteGroupPostCommentApiCallId = await helper.apiCall({
      method: configJSON.deleteApiMethod,
      endPoint: `${configJSON.commentsListApiEndpoint}/${commentId}?commentable_id=${postId}`,
      contentType: configJSON.apiContentType,
      token: token,
    });
  };

  handleDeletePostGroupCommentApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.message) {
      const { deletedCommentId, currentGroupPostCommentList , currentCommentedPostId} = this.state;
      const updatedCommentsList = currentGroupPostCommentList.filter((item) => item.id !== deletedCommentId)
      this.setState({
        currentGroupPostCommentList: _.uniqBy(updatedCommentsList, "id"),
      });
      this.props.handleUpdateCommentsCount(currentCommentedPostId, "decrease")
    } else {
      const errorDelPostToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorDelPostToastMsg);
    }
    this.setState({
      isLoadingComments: false,
      deletedCommentId: "",
      currentCommentedPostId: "",
    });
  };

  openPostDeleteModal = (title: string, postId: string | number) => {
    this.setState({
      confirmationGroupPostDeletePopUpData: {
        header: "Delete Post?",
        message: `Are you sure that you want to delete this ${title}?`,
        type: "delete",
        postId: postId as string,
      },
    });
  };

  onCloseDeleteConfirmationModal = () => {
    this.setState({
      confirmationGroupPostDeletePopUpData: null,
    });
  };

  onAcceptGroupPostDeleteConfirmation = async () => {
    if (this.state.confirmationGroupPostDeletePopUpData) {
      const { groupId } = this.props;
      const { type, postId } = this.state.confirmationGroupPostDeletePopUpData;
      if (type === "delete") {
        this.props.handleDeleteGroupPost(postId, groupId);
      }
      this.onCloseDeleteConfirmationModal()
    }
  }
  
  onLikeGroupPost = async (postId: string) => {
    this.setState({
      currentLikedPostId: postId,
    });
    const request = {
      likeable_id: postId,
      like_type: configJSON.likeType,
    };
    this.likeGroupPostApiCallId = await helper.apiCall({
      method: configJSON.postApiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likePostEndPoint}`,
      body: request,
    });
  };

  likeGroupPostApiCallHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson.message || responseJson.data)) {
      const { currentLikedPostId } = this.state;
      this.props.handleUpdateLikeGroupPost(currentLikedPostId);
    } else {
      const errorLikePostToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorLikePostToastMsg);
    }
    this.setState({ currentLikedPostId: "" })
  };

  handleGetGroupPostLikedUsersListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.data) {
      this.setState({
        groupPostLikedUserList: [...responseJson.data.data],
      });
    } else {
      this.setState({ groupPostLikedUserList: [] });
    }
    helper.hideLoader();
  };

  getGroupPostLikedUsersList = async(groupPostId: number | string) => {
    helper.showLoader();
    this.groupPostLikedUsersListApiCallId = await helper.apiCall({
      method: configJSON.apiMethod,
      contentType: configJSON.apiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likedUsersListApiEndpoint}?post_id=${groupPostId}`,
    });
  }

  setLikedUserList = () => {
    this.setState({
      groupPostLikedUserList: undefined,
    });
  };

  // Customizable Area End
}
