// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    openChatInfo: boolean;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export const messages = [
    {
        name: "Maan",
        message: "I'm down! Any ideas??",
        isSendByMe: false,
        time: "11:35 AM"
    },
    {
        name: "Maan",
        message: "I'm down! Any ideas??",
        isSendByMe: true,
        time: "11:35 AM"
    },
    {
        name: "Josseb",
        message: "I'm down! Any ideas??",
        isSendByMe: false,
        time: "11:35 AM"
    },
    {
        name: "Maan",
        message: "I'm down! Any ideas??",
        isSendByMe: false,
        time: "11:35 AM"
    },
    {
        name: "Maan",
        message: "I'm down! Any ideas??",
        isSendByMe: false,
        time: "11:35 AM"
    },
    {
        name: "Maan",
        message: "Different story year",
        isSendByMe: true,
        time: "11:35 AM"
    },
    {
        name: "Maan",
        message: "I'm down! Any ideas??",
        isSendByMe: true,
        time: "11:35 AM"
    },
    {
        name: "Josseb",
        message: "I'm down! Any ideas??",
        isSendByMe: false,
        time: "11:35 AM"
    },
]

export default class ViewGroupChatController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAllPostsApiId?: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            openChatInfo: false
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = async () => {
        const authToken = await helper.getStorageData("authToken");
        this.setState({
            token: authToken
        })
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        // Customizable Area End
    };

    // Customizable Area Start
    toggleChatInfo = ()=>{
        this.setState((prevState)=>{
            return {
                openChatInfo: !prevState.openChatInfo
            }
        })
    }
    // Customizable Area End
}