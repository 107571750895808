// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { runEngine } from "../../framework/src/RunEngine";
import App from './App';

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js') // Register the service worker
      .then(registration => {
        runEngine.debugLog('Service Worker registered: ', registration);
      })
      .catch(error => {
        runEngine.debugLog('Service Worker registration failed: ', error);
      });
  });
}
 
navigator.serviceWorker.ready.then(function(registration) {
  runEngine.debugLog('Service Worker is ready');
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
