import React, { useEffect, useState } from "react";
import { styled } from "@material-ui/styles";
import moment from 'moment';

interface Props {
    isTimerOn?: boolean;
    onTimerEnd?: () => void;
    onTimerStart?: () => void;
    timerDuration?: number;
    lastAttemptAt?: string;
    storageName?: string;
    style?: React.CSSProperties
}


const Timer: React.FC<Props> = (props: Props) => {
    const { isTimerOn, onTimerEnd, style, lastAttemptAt } = props;
    const [remainingTime, setRemainingTime] = useState(0);

    const getFormattedTime = (time: number) => {
        const minutes = Math.floor(time / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        let formattedTime = "";
        if(minutes > 1){
            formattedTime += `${minutes} minutes`
        }if(minutes === 1){
            formattedTime += "1 minute";
        }

        if(seconds > 1){
            formattedTime += ` ${seconds} seconds`;
        }else if(seconds === 1){
            formattedTime += ` 1 second`;
        }
        return formattedTime;
    };

    useEffect(() => {
        // Calculate the time remaining (5 minutes = 300000 milliseconds)
        const lastAttempt = moment(lastAttemptAt);
        const fiveMinutesLater = lastAttempt.add(5, 'minutes');
        const now = moment();

        const timeLeft = fiveMinutesLater.diff(now);

        // Update the remaining time
        setRemainingTime(timeLeft > 0 ? timeLeft : 0);

        // Set up an interval to update the remaining time every second
        const timerInterval = setInterval(() => {
            const now = moment();
            const timeLeft = fiveMinutesLater.diff(now);
            setRemainingTime(timeLeft > 0 ? timeLeft : 0);

            // Clear the interval when the time reaches zero
            if (timeLeft <= 0) {
                clearInterval(timerInterval);
                onTimerEnd?.();
            }
        }, 1000);

        return () => clearInterval(timerInterval); // Cleanup on unmount
    }, [lastAttemptAt]);

    return (
        <>
            {
                isTimerOn && (
                    <StyledTimer style={style} className="Custom_Timer">
                        <p>You have exceeded the limit. Try again after {getFormattedTime(remainingTime)}</p>
                    </StyledTimer>
                )
            }
        </>
    )
}

const StyledTimer = styled("div")({
    "&.Custom_Timer": {
        fontSize: "0.75rem",
        fontWeight: 400,
        color: "rgb(255, 255, 255)"
    }
})

export default Timer;