// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
const {
    HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    closeInfo: ()=> void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export const members = [
    {
        name: "Kapil Thadani",
        isAdmin: true,
        id: 1
    },
    {
        name: "Harshit Devall",
        isAdmin: false,
        id: 2
    },
    {
        name: "Demi Jons",
        isAdmin: false,
        id: 3
    },
    {
        name: "Kapil Thadani",
        isAdmin: false,
        id: 4
    }
]

export default class GroupChatInfoController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = async () => {
        const AuthToken = await helper.getStorageData("authToken");
        this.setState({
            token: AuthToken
        })
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
}