import React from "react";
import { styled, Typography } from "@material-ui/core";

interface ReminderDateHeaderProps {
    reminder: { date: string, year: number};
    handleChangeWeek: (date: string, year: number) => void;
}

const ReminderDateHeader: React.FC<ReminderDateHeaderProps> = (props: ReminderDateHeaderProps) => {
    const { reminder } = props;

    return (
        <StyledReminderDateHeader className="ReminderDateHeader_StyledReminderDateHeader">
            <Typography id={`reminder-${reminder.date}`} className="reminder-date" component={"p"}>
                {reminder.date}
            </Typography>
        </StyledReminderDateHeader>
    )
}

const StyledReminderDateHeader = styled("div")({
    "&.ReminderDateHeader_StyledReminderDateHeader": {
        marginBottom: "10px",
        color: "#FFFFFF",
        "& .reminder-date": {
            fontSize: "16px"
        },
    }
})

export default ReminderDateHeader;