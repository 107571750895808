// Customizable Area Start
import React from "react";
import {
  styled
} from "@material-ui/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import GroupHeader from "./components/GroupHeader.web";
import SearchField from "./components/SearchField.web";
import GroupCardContainer from "./components/GroupCardContainer.web";
import SharedGroups from "./components/SharedGroups.web";
import GroupDetail from "./components/GroupDetail.web";
import GroupPendingRequestsListModal from "./components/GroupPendingRequestsListModal.web";
import { StylesProvider, createGenerateClassName, ThemeProvider, createTheme } from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'GroupsContainer',  // Customize the prefix (use meaningful names)
});

const theme = createTheme(); 

import GroupsController, {
  Props
} from "./GroupsController";
import TrendingGroups from "./components/TrendingGroups.web";

export default class Groups extends GroupsController {
  constructor(props: Props) {
    super(props);
  }

  NoGroupsMessage = () => {
    return (
      <StyledNoGroupsMessage className="Groups_StyledNoGroupsMessage">
        <header>Chats</header>
        <div className="message">
          <p>No Groups Available</p>
        </div>
      </StyledNoGroupsMessage>
    )
  }

  GroupsContainer = ()=>{
    const { groupSearchValue, groups, sharedGroups, selectedGroup, totalPendingRequestsCount, openPendingRequestsModal, pendingGroupRequests, errorOccurredInAcceptingRejectingRequest, matches, showTrendingGroups, isTrendingGroupsLoading, trendingGroups, isMyGroupsLoading, isSharedGroupsLoading } = this.state;
    return (
      <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
      <StyledGroupsContainer className="Groups_StyledGroupsContainer_Web">
        <GroupHeader handleOpenPendingRequests={() => this.getGroupPendingRequests()} pendingRequestCount={totalPendingRequestsCount} redirectTo={this.redirectTo} />
        <div className="main-groups-container">
          {!(matches && selectedGroup) && <div style={{
            width: matches ? "100%" : "30%"
          }} className="left">
                {
                  <>
                    <div style={{ padding: "0rem 1rem" }}>
                      <SearchField value={groupSearchValue} onChange={(event) =>this.changeSearchFieldValue(event)}/>
                    </div>
                    {
                      <>
                        <div className="groups-container">
                          <GroupCardContainer isMyGroupsLoading={isMyGroupsLoading} selectedGroup={selectedGroup} onClick={(event) => this.onSelectGroup({ ...event, type: "my-group" })} groups={groups} />
                          {!showTrendingGroups && <SharedGroups isSharedGroupsLoading={isSharedGroupsLoading} showTrendingGroups={this.handleShowTrendingGroups} selectedGroup={selectedGroup} onClick={(event) => this.onSelectGroup({ ...event, type: "shared-group" })} groups={sharedGroups} />}
                          {showTrendingGroups &&
                            <TrendingGroups isTrendingGroupsLoading={isTrendingGroupsLoading} goBackToSharedGroups={() => this.handleBackToSharedGroups()} selectedTrendingGroup={selectedGroup} onSelectGroup={(event) => this.onSelectGroup({ ...event, type: "shared-group" })} trendingGroups={trendingGroups} />
                          }
                        </div>
                      </>
                    }
                  </>
                }
          </div>}
          <div style={{
            width: matches ? "100%" : "70%"
          }} className="right">
            <GroupDetail handleFetchPendingRequestCount={this.getGroupPendingRequestsCount} onAcceptRejectPendingRequest={() => {
              if(this.state.totalPendingRequestsCount > 0) {
                this.setState({ totalPendingRequestsCount: this.state.totalPendingRequestsCount - 1 })
              }
            }} matchesMediumScreenSizeQuery={matches} handleUnSelectGroup={() => this.setState({ selectedGroup: null })} handleJoinGroup={this.joinGroupRequest} selectedGroup={selectedGroup} redirectTo={this.redirectTo} onDeleteGroup={this.onDeleteGroup} />
          </div>
        </div>
        <GroupPendingRequestsListModal
          open={openPendingRequestsModal}
          onAcceptRequest={this.acceptRejectGroupPendingRequest}
          onClose={this.onClosePendingRequestsModal}
          pendingRequesList={pendingGroupRequests}
          onRejectRequest={this.acceptRejectGroupPendingRequest}
          onSearch={this.onSearchGroupPendingRequests}
          errorInAcceptingRejectingRequest={errorOccurredInAcceptingRejectingRequest}
        />
      </StyledGroupsContainer>
      </ThemeProvider>
      </StylesProvider>
    )
  }


  render() {
    return (
     <NavigationMenu {...this.props} children={this.GroupsContainer()} />
    );
  }
}

const StyledGroupsContainer = styled("div")({
  "&&.Groups_StyledGroupsContainer_Web": {
    display: "flex",
    flexDirection: "column",
    gap: '1.5rem',
    padding: "1rem",
    boxSizing: "border-box",
    height: "100%",
    "& .main-groups-container":{
      display: "flex",
      gap:"1rem",
      height: "calc(100% - 4.5rem)",
      boxSizing: "border-box",
      "& > .left, > .right":{
        borderRadius: "0.5rem",
        height: "100%",
        boxSizing: "border-box",
      },
      "& > .left":{
          // width: "26.875rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          background: "rgba(35, 64, 75, 1)",
          padding: "1rem 0rem",
          boxSizing: "border-box",
          "& > .groups-container":{
            overflow: "scroll",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            flex: 1
          }
      },
      "& > .right":{
        flex: 1,
        flexShrink: 1,
        boxSizing: "border-box",
        borderRadius: "0.5rem",
      }
    }
  }
})

const StyledNoGroupsMessage = styled("div")({
  "&.Groups_StyledNoGroupsMessage":{
      padding: "0rem 1rem",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& > header":{
        fontSize: "1.25rem",
        fontWeight: 500,
        color: "rgba(255, 255, 255, 1)"
      },
      "& .message":{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        "& > p":{
          fontSize: "1rem",
          fontWeight: 400,
          color: "rgba(255, 255, 255, 0.8)"
        }
      }
  }
})



// Customizable Area End
