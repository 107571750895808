import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Platform } from "react-native";
import { AllEvents } from "./AllEventController";

// Customizable Area Start
import { EventData } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";
import { IAddPost, IComment, IMentionInputType, IPost } from "../../../components/src/CommonTypes";
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
const { ApiCallFunction: apiCallFunction } = require("../../appointmentmanagement/src/ApiCallFunction");
import { getEventDetail, handleEventDetailCall } from "./actions";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  eventDetail: EventData | null;
  openDeleteConfirmPopup: boolean;
  userId: number | undefined;
  isEventRsvpStatusUpdateMode: boolean;
  eventDeleteConfirmationPopup: {
    header: string;
    message: string;
    type: string;
    eventId: string;
  } | null;
  recentPostDetails: IPost | null;
  postCommentModal: {
    id: string;
  } | null;
  commentsPageNum: number; 
  commentsList: IComment[];
  isLoadingCommentsList: boolean;
  selectedCommentId: string | null;
  isAllCommentsFetched: boolean;
  postDetails: IAddPost | null;
  recentPostUploadedBlobIds: number[];
  isPostUploading: boolean;
  numberOfPostFilesUploaded: number;
  postLikedUserList: { id: string | number, attributes: { like_by_name: string} }[] | undefined;
  searchedPostCommentMentionsList: IMentionInputType[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllEventDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetEventDetails: string = "";
  apiUpdateRsvpStatusOfEvent: string = "";
  deleteEventApiCallId: string = "";
  recentPostApiCallId: string = "";
  likeRecentPostApiCallId: string = "";
  dislikeRecentPostApiCallId: string = "";
  getCommentsListApiCallId: string = "";
  addCommentInRecentPostApiCallId: string = "";
  deleteCommentFromRecentPostApiCallId: string = "";
  uploadPostFileApiCallId: string[] = [];
  addPostApiCallId: string = "";
  postLikedUsersListApiCallId: string = "";
  searchPostCommentMentionsApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      eventDetail: null,
      openDeleteConfirmPopup: false,
      isEventRsvpStatusUpdateMode: false,
      userId: undefined,
      eventDeleteConfirmationPopup: null,
      recentPostDetails: null,
      commentsList: [],
      commentsPageNum: 1,
      isLoadingCommentsList: false,
      postCommentModal: null,
      selectedCommentId: null,
      isAllCommentsFetched: false,
      postDetails: null,
      isPostUploading: false,
      recentPostUploadedBlobIds: [],
      numberOfPostFilesUploaded: 0,
      postLikedUserList: undefined,
      searchedPostCommentMentionsList: [],
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    if (Platform.OS !== "web") {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if(!token){
        token = await getStorageData("authToken")
      }
      this.setToken(token)
    }
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      switch(apiCallId){
        case this.apiGetEventDetails:
          this.handleEventDetailCall(message)
          break;
        case this.apiUpdateRsvpStatusOfEvent:
          this.handleEventDetailRsvpStatusUpdate(message);
          break;
        case this.deleteEventApiCallId:
          this.handleDeleteEventCall(message);
          break;
        case this.recentPostApiCallId:
          this.handleRecentPostCall(message);
          break;
        case this.likeRecentPostApiCallId:
          this.handleLikePostApiCall(message);
          break;
        case this.dislikeRecentPostApiCallId:
          this.handleDisLikePostApiCall(message);
          break;
        case this.getCommentsListApiCallId:
          this.handleGetCommentsListApiCall(message);
          break;
        case this.addCommentInRecentPostApiCallId:
          this.handleAddPostCommentApiCall(message);
          break;
        case this.deleteCommentFromRecentPostApiCallId:
          this.handleDeletePostCommentApiCall(message);
          break;
        case this.addPostApiCallId:
          this.handleCreateRecentPostCall(message);
          break;
        case this.postLikedUsersListApiCallId:
          this.handleGetPostLikedUsersListCall(message);
          break;
        case this.searchPostCommentMentionsApiCallId:
          this.handlePostComentGetMentionsListCall(message);
          break;
      }
      if(this.uploadPostFileApiCallId.includes(apiCallId)){
        this.handlePostUploadFileApiCall(message);
      }
     
    }
    // Customizable Area End
  }

  navigateToEventDetail = (selectedUpcomingEvent: AllEvents) => {
    this.props.navigation.navigate("AddEventDetail", {
      event: selectedUpcomingEvent,
    });
  };

  // Customizable Area Start

  setToken = (token: string) => {
    this.setState({ token: token }, async()=>{
      const userData = await HelperFunctions.getUserData();
      if (userData) {
        this.setState({userId: userData.id}, () => {
          this.getEventDetail();
          this.getRecentPost();
        });
      }
    });
  }

  redirectTo = (endpoint: string, params?:{[key:string]:string | number}) => {
    this.props.navigation.navigate(endpoint, params)
  }

  handleGetPostLikedUsersListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.data) {
      this.setState({
        postLikedUserList: [...responseJson.data.data],
      });
    } else {
      this.setState({ postLikedUserList: [] });
    }
    HelperFunctions.hideLoader();
  };

  getLikedUsersList = async(postId: number | string) => {
    HelperFunctions.showLoader();
    this.postLikedUsersListApiCallId = await apiCallFunction({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.postLikedUsersListApiEndpoint}?post_id=${postId}`,
    });
  }

  setLikedUserList = () => {
    this.setState({
      postLikedUserList: undefined,
    });
  };

  handleEventDetailCall = (message:Message) => {
    const responseJson = handleEventDetailCall(message);
    this.setState({
      eventDetail: responseJson.data
    })
    HelperFunctions.hideLoader();
  }

  getEventDetail = async ()=> {
    HelperFunctions.showLoader();
    const eventId = this.props.navigation?.getParam("eventId");
    this.apiGetEventDetails = await getEventDetail({
      token: this.state.token,
      endPoint: eventId
    })
  }

  getRecentPost = async ()=> {
    HelperFunctions.showLoader();
    const eventId = this.props.navigation?.getParam("eventId");
    this.recentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.recentPostsEndpoint}?event_id=${eventId}&per_page=1&page_no=1`,
    });
  }

  handleCreateRecentPostCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors && responseJson?.data) {
      this.setState({ recentPostDetails: responseJson.data })
    } else {
      const postErrorMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(postErrorMsg);
    }
    this.setState({ isPostUploading: false })
    HelperFunctions.hideLoader();
  };

  onCreateRecentPost = async () => {
    const { recentPostUploadedBlobIds, postDetails } = this.state;
    this.closeCreatePostModal();
    HelperFunctions.showLoader();
    const eventId = this.props.navigation?.getParam("eventId");
    const body: {body?: string, event_id?: string, blob_ids?: string[]} = {}
    if(postDetails){
      body.body = postDetails.postText
    }
    body.event_id = eventId;
    if(recentPostUploadedBlobIds.length) {
      const blobIds = recentPostUploadedBlobIds.join(",")
      body.blob_ids = [blobIds];
    }

    this.addPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.postApiMethodType,
      token: this.state.token,
      endPoint: `${configJSON.recentPostsEndpoint}`,
      body,
      contentType: configJSON.validationApiContentType,
    });
    this.setState({ postDetails: null, recentPostUploadedBlobIds: [] });
    this.uploadPostFileApiCallId = []
  };

  onClickPostShare = async (postDetails: IAddPost) => {
    this.setState({
      postDetails: postDetails,
      isPostUploading: true
    }, () => {
      if (postDetails?.postImgFiles?.length) {
        postDetails.postImgFiles.forEach(async (file) => {
          const formData = new FormData();
          formData.append("media", file);
  
          const fileUploadApiId = await HelperFunctions.uploadFilesToServer({
            token: this.state.token,
            body: formData,
            type: "formData"
          });
          this.uploadPostFileApiCallId.push(fileUploadApiId)
        })
      } else {
        this.onCreateRecentPost();
      }
    })
  }

  handlePostUploadFileApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && responseJson.blob_id){
      this.setState(prevState=>{
        return {
          recentPostUploadedBlobIds: [...prevState.recentPostUploadedBlobIds, responseJson.blob_id]
        }
      },()=>{
        const { recentPostUploadedBlobIds, postDetails } = this.state;
        if(postDetails && postDetails.postImages.length === recentPostUploadedBlobIds.length){
          this.onCreateRecentPost();
        }
      })
    }
  }

  likePost = async (postId: string) => {
    HelperFunctions.showLoader();
    const body = {
      "likeable_id": postId,
      "like_type": "BxBlockPosts::Post"
    }
    this.likeRecentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.postApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likeRecentPostApiEndpoint}`,
      body,
    });
  }
  
  disLikePost = async (postId: string) => {
    HelperFunctions.showLoader();
    const body = {
      "likeable_id": postId,
      "like_type": "BxBlockPosts::Post"
    }
    this.dislikeRecentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.postApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      body,
      endPoint: `${configJSON.likeRecentPostApiEndpoint}`,
    });
  }
  
  handleLikePostApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors) {
      const { recentPostDetails } = this.state;
      if(recentPostDetails) {
        const updatedRecentPost = { ...recentPostDetails };
          updatedRecentPost.attributes.liked_by_me = true;
          updatedRecentPost.attributes.total_likes += 1;
          this.setState({ recentPostDetails: updatedRecentPost })
      }
    } else {
      const likePostErrMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(likePostErrMsg);
    }
    HelperFunctions.hideLoader();
  }

  handleDisLikePostApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.errors) {
      const { recentPostDetails } = this.state;
      if(recentPostDetails) {
        const updatedRecentDislikePost = { ...recentPostDetails };
          updatedRecentDislikePost.attributes.liked_by_me = false;
          updatedRecentDislikePost.attributes.total_likes -= 1;
          this.setState({ recentPostDetails: updatedRecentDislikePost })
      }
    } else {
      const disLikePostErrMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(disLikePostErrMsg);
    }
    HelperFunctions.hideLoader();
  }

  handleRecentPostCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.length) {
      this.setState({ recentPostDetails: responseJson.data[0] });
    } else {
      this.setState({ recentPostDetails: null });
    }
    HelperFunctions.hideLoader();
  }

  handleEventDetailRsvpStatusUpdate = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson?.errors) {
      this.setState({
        isEventRsvpStatusUpdateMode: false
      },()=>{
        HelperFunctions.showSuccessToast("RSVP status updated.")
        this.getEventDetail();
        this.getRecentPost();
      })
    } else {
      const rsvpUpdateMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(rsvpUpdateMsg);
    }
    HelperFunctions.hideLoader();
  }

  onUpdateEventRsvpStatus = async(status:string, eventId:string | number) => {
    HelperFunctions.showLoader();
    const body = {
      status
    };
    this.apiUpdateRsvpStatusOfEvent = await apiCallFunction({
      method: configJSON.patchApiMethodType,
      contentType: configJSON.validationApiContentType,
      endPoint:`${configJSON.eventsEndpoint}/${eventId}/add_event_status`,
      token: this.state.token,
      body
    })
  }

  onRsvpUpdateModeChange = ()=>{
    this.setState(prevState=>{
      return {
        isEventRsvpStatusUpdateMode: !prevState.isEventRsvpStatusUpdateMode
      }
    })
  }

  openEventDeleteModal = (title: string, eventId?: string | number) => {
    this.setState({
      eventDeleteConfirmationPopup: {
        header: "Delete Event?",
        message: `Are you sure that you want to delete ${title}?`,
        type: "delete",
        eventId: eventId as string
      }
    })
  }

  onCloseEventDeleteConfirmationModal = () => {
    this.setState({
      eventDeleteConfirmationPopup: null
    })
  }

  handleDeleteEventCall = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && !responseJson.errors) {
      HelperFunctions.showSuccessToast("Event deleted successfully!")
      this.redirectTo("Events");
    } else {
      const deletErrorMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(deletErrorMsg);
    }
    HelperFunctions.hideLoader();
  }

  onAcceptEventDeleteConfirmation = async () => {
    if (this.state.eventDeleteConfirmationPopup) {
      const { type, eventId } = this.state.eventDeleteConfirmationPopup;
      if (type === "delete") {
        this.setState({
          eventDeleteConfirmationPopup: null
        })
        HelperFunctions.showLoader();
        this.deleteEventApiCallId = await apiCallFunction({
          method:configJSON.deleteApiMethodType,
          endPoint:`${configJSON.eventsEndpoint}/${eventId}`,
          contentType: configJSON.appointmentApiContentType,
          token: this.state.token
        })
      }
    }
  }

  openCreatePostModal = () => {
    this.setState({
      postDetails: {
        postText: "",
        postImages: [],
      }
    })
  }

  closeCreatePostModal = () => {
    this.setState({ postDetails: null })
  }

  onSubmitPostDetails = (postDetails: IAddPost) => {
    this.onClickPostShare(postDetails);
  }

  handleChangeCommentsPageNumber = () => {
    if(!this.state.isAllCommentsFetched){
      this.setState({ commentsPageNum: this.state.commentsPageNum + 1}, () => this.getCommentsList())
    }
  } 

  handlePostComentGetMentionsListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data) {
      const mentionsList: Array<IMentionInputType> = responseJson.data?.map((item: { attributes: { account_info: { id: number, user_name: string, name: string}}}) => {
        return {
          id: item.attributes.account_info.id.toString(),
          display: item.attributes.account_info.user_name,
        }
      })
      this.setState({
        searchedPostCommentMentionsList: [ ...mentionsList],
      });
    } else {
      this.setState({ searchedPostCommentMentionsList: [] });
    }
  };

  getPostComentMentionsList = async() => {
    const { eventDetail } = this.state;
    this.searchPostCommentMentionsApiCallId = await HelperFunctions.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.searchMentionsApiEndpoint}?event_id=${eventDetail?.id}`,
    });
  }

  getCommentsList = async ()=>{
    this.setState({
      isLoadingCommentsList: true
    })
    const { recentPostDetails } = this.state;
    this.getCommentsListApiCallId = await HelperFunctions.apiCall({
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.commentsApiEndpoint}?page_no=${this.state.commentsPageNum}&per_page=10&commentable_id=${recentPostDetails?.id}&order=desc`,
    });
  }

  handleGetCommentsListApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson?.meta?.next_page) {
      this.setState({ isAllCommentsFetched: true })
    }
    if(responseJson?.data?.length){
      this.setState({
        commentsList: [ ...this.state.commentsList, ...responseJson.data]
      })
    }
    this.getPostComentMentionsList();
    this.setState({
      isLoadingCommentsList: false
    })
  } 

  openPostCommentsModal = (postId: string)=>{
    this.setState({
      postCommentModal: {
        id: postId
      },
      commentsPageNum: 1
    }, () => { this.getCommentsList() })
  }

  closePostCommentsModal = ()=>{
    this.setState({
      postCommentModal: null,
      commentsPageNum: 1,
      commentsList: [],
      isAllCommentsFetched: false,
    })
  }

  scrollToTopOnPostComment = () => {
    const topCommentDiv = document.getElementById("top_comment_div");
    if (topCommentDiv) {
      topCommentDiv.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
  }

  onAddPostComment = async (comment:{ text: string, postId: string })=>{
    this.setState({
      isLoadingCommentsList: true
    })
    const body = {
      comment: {
        commentable_type: "BxBlockPosts::Post",
        commentable_id: comment.postId,
        comment: comment.text
      }
    }
    this.addCommentInRecentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.postApiMethodType,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.commentsApiEndpoint}`,
      body
    });
  }

  handleAddPostCommentApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson?.data){
      const { recentPostDetails, commentsList } = this.state;
      let updatedCommentsList = [{ ...responseJson.data}, ...commentsList];
      if(recentPostDetails){
        let updatedRecentPost = { ...recentPostDetails };
        updatedRecentPost.attributes.comments_count += 1;
        this.setState({ recentPostDetails: updatedRecentPost })
      }
      this.setState({
        commentsList: updatedCommentsList,
      })
      this.scrollToTopOnPostComment();
    } else{
      HelperFunctions.showErrorToast("Something went wrong! please try again")
    }
    this.setState({
      isLoadingCommentsList: false
    })
  }
  
  onDeletePostComment = async (commentId: string) => {
    this.setState({
      isLoadingCommentsList: true,
      selectedCommentId: commentId,
    })
    const eventId = this.props.navigation?.getParam("eventId");
    const { token, postCommentModal } = this.state;
    this.deleteCommentFromRecentPostApiCallId = await HelperFunctions.apiCall({
      method: configJSON.deleteApiMethodType,
      endPoint: `${configJSON.commentsApiEndpoint}/${commentId}?event_id=${eventId}&commentable_id=${postCommentModal?.id}`,
      contentType: configJSON.validationApiContentType,
      token
    })
  }

  handleDeletePostCommentApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.message) {
      const { recentPostDetails, commentsList, selectedCommentId } = this.state;
      let updatedCommentsList = commentsList.filter((item) => item.id !== selectedCommentId);
      if (recentPostDetails) {
        let updatedRecentPost = { ...recentPostDetails };
        updatedRecentPost.attributes.comments_count -= 1;
        this.setState({ recentPostDetails: updatedRecentPost })
      }
      this.setState({
        commentsList: updatedCommentsList,
      })
    } else {
      HelperFunctions.showErrorToast("Something went wrong! please try again")
    }
    this.setState({
      isLoadingCommentsList: false,
      selectedCommentId: null,
    })
  }

  // Customizable Area End
}
