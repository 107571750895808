import React from "react";
import { Typography, styled, Box, Tooltip } from "@material-ui/core";
import { EventInput } from '@fullcalendar/core';

interface CustomEventProps {
    redirectTo: (typeOfEvent: string, destination: string) => void;
    eventObj: EventInput;
}

const CustomEvent: React.FC<CustomEventProps> = (props: CustomEventProps) => {
    const { redirectTo, eventObj } = props;

    const getEventClassName = () => {
      const status = eventObj.event._def.extendedProps.status === "cancelled" ? "cancelled" : eventObj.event._def.extendedProps.eventType;
      return status;
    }

    return (
        <StyledEventForMonthView className={getEventClassName()} onClick={()=>redirectTo(eventObj.event._def.extendedProps.eventType, eventObj.event._def.publicId)}>
        <Tooltip placement="top" title={eventObj.event._def.title}>
          <p>
            {
              (eventObj.event._def.extendedProps.status === "active" || eventObj.event._def.extendedProps.status === "pending" || (eventObj.event._def.extendedProps.status === "completed")) ? (
                eventObj.event._def.title
              ) : (
                "Cancelled"
              )
            }
          </p>
        </Tooltip>
        <Box>
          <Typography className="event-type" component={"span"}>{eventObj.event._def.extendedProps.eventType}</Typography>
          {(eventObj.view.type === "timeGridDay" || eventObj.view.type === "timeGridWeek") &&
            <Tooltip placement="top" title={eventObj.event._def.extendedProps.description}>
              <Typography className="event-desc" component={"span"}>{eventObj.event._def.extendedProps.description}</Typography>
            </Tooltip>}
        </Box>
      </StyledEventForMonthView>
    )
}

const StyledEventForMonthView = styled("div")({
    // background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    padding: "0px 0px 0px 10px",
    borderTopLeftRadius: "3px",
    // border: "1px solid #19B5B945",
    // maxHeight: "5rem",
    width: "100%",
    "& p": {
      margin: 0,
      color: "ffffff",
      fontWeight: "500",
      fontSize: "0.875rem",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      "&:nth-child(2)": {
        fontSize: "0.75rem",
        color: "#FFFFFFCC",
        fontWeight: 400
      }
    },
    "&.active": {
      borderLeft: "2px solid #42FF99"
    },
    "&.event": {
      borderLeft: "2px solid rgba(234, 104, 245, 1)"
    },
    "&.appointment": {
      borderLeft: "2px solid rgba(200, 245, 104, 1)"
    },
    "&.reminder": {
      borderLeft: "2px solid rgba(255, 126, 85, 1)"
    },
    "&.cancelled": {
      borderLeft: "2px solid #FF0000",
      "& p:nth-child(1)": {
        textDecoration: "line-through"
      }
    },
    "& .event-type": {
      fontSize: "12px",
      textTransform: "capitalize",
      color: "rgba(255, 255, 255, 0.8)",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "block",
    },
    "& .event-desc": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "block",
      fontSize: "12px",
      // width: "35%",
      fontWeight: 400,
      color: "rgba(255, 255, 255, 0.8)"
    }
})

export default CustomEvent;
