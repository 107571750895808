const { HelperFunctions: helper } = require("../../components/src/HelperFunctions");
import { getStorageData, setStorageData } from "../../framework/src/Utilities";

export const registerSW = async () => {
    if (!('serviceWorker' in navigator)) {
        throw new Error("No support for service worker");
    }
    const registration = await navigator.serviceWorker.register("/sw.js");
    return registration;
}

const requestNotificationPermission = async () => {
    return await Notification.requestPermission();
}

const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

// checkServiceWorkerPresent();
export const subscribeToNotifications = async (token, userData) => {
    await setStorageData("authToken", token);
    await setStorageData("userData", JSON.stringify(userData));
    const permission = await requestNotificationPermission()
    if (permission !== "granted") {
        throw new Error("Request for notifications is not granted");
    }else{
        navigator.serviceWorker.ready.then(async (reg) => {
            const subscription = await reg.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array("BHeE5EWkCtUr0GTBmnheibsvnvTpCLZxOr38k_JNrFYfwt7VgZXo7XxuS8JwzDhIhNAeCXDEO5Jdq07fu3Amxyw=")
            })
            const json = subscription.toJSON();
            const body = {
                "subscription_type": "browser_push",
                "subscription_attributes": {
                    "endpoint":  json.endpoint,
                    "p256dh":  json.keys.p256dh,
                    "auth": json.keys.auth
                }
            }
            const token = await getStorageData("authToken");
            await helper.apiCall({
                method: "POST",
                endPoint: "bx_block_push_notifications/push_notification_subscriptions",
                contentType: "application/json",
                token: token,
                body: body
            })
        })
        await registerSW()
    }  
}