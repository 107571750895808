import React, { useEffect, useRef, useState } from "react";
import { Box, Menu, Typography, styled } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import { MoreIcon, commentIcon, likeIcon } from "../assets";
import { IComment, IMentionInputType, IPost, IMetaPagination } from "../../../../components/src/CommonTypes";
import { pdfPlaceholder } from "../../../postcreation/src/assets";
import LikedUserListModal from "./LikedUserListModal.web";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");
import Avatar from "./Avatar.web";
import CommentsList from "../../../comments/src/CommentsList.web";
import CommentTextField from "../../../comments/src/components/CommentTextField.web";

interface PostDetailProps {
    post: IPost;
    onDeletePost?: (title: string, eventId: string | number) => void;
    userId?: number;
    onEditPost?: (postDetails: IPost) => void;
    isLastElement: boolean;
    handleFetchNextPage: () => void;
    onLikePost?: (postId: string)=> void;
    likedUserList?: { id: string | number, attributes: { like_by_name: string} }[]
    setLikedUsers?: (event?: {[key: string]: string}[])=> void
    fetchLikedUsers?: (postId: number |  string) => void;
    onOpenCommentsModal?: (event: string)=>void
    onCloseCommentsModal?: (postId: string | number)=>void;
    isPostCommentSectionOpen?: boolean;
    comments?: IComment[];
    handleFetchNextComments?: (postId: string) => void;
    onAddComment?:(event:{text: string, postId: string})=> void;
    onLikeGroupPostComment?: (commentId: string | number)=> void;
    fetchLikedUsersForComment?: (commentId: number |  string) => void;
    onDeleteComment?:(id: string | number)=> void;
    isLoadingComments?: boolean;
    mentionesUserList?: IMentionInputType[];
    fetchMentionUsersList?: (query: string) => void;
    handleUpdateRepliesCount?: (commentId: number | string, action: "increase" | "decrease") => void;
    handleReplySectionShow?: (commentId: number | string) => void;
    commentsMetaData: IMetaPagination | null;
    handleReplySectionHide?: (commentId: number | string) => void;
}


const PostListCard: React.FC<PostDetailProps> = (props: PostDetailProps) => {
    const { 
        post, 
        onDeletePost, 
        userId, 
        onEditPost, 
        isLastElement, 
        handleFetchNextPage, 
        onLikePost, 
        likedUserList, 
        setLikedUsers, 
        onOpenCommentsModal, 
        onCloseCommentsModal, 
        comments, 
        onAddComment,
        onDeleteComment,
        isLoadingComments,
        fetchLikedUsers,
        handleFetchNextComments,
        mentionesUserList,
        commentsMetaData,
        handleReplySectionHide,
        handleReplySectionShow,
        handleUpdateRepliesCount,
        isPostCommentSectionOpen,
        onLikeGroupPostComment,
        fetchLikedUsersForComment,
    } = props;
    const [optionPostDropdownMenuAnchorEle, setOptionPostDropdownMenuAnchorEle] = useState<HTMLElement | null>(null);
    const targetRef: any = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [loggedInUserDetails, setLoggedInUserDetails] = useState<{id: number, full_name: string, image_url: string} | null>(null);
    const openPostOptionMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setOptionPostDropdownMenuAnchorEle(event.currentTarget)
    }

    const closePostOptionMenu = (event: MouseEvent) => {
        setOptionPostDropdownMenuAnchorEle(null)
    }
    const PostOptionDropdown = () => {
        return (
            <CustomMenuPost
                className="Custom_Menu_Post"
                data-test-id="custom-menu-post"
                open={Boolean(optionPostDropdownMenuAnchorEle)}
                onClose={(evt: MouseEvent) => closePostOptionMenu(evt)}
                anchorEl={optionPostDropdownMenuAnchorEle}
                PaperProps={{
                    style: {
                        transform: "translateY(0.5rem)"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
            >
                <StyledPostOptionDropdown id="post-options-menu" className="PostOptionDropdown_Web">
                            <Box id="edit-post-icon" className="item" onClick={() => {
                                if (onEditPost) {
                                    onEditPost(post);
                                    setOptionPostDropdownMenuAnchorEle(null);
                                }
                            }}>
                                <svg id="edit-post-icon-svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.99997 16H3.2615L13.4981 5.7634L12.2366 4.50188L1.99997 14.7385V16ZM0.5 17.5V14.1154L13.6904 0.930775C13.8416 0.793426 14.0086 0.687292 14.1913 0.612375C14.374 0.537458 14.5656 0.5 14.7661 0.5C14.9666 0.5 15.1608 0.535584 15.3488 0.60675C15.5368 0.6779 15.7032 0.791034 15.848 0.94615L17.0692 2.18268C17.2243 2.32754 17.3349 2.49424 17.4009 2.68278C17.4669 2.87129 17.5 3.05981 17.5 3.24833C17.5 3.44941 17.4656 3.64131 17.3969 3.82403C17.3283 4.00676 17.219 4.17373 17.0692 4.32495L3.88458 17.5H0.5ZM12.8563 5.1437L12.2366 4.50188L13.4981 5.7634L12.8563 5.1437Z" fill="white" />
                                </svg>
                                Edit
                            </Box>
                            <Box id="delete-post-icon" className="item"
                                onClick={() => {
                                    if (onDeletePost) {
                                        onDeletePost("Post", post.id);
                                        setOptionPostDropdownMenuAnchorEle(null);
                                    }
                                }}
                            >
                                <svg id="delete-post-icon-svg" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.3077 17.5017C2.80898 17.5017 2.38302 17.3251 2.02982 16.9719C1.67661 16.6187 1.5 16.1928 1.5 15.694V3.00176H0.5V1.50179H4.99997V0.617188H11V1.50179H15.5V3.00176H14.5V15.694C14.5 16.1992 14.325 16.6267 13.975 16.9767C13.625 17.3267 13.1974 17.5017 12.6922 17.5017H3.3077ZM13 3.00176H2.99997V15.694C2.99997 15.7838 3.02883 15.8575 3.08652 15.9152C3.14422 15.9729 3.21795 16.0018 3.3077 16.0018H12.6922C12.7692 16.0018 12.8397 15.9697 12.9038 15.9056C12.9679 15.8415 13 15.771 13 15.694V3.00176ZM5.40385 14.0018H6.90382V5.00176H5.40385V14.0018ZM9.09613 14.0018H10.5961V5.00176H9.09613V14.0018Z" fill="white" />
                                </svg>
                                Delete
                            </Box>
                </StyledPostOptionDropdown>
            </CustomMenuPost>
        )
    }
    useEffect(() => {
        const observer = new IntersectionObserver((entries: any) => {
            const [entry] = entries;
            setIsVisible(entry.isIntersecting);
        }, {
            threshold: 0.5
        });

        if (targetRef?.current) observer.observe(targetRef.current);

        return () => {
            if (targetRef?.current) observer.unobserve(targetRef.current);
        };
    }, [targetRef?.current]);

    useEffect(() => {
        if(isVisible){
            handleFetchNextPage()
        }
    }, [isVisible])

    useEffect(() => {
        (async () => {
            const userData = await helper.getUserData();
            setLoggedInUserDetails(userData);
        })();
    }, [])

    const onClickLikesHandler = ()=>{
        fetchLikedUsers?.(post.id)
    }

    const onAddCommentHandler = (text: string)=>{
      if(onAddComment){
        onAddComment({text, postId: post.id})
      }
    }

    const openCommentsSection = (event: React.MouseEvent<HTMLImageElement>) => {
        if(!isPostCommentSectionOpen) {
            onOpenCommentsModal?.(post.id);
        }
    }

    return (
        <StyledPostDetail ref={isLastElement ? targetRef : null} className="PostDetailCard_Web_Container">
            <Box className={"post"}>
                <Box className="post-detail">
                    <Box className="post-header-container">
                        <Box className="post-owner">
                            <Box className="created-by">
                                <Box className="owner-details">
                                    <Avatar 
                                      text={post.attributes.account_info.full_name} 
                                      image_url={post.attributes.account_info.image_url} 
                                    />
                                    <Box display={"flex"} flexDirection={"column"}>
                                        <Typography className="post-creator-name" component={"span"}>{post.attributes.account_info.full_name}</Typography>
                                        <Typography className="post-create-time" component={"span"}>{post.attributes.created_at}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {userId && userId === post.attributes.account_id && (
                            <>
                                <img data-test-id="more-icon" onClick={(event) => openPostOptionMenu(event)} src={MoreIcon} />
                                {PostOptionDropdown()}
                            </>
                        )}
                    </Box>
                    <Box className="post-description">
                        <Typography component={"p"}>
                            {post.attributes.body}
                        </Typography>
                    </Box>
                    <Box className="post-files">
                        {post.attributes.images_and_videos.map((item) => <Box key={item.id}>
                            {item.url.includes("type=image") && <img className="post-img cursor-pointer" src={item.url} alt="uploaded-post" onClick={() => helper.openFileInNewTab(item.url)} />}
                            {item.url.includes("type=application") && <img className="post-img cursor-pointer" src={pdfPlaceholder} alt="uploaded-post" onClick={() => helper.openFileInNewTab(item.url)} />}
                            {item.url.includes("type=video") && <video controls controlsList="nodownload" className="post-img" src={item.url} />}</Box>)}
                    </Box>
                    <Box className="like_comment">
                        <Typography component={"span"} className="text-underline" id="likes_count" onClick={onClickLikesHandler}>
                            {post.attributes.total_likes}
                            {" "}
                            {
                                post.attributes.total_likes > 1 ? "Likes" : "Like"
                            }
                        </Typography>
                        <Typography component={"span"}>.</Typography>
                        {!isPostCommentSectionOpen ? <Typography id="open-comments-text" onClick={openCommentsSection}
                            component={"span"} className="text-underline"
                        >{post.attributes.comments_count} {
                                post.attributes.comments_count > 1 ? "Comments" : "Comment"
                            }</Typography>
                            : <Typography id="hide-post-comments-text" className="text-underline" onClick={() => onCloseCommentsModal?.(post.id)}
                                component={"span"}
                            >Hide comments</Typography>}
                    </Box>
                    <Box className="like_comment_action">
                        {post.attributes.liked_by_me ? <FavoriteIcon className="liked_by_me" onClick={()=> onLikePost?.(post.id)} /> : <img src={likeIcon} alt="like" onClick={()=> onLikePost?.(post.id)} />}
                        <img src={commentIcon} alt="comment"
                            onClick={openCommentsSection}
                        />                      
                    </Box>
                    {isPostCommentSectionOpen &&
                        <Box width={"99%"}>
                            <Box className="post-comment-user">
                                {loggedInUserDetails && <Box>
                                    <Avatar
                                        text={loggedInUserDetails.full_name}
                                        image_url={loggedInUserDetails.image_url}
                                    />
                                </Box>}
                                <CommentTextField widthOfComponent="97%" widthOfTextInput="98%" mentionesUserList={mentionesUserList} onAddComment={onAddCommentHandler} hideSendIcon />
                            </Box>
                            <CommentsList
                                commentsList={comments}
                                id="Event-Comments"
                                isCommentsLoading={isLoadingComments}
                                userId={userId}
                                navigation={undefined}
                                onDeleteComment={onDeleteComment}
                                commentsMetaData={commentsMetaData}
                                fetchLikedUsersList={fetchLikedUsersForComment}
                                handleLikeDislikeComment={onLikeGroupPostComment}
                                handleFetchNextPageComments={() => handleFetchNextComments?.(post.id)}
                                handleReplySectionShow={(commentId: number | string) => handleReplySectionShow?.(commentId)}
                                handleReplySectionHide={(commentId: number | string) => handleReplySectionHide?.(commentId)}
                                handleUpdateRepliesCount={(commentId: number | string, action: "increase" | "decrease") => handleUpdateRepliesCount?.(commentId, action)}
                                mentionsList={mentionesUserList}
                            />
                        </Box>
                    }
                </Box>
            </Box>
            {
                likedUserList && (
                    <LikedUserListModal list={likedUserList} open={Boolean(likedUserList)} onClose={()=> setLikedUsers?.(undefined)} />
                )
            }
        </StyledPostDetail>
    )
}

const StyledPostDetail = styled("div")({
    "&.PostDetailCard_Web_Container": {
        display: "flex",
        gap: "0.8rem",
        flexDirection: "column",
        "& .post": {
            display: "flex",
            padding: "1rem",
            gap: "2rem",
            borderRadius: "1rem",
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
            "& .img-container": {
                "& > img": {
                    width: "100%",
                    height: "315px",
                    borderRadius: "1rem",
                    objectFit: "fill",
                }
            },
            "& .post-detail": {
                flex: 1,
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                display: "flex",
                "& .post-header-container": {
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    "& > img": {
                        cursor: "pointer"
                    }
                },
                "& .post-owner": {
                    gap: "4rem",
                    display: "flex",
                    alignItems: "center",
                    "& .created-by": {
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.8rem",
                        "& .header": {
                            fontWeight: 500,
                            fontSize: "0.875rem",
                            color: "rgba(255, 255, 255, 0.8)"
                        },
                        "& .owner-details": {
                            display: "flex",
                            color: "rgba(255, 255, 255, 1)",
                            alignItems: "center",
                            gap: "0.5rem",
                            "& .avatar": {
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "rgba(37, 236, 242, 1)",
                                width: "36px",
                                height: "36px",
                                border: "1px solid rgba(37, 236, 242, 1)",
                                borderRadius: "100%"
                            },
                            "& .post-creator-name": {
                                fontSize: "14px",
                            },
                            "& .post-create-time": {
                                fontSize: "12px",
                                color: "rgba(255, 255, 255, 0.8)"
                            },
                        },
                    },
                    [`@media screen and (max-width: 1100px)`]: {
                        gap: "0.5rem",
                        justifyContent: "space-between",
                        flexWrap: "wrap"
                    },
                },
                "& .post-description": {
                    wordBreak: "break-word",
                    fontSize: "16px",
                    color: "rgba(255, 255, 255, 1)"
                },
                "& .post-files": {
                    display: "flex",
                    overflowX: "auto",
                    alignItems: "center",
                    gap: "15px",
                    "& .post-img": {
                        width: "104px",
                        height: "105px",
                        objectFit: "cover",
                        borderRadius: "16px",
                    },
                },
                "& .like_comment": {
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    "& span": {
                        fontSize: "12px",
                        color: "rgba(255, 255, 255, 0.8)"
                    }
                },
                "& .like_comment_action": {
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    position: "relative",
                    "& .liked_by_me": {
                        color: "red"
                    },
                    "& > *":{
                       cursor: "pointer"
                    }
                },
                "& .post-comment-user": {
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "10px",
                }
            },
            [`@media screen and (max-width: 900px)`]: {
                "& .img-container": {
                    display: "none"
                }
            },
        },
        "& .post-card-bg": {
            background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        }
    }
})

const StyledPostOptionDropdown = styled("div")({
    "&.PostOptionDropdown_Web": {
        display: "flex",
        flexDirection: "column",
        width: "9.8125rem",
        border: "1px solid #19B5B945",
        borderRadius: "0.5rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        padding: "0.25rem 0rem",
        "& .item": {
            display: "flex",
            alignItems: "center",
            color: "#ffffff",
            fontSize: "1rem",
            fontWeight: 400,
            gap: "0.5rem",
            cursor: "pointer",
            padding: "0.25rem 0.7rem",
            backgroundColor: "transparent",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
                backgroundColor: "#0000001A"
            }
        }
    }
})

const CustomMenuPost = styled(Menu)({
    "&.Custom_Menu_Post": {
        "& .MuiList-padding": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "transparent"
        }
    }
})

export default PostListCard;