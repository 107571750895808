// Customizable Area Start
import React from "react";
import ViewGroupChatController, {
    Props,
    messages
} from "./ViewGroupChatController";
import { styled } from "@material-ui/styles";
import { ChatInputField } from "../../../components/src";
import GroupChatInfo from "./GroupChatInfo.web";
// Customizable Area End

export default class ViewGroupChat extends ViewGroupChatController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    Message = (message: {
        name: string;
        message: string;
        isSendByMe: boolean;
        time: string;
    }) => {
        return (
            <StyledMessage className={`ViewGroupChat_StyledMessage ${message.isSendByMe ? "send" : "received"}`}>
                {
                    !message.isSendByMe && (
                        <div className="avatar-text">{message.name[0]}</div>
                    )
                }
                <div className="message">
                    {
                        !message.isSendByMe && (
                            <p className="name">{message.name}</p>
                        )
                    }
                    <p className="text">{message.message}</p>
                    <p className="time">{message.time}</p>
                </div>
            </StyledMessage>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            openChatInfo
        } = this.state;
        return (
            <StyledViewGroupChat className={`ViewGroupChat_StyledViewGroupChat ${openChatInfo ? "info-active" : ""}`}>
                <div className="chats">
                    <div className="header">
                        <div className="clickable-container" onClick={this.toggleChatInfo}>
                            <div className="chat-image">GI</div>
                            <p>Gaming Institute</p>
                        </div>
                    </div>
                    <div className="chat-messages">
                        <div className="messages">
                            <div className="welcome-message">Welcome to the appointment chat</div>
                            {
                                messages && messages.map((message, message_index) => {
                                    return (
                                        <React.Fragment key={message_index}>
                                            {this.Message(message)}
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                        <div className="input-field">
                            <ChatInputField postImages={[]} />
                        </div>
                    </div>
                </div>
                {
                    openChatInfo && (
                        <div className="info">
                           <GroupChatInfo {...this.props} closeInfo={this.toggleChatInfo} />
                        </div> 
                    )
                }
            </StyledViewGroupChat>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledViewGroupChat = styled("div")({
    "&.ViewGroupChat_StyledViewGroupChat": {
        display: "flex",
        height: "100%",
        "& > .chats": {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            "& > .header": {
                display: "flex",
                padding: "1rem",
                boxSizing: "border-box",
                width: "100%",
                height: "4.75rem",
                alignItems: "center",
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                "& > .clickable-container": {
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    cursor: "pointer",
                    "& > .chat-image": {
                        width: "2.5rem",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgba(0, 0, 0, 0.2)",
                        borderRadius: "0.5rem",
                        fontSize: "1.25rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.8)"
                    },
                    "& > p": {
                        fontSize: "1.125rem",
                        fontWeight: 500,
                        color: "rgba(255, 255, 255, 1)"
                    }
                }
            },
            "& > .chat-messages": {
                display: "flex",
                width: "100%",
                height: "calc(100% - 4.75rem)",
                flexDirection: "column",
                "& > .messages": {
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    gap: "1.5rem",
                    padding: "1rem",
                    boxSizing: "border-box",
                    overflow: "auto",
                    "& > .welcome-message": {
                        fontSize: "0.75rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.8)",
                        textAlign: "center"
                    }
                },
                "& > .input-field": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.5rem 1rem 1rem"
                }
            }
        },
        "& > .info": {
            width: "50%"
        },
        "&.info-active": {
            "& > .chats": {
                width: "50%",
                borderRight: "1px solid rgba(255, 255, 255, 0.1)"
            }
        }
    }
})

const StyledMessage = styled("div")({
    "&.ViewGroupChat_StyledMessage": {
        display: "flex",
        gap: "0.25rem",
        alignItems: "flex-end",
        width: "100%",
        "& > .message": {
            background: "rgba(0, 0, 0, 0.15)",
            padding: "0.375rem 0.5rem",
            borderRadius: "0.75rem 0.75rem 0.75rem 0",
            boxSizing: "border-box",
            "& > .name": {
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "rgba(244, 116, 116, 1)"
            },
            "& > .text": {
                fontSize: "0.875rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 1)"
            },
            "& > .time": {
                fontSize: "0.75rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.5)",
                textAlign: "right"
            }
        },
        "&.send": {
            justifyContent: "flex-end",
            "& > .message": {
                borderRadius: "0.75rem 0.75rem 0rem 0.75rem"
            }
        }
    }
})
// Customizable Area End
