import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  cakeIcon,
  schooldropIcon,
  schoolpickIcon,
  VetIcon,
  RentIcon,
  GetTogetherIcon,
  MeetingIcon,
  DoctorIcon,
  GymIcon,
  FlightIcon,
  OutforCoffeeIcon,
  CustomIcon,
  ActivityIcon,
  FeesIcon,
} from "./assets";
import moment from "moment";
import scale from "../../../components/src/Scale";
import { configJSON } from "./AddEventDetailController";
import { ImageSourcePropType } from "react-native";
import { Message } from "../../../framework/src/Message";
import { getStorageData } from '../../../framework/src/Utilities';
import { EventData } from "./types";
import { IAddPost, IEventCard, SearchLocation } from "../../../components/src/CommonTypes";
const { ApiCallFunction: apiCallFunction } = require("../../appointmentmanagement/src/ApiCallFunction");
const { HelperFunctions } = require("../../../components/src/HelperFunctions");
import { LatLngExpression } from 'leaflet';
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  flatlistdata: Array<FlatListData>;
  isShowMap: boolean;
  token: string | null;
  isRsvpStatusUpdateMode: boolean;
  upcomingEventsList: EventData[];
  bestEventsList: EventData[];
  nextEvent:EventData | null;
  onGoingEvents: EventData[];
  eventsCardList: IEventCard[];
  bestEventsCardsList: IEventCard[];
  confirmationPopUpData: {
    header: string;
    message: string;
    type: string;
    eventId: string;
  } | null;
  userId: number | undefined;
  addPostDetails: IAddPost | null;
  eventFilters: {
    position?: LatLngExpression | null,
    location?: SearchLocation | null,
    radius?: number
  };
  recentLocations: SearchLocation[];
  predictions: SearchLocation[]
  selectedNearByLocationCoordinates: LatLngExpression | null;
  isFilterApplied: boolean;
  upcomingEventsCount: number;
  bestEventsCount: number;
  // Customizable Area End
}

interface SS {
  id: string;
}

interface FlatListData {
  leftMargin: number;
  image: ImageSourcePropType;
  buttonTitle: string;
}

export default class EventsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetNextEventApiId: string = "";
  apiGetUpcomingEventsApiId: string = "";
  apiGetBestEventsApiId: string = "";
  rsvpEventStatusUpdateApiCallId: string = "";
  deleteEventApiCallId: string = "";
  recentSearchLocationApiCallId: string = "";
  searchLocationApiCallId: string = "";
  getPlaceCoordinatesApiId: string = "";
  private readonly errorTitle = "Error";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      isShowMap: false,
      flatlistdata: [
        {
          leftMargin: scale(7),
          image: cakeIcon,
          buttonTitle: configJSON.birthday,
        },
        {
          leftMargin: scale(11),
          image: schooldropIcon,
          buttonTitle: configJSON.schollDropOff,
        },
        {
          leftMargin: scale(12),
          image: schoolpickIcon,
          buttonTitle: configJSON.schoolPickUp,
        },
        {
          leftMargin: scale(9),
          image: GetTogetherIcon,
          buttonTitle: configJSON.getToghether,
        },
        {
          leftMargin: scale(8),
          image: MeetingIcon,
          buttonTitle: configJSON.meeting,
        },
        {
          leftMargin: scale(7),
          image: OutforCoffeeIcon,
          buttonTitle: configJSON.outForCoffee,
        },
        {
          leftMargin: scale(9),
          image: GymIcon,
          buttonTitle: configJSON.gym,
        },
        {
          leftMargin: scale(11),
          image: FlightIcon,
          buttonTitle: configJSON.flight,
        },
        {
          leftMargin: scale(9),
          image: DoctorIcon,
          buttonTitle: configJSON.doctor,
        },
        {
          leftMargin: scale(8),
          image: VetIcon,
          buttonTitle: configJSON.vet,
        },
        {
          leftMargin: scale(8),
          image: RentIcon,
          buttonTitle: configJSON.rent,
        },
        {
          leftMargin: scale(5),
          image: FeesIcon,
          buttonTitle: configJSON.fees,
        },
        {
          leftMargin: scale(9),
          image: ActivityIcon,
          buttonTitle: configJSON.activity,
        },
        {
          leftMargin: scale(10),
          image: CustomIcon,
          buttonTitle: configJSON.custom,
        },
      ],
      token:null,
      isRsvpStatusUpdateMode: false,
      nextEvent: null,
      upcomingEventsList: [],
      bestEventsList: [],
      eventsCardList: [],
      bestEventsCardsList: [],
      confirmationPopUpData: null,
      userId: undefined,
      addPostDetails: null,
      eventFilters: {
        position: null,
        location: null
      },
      recentLocations: [],
      predictions: [],
      selectedNearByLocationCoordinates: null,
      isFilterApplied: false,
      onGoingEvents: [],
      upcomingEventsCount: 0,
      bestEventsCount: 0
      // Customizable Area End
    };
  }

  navigateToAddEventDetail = (item: FlatListData) => {
    this.setState({ isShowMap: false }, () =>
      this.props.navigation.navigate("AddEventDetail", {
        event: item.buttonTitle,
      })
    );
  };
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getToken();
    (async () => {
      const userData = await HelperFunctions.getUserData();
      if (userData) {
        this.setState({userId: userData.id});
      }
    })();
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if(!token){
        token = await getStorageData("authToken")
      }
      this.setToken(token)
    }
    const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      switch(apiCallId) {
        case this.apiGetNextEventApiId: 
          this.handleNextEventCall(message);
          break;
        case this.rsvpEventStatusUpdateApiCallId:
          this.handleEventRsvpStatusUpdate(message);
          break;
        case this.deleteEventApiCallId:
          this.handleDeleteEvent(message);
          break;
        case this.apiGetUpcomingEventsApiId:
          this.handleUpcomingEventsCall(message);
          break;
        case this.apiGetBestEventsApiId:
          this.handleBestEventsCall(message);
          break;
        case this.searchLocationApiCallId:
          this.handleSearchLocationCall(message)
          break;
        case this.recentSearchLocationApiCallId:
          this.handleRecentSearchLocationCall(message)
          break;
        case this.getPlaceCoordinatesApiId:
          this.handleLocationCoordinatesApiCall(message);
          break;
      }
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  setToken = (token:string | null)=>{
    this.setState({ token: token },()=>{
      this.getNextEvent();
      this.getUpcomingEvents();
      this.getBestEvents();
      this.getRecentLocations();
    });
  }

  handleNextEventCall = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (!responseJson?.errors && responseJson?.data?.length > 0) {
      this.setState({
        onGoingEvents: responseJson.data,
      });
    } else {
      this.setState({ onGoingEvents: [] });
    }
    HelperFunctions.hideLoader();
  }

  getNextEvent = async ()=>{
   HelperFunctions.showLoader();
   this.apiGetNextEventApiId = await apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.nextEventAPIEndpoint,
      contentType: configJSON.appointmentApiContentType,
      token:this.state.token
    })
  }

  handleEventRsvpStatusUpdate= (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson?.errors) {
      this.setState({
        isRsvpStatusUpdateMode: false
      },()=>{
        this.getNextEvent();
      })
    } else {
      const errorMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(errorMsg);
    }
    HelperFunctions.hideLoader();
  }

  onUpdateRsvpStatus = async(status:string, eventId:string | number) => {
    HelperFunctions.showLoader();
    const body = {
      status
    };
    this.rsvpEventStatusUpdateApiCallId = await apiCallFunction({
      method: configJSON.patchApiMethodType,
      endPoint:`${configJSON.eventsEndpoint}/${eventId}/add_event_status`,
      contentType: configJSON.validationApiContentType,
      token: this.state.token,
      body
    })
  }

  onRsvpUpdateModeChange = ()=>{
    this.setState(prevState=>{
      return {
        isRsvpStatusUpdateMode: !prevState.isRsvpStatusUpdateMode
      }
    })
  }

  handleDeleteEvent = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (responseJson && !responseJson.errors) {
      HelperFunctions.showSuccessToast("Event deleted successfully!")
      this.getNextEvent();
      this.getUpcomingEvents();
    } else {
      const errorToastMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      HelperFunctions.showErrorToast(errorToastMsg);
    }
    HelperFunctions.hideLoader();
  }

  onDeleteEvent = async (eventId: string | number) =>{
    this.setState({
      confirmationPopUpData: null
    })
    HelperFunctions.showLoader();
    this.deleteEventApiCallId = await apiCallFunction({
      method: configJSON.deleteApiMethodType,
      endPoint:`${configJSON.eventsEndpoint}/${eventId}`,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  formatEventCardsTime = (date: string, time: string) => {
    const dateTime = moment(date + " " + time);
    return dateTime.format("DD MMM, ddd hh:mm A");
  }

  handleSetEventsCardsList = () => {
    const { upcomingEventsList } = this.state;
    const cardsList = upcomingEventsList.map((item) => {
      const cardObj: IEventCard = {
        id: item.id,
        image: item.attributes.image_url || "",
        isTrending: false,
        location: item.attributes.location?.city || "",
        price: `₹${item.attributes?.ticket_price}`,
        time: this.formatEventCardsTime(item.attributes.date_time_event, item.attributes.start_time_event),
        title: item.attributes.title,
      };
      return cardObj;
    });
    return cardsList;
  }

  handleSetBestEventsCardsList = () => {
    const { bestEventsList } = this.state;
    const bestCardList = bestEventsList.map((eventItem) => {
      const bestCardObj: IEventCard = {
        id: eventItem.id,
        image: eventItem.attributes.image_url || "",
        isTrending: false,
        location: eventItem.attributes.location?.city || "",
        price: `₹${eventItem.attributes?.ticket_price}`,
        time: this.formatEventCardsTime(eventItem.attributes.date_time_event, eventItem.attributes.start_time_event),
        title: eventItem.attributes.title,
      };
      return bestCardObj;
    });
    return bestCardList;
  }

  handleUpcomingEventsCall = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors && responseJson.data && responseJson.data.length) {
      this.setState({
        upcomingEventsList: responseJson.data,
      }, () => {
        const cardsList = this.handleSetEventsCardsList();
        this.setState({ eventsCardList: cardsList, upcomingEventsCount: cardsList.length })
      });
    } else {
      this.setState({ upcomingEventsList: [], eventsCardList: [], upcomingEventsCount: 0 });
    }
    this.setState({
      isFilterApplied: false
    })
    HelperFunctions.hideLoader();
  }

  getUpcomingEvents = async () => {
    HelperFunctions.showLoader();
    let upcomingEventsEndpoint = configJSON.upcomingEventsAPIEndpoint;
    const { eventFilters } = this.state;
    if(eventFilters?.position) {
      let position: string | string[] = eventFilters.position.toString();
      position = position.replace(/(^[^(]*\()|(\)[^)]*$)/g, '');
      position = position.split(",");
      upcomingEventsEndpoint += `?current_latitude=${position[0]}&current_longitude=${position[1]}&radius_in_km=${eventFilters.radius}`
      this.setState({
        isFilterApplied: true
      })
    }
    this.apiGetUpcomingEventsApiId = await apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: upcomingEventsEndpoint,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  handleBestEventsCall = (message:Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (!responseJson.errors && responseJson?.data?.length) {
      this.setState({
        bestEventsList: responseJson.data,
      }, () => {
        const bestCardsList = this.handleSetBestEventsCardsList();
        this.setState({ bestEventsCardsList: bestCardsList, bestEventsCount: bestCardsList.length })
      });
    } else {
      this.setState({ bestEventsCardsList: [], bestEventsList: [], bestEventsCount: 0 });
    }
    HelperFunctions.hideLoader();
  }

  getBestEvents = async () => {
    HelperFunctions.showLoader();
    let endpoint = configJSON.bestEventsAPIEndpoint;
    const { eventFilters } = this.state;
    if(eventFilters?.position){
      let position: string | string[] = eventFilters.position.toString();
      position = position.replace(/(^[^(]*\()|(\)[^)]*$)/g, '');
      position = position.split(",");
      endpoint += `?current_latitude=${position[0]}&current_longitude=${position[1]}&radius_in_km=${eventFilters.radius}`
    }
    this.apiGetBestEventsApiId = await apiCallFunction({
      method: configJSON.validationApiMethodType,
      endPoint: endpoint,
      contentType: configJSON.appointmentApiContentType,
      token: this.state.token
    })
  }

  redirectTo = (endpoint: string, params?:{[key:string]:string | number}) => {
    this.props.navigation.navigate(endpoint, params)
  }

  onOpenCreatePostModal = () => {
    this.setState({
      addPostDetails: {
        postText: "",
        postImages: [],
      }
    })
  }

  onCloseCreatePostModal = () => {
    this.setState({ addPostDetails: null })
  }

  onSubmitPostDetails = (postDetails: IAddPost) => {
    this.onCloseCreatePostModal();
  }

  openDeleteModal = (title: string, eventId: string | number) => {
    this.setState({
      confirmationPopUpData: {
        header: "Delete Event?",
        message: `Are you sure that you want to delete ${title}?`,
        type: "delete",
        eventId: eventId as string
      }
    })
  }

  onCloseConfirmationModal = () => {
    this.setState({
      confirmationPopUpData: null
    })
  }

  onAcceptConfirmation = async () => {
    if (this.state.confirmationPopUpData) {
      const { type, eventId } = this.state.confirmationPopUpData;
      if (type === "delete") {
        this.onDeleteEvent(eventId)
      }
    }
  }

  applyFilter = (filter:{ position: LatLngExpression | null, location: SearchLocation | null, radius?: number })=>{
    this.setState({
      eventFilters: filter
    }, () => {
      this.getBestEvents();
      this.getUpcomingEvents();
    })
  }

  handleLocationCoordinatesApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.coordinates?.length) {
      this.setState({selectedNearByLocationCoordinates: responseJson.coordinates})
    }
  }

  getLocationCoordinates = async(place: string) => {
    this.getPlaceCoordinatesApiId = await HelperFunctions.apiCall({
      method: "GET",
      token: this.state.token,
      endPoint: `${configJSON.detectCoordinatesApiEndpoint}?address=${place}`,
      contentType: configJSON.validationApiContentType
    })
  }

  getRecentLocations = async ()=>{
    this.recentSearchLocationApiCallId = await HelperFunctions.apiCall({
      method: "GET",
      token: this.state.token,
      endPoint: `bx_block_maps3/locations/recent_location?type=event`,
      contentType: configJSON.validationApiContentType
    })
  }

  handleRecentSearchLocationCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data && responseJson.data.length) {
      const locations:SearchLocation[] = [];
      responseJson.data.map((prediction:{id:string,attributes:{[key:string]:string}})=>{
        const location:SearchLocation = {
          structured_formatting: {
            main_text: prediction.attributes.city,
            secondary_text: `${prediction.attributes.state}, ${prediction.attributes.country}`
          },
          description: "",
          place_id: prediction.attributes.place_id
        };
        locations.push(location);
      })
      this.setState({
        recentLocations: locations
      })
    }else{
      this.setState({
        recentLocations: []
      })
    }
  }

  onSearchLocation = async (location: string) => {
    this.searchLocationApiCallId = await HelperFunctions.apiCall({
      method: "GET",
      token: this.state.token,
      endPoint: `bx_block_maps3/locations?query=${location}`,
      contentType: configJSON.validationApiContentType
    })
  }

  handleSearchLocationCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && !responseJson.errors && responseJson.predictions) {
      const locations:SearchLocation[] = [];
      responseJson.predictions.map((prediction:SearchLocation)=>{
        const location:SearchLocation = {
          structured_formatting: prediction.structured_formatting,
          description: prediction.description,
          place_id: prediction.place_id,
        };
        locations.push(location);
      })
      this.setState({
        predictions: locations
      })
    }else{
      this.setState({
        predictions: []
      })
    }
  }
  // Customizable Area End
}
