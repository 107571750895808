// Customizable Area Start
import React from "react";
import {
    Box,
    CircularProgress,
    Grid,
    styled
} from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import BucketListController, {
    Props
} from "./BucketListController";
import CustomNoDataComponent from "../../../components/src/CustomNoDataComponent.web";
import { Button, ConfirmationPopUp } from "../../../components/src";
import BucketListCard from "./components/BucketListCard.web";
import BucketListHeader from "./components/BucketListHeader.web";
// Customizable Area End

export default class BucketList extends BucketListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    BucketList = () => {
        const { bucketListMetadata, isLoadingNextPage, totalCountOfItems, search, selectedTab, confirmationGoalDeletePopUpData } = this.state;
        return (
            <StyledBucketListContainer className="StyledBucketList_Container_Web">
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <BucketListHeader searchText={search} redirectTo={this.redirectTo} allItemsCount={totalCountOfItems.total_completed_count + totalCountOfItems.total_todo_count} completedItemsCount={totalCountOfItems.total_completed_count} todoItemsCount={totalCountOfItems.total_todo_count} onSearch={this.onSearch} onChangeTab={this.onChangeTab} />
                    </Grid>
                </Grid>
                <Grid className="bucket-list-container">
                    {this.getTabFilteredData().length > 0 &&
                        this.getTabFilteredData().map((item) =>
                            <Grid key={item.id} id="bucket_list_card" item>
                                <BucketListCard onDeleteClick={(title: string, goalId: string) => this.openGoalDeleteModal(title, goalId)} goalData={item} redirectTo={this.redirectTo} onMarkAsComplete={(goalId: string) => this.onMarkGoalAsCompleted(goalId)} />
                            </Grid>
                        )
                    }
                </Grid>
                {bucketListMetadata?.next_page &&
                    <Box className="show_more_grid">
                        <Button type="outlined" text="Show more" onClick={this.handleFetchNextGoals} buttonId="load_more_goals" />
                    </Box>}
                {isLoadingNextPage &&
                    <Box className="common-circular-progress-loader">
                        <CircularProgress />
                    </Box>
                }
                {(this.getTabFilteredData().length === 0 && !isLoadingNextPage) && <CustomNoDataComponent titleMsg={`No goals in ${selectedTab} bucketlist`} subTitleMsg="No goals. Stay tuned for the updates." />}
                <ConfirmationPopUp
                    open={Boolean(confirmationGoalDeletePopUpData)}
                    header={confirmationGoalDeletePopUpData?.header as string}
                    message={confirmationGoalDeletePopUpData?.message as string}
                    onAccept={this.onAcceptGoalDeleteConfirmation}
                    onReject={this.onCloseDeleteGoalConfirmationModal}
                    onClose={this.onCloseDeleteGoalConfirmationModal}
                />
            </StyledBucketListContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <NavigationMenu {...this.props} children={this.BucketList()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledBucketListContainer = styled("div")({
    "&&.StyledBucketList_Container_Web": {
        flexDirection: "column",
        display: "flex",
        padding: "1rem 1rem",
        gap: "1.5rem",
        "& .ellipsis": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
        },
        "& .page-header": {
            fontSize: "1.125rem",
            fontWeight: 500,
            gap: "0.5rem",
            color: "white",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            width: "fit-content"
        },
        "& .bucket-list-title": {
            fontSize: "16px",
            color: "#FFFFFF",
        },
        "& .add-btn-grid": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
        },
        "& .bucket-list-container": {
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            rowGap: "1.5rem",
            columnGap: "1.5rem",
            [`@media screen and (max-width: 1555px)`]: {
                gridTemplateColumns: "repeat(3, 1fr)",
            },
            [`@media screen and (max-width: 1230px)`]: {
                gridTemplateColumns: "repeat(2, 1fr)",
            },
            [`@media screen and (max-width: 780px)`]: {
                gridTemplateColumns: "repeat(1, 1fr)",
            },
        },
        "& .show_more_grid": {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }
    }
})
// Customizable Area End
