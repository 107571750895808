import React from "react";
import { styled } from "@material-ui/styles";

interface Props {
    value: string;
    onChange: (event: string)=>void;
    placeholder?: string;
}


const SearchField: React.FC<Props> = (props: Props) => {
    const { value, onChange, placeholder = "Search group name" } = props;

    return (
        <StyledSearchField className="Groups_SearchField_StyledSearchField">
            <div className="icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.51965 12.6135C4.81196 12.6135 3.36582 12.0212 2.18122 10.8366C0.996605 9.652 0.404297 8.20585 0.404297 6.49816C0.404297 4.79048 0.996605 3.34433 2.18122 2.15971C3.36582 0.975112 4.81196 0.382812 6.51965 0.382812C8.22733 0.382812 9.67348 0.975112 10.8581 2.15971C12.0427 3.34433 12.635 4.79048 12.635 6.49816C12.635 7.21226 12.5151 7.89431 12.2754 8.54431C12.0356 9.19431 11.7158 9.7597 11.3158 10.2405L17.0696 15.9943C17.2081 16.1328 17.2789 16.3068 17.2821 16.5164C17.2853 16.726 17.2145 16.9033 17.0696 17.0481C16.9248 17.193 16.7491 17.2654 16.5427 17.2654C16.3363 17.2654 16.1607 17.193 16.0158 17.0481L10.2619 11.2943C9.76195 11.7071 9.18695 12.0302 8.53695 12.2635C7.88695 12.4968 7.21451 12.6135 6.51965 12.6135ZM6.51965 11.1136C7.80811 11.1136 8.89946 10.6664 9.7937 9.77221C10.6879 8.87798 11.135 7.78663 11.135 6.49816C11.135 5.2097 10.6879 4.11835 9.7937 3.22411C8.89946 2.32988 7.80811 1.88276 6.51965 1.88276C5.23118 1.88276 4.13983 2.32988 3.2456 3.22411C2.35138 4.11835 1.90427 5.2097 1.90427 6.49816C1.90427 7.78663 2.35138 8.87798 3.2456 9.77221C4.13983 10.6664 5.23118 11.1136 6.51965 11.1136Z" fill="#D7D7D7" />
                </svg>
            </div>
            <input value={value} placeholder={placeholder} onChange={(event)=> onChange(event.target.value)} />
        </StyledSearchField>
    )
}

const StyledSearchField = styled("div")({
    "&.Groups_SearchField_StyledSearchField": {
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        borderRadius: "6.25rem",
        padding: "0rem 1rem",
        height: "3rem",
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
        flexShrink: 0,
        "& input": {
            outline: "none",
            border: "none",
            background: "transparent",
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "rgba(215, 215, 215, 1)",
            width: "100%",
            "&::placeholder":{
                color: "rgba(215, 215, 215, 1)"
            }
        },
        "& .icon": {
            display: "flex",
            alignItems: "center",
        }
    }
})

export default SearchField;