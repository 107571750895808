// Customizable Area Start
import React, { useState } from "react";
import { Box, Grid, Menu, Tooltip, Typography, styled } from "@material-ui/core";
import { IBucketListGoal } from "../../../../components/src/CommonTypes";
import { invalidEvtImg, MoreIcon } from "../../../events/src/assets";
import { bucketEmptyIcon } from "../assets";
import { markAsCompleteIconWhite } from "../assets";
const { HelperFunctions: helper } = require("../../../../components/src/HelperFunctions");

interface IBucketList {
    goalData: IBucketListGoal,
    onDeleteClick?: (title: string, goalId: string) => void;
    onMarkAsComplete?: (goalId: string) => void;
    redirectTo?: (endpoint: string, params?: { [key: string]: string | number }) => void;
}

const BucketListCard: React.FC<IBucketList> = (props: IBucketList) => {
    const { goalData, onDeleteClick, onMarkAsComplete, redirectTo } = props;
    const [optionGoalDropdownMenuAnchorEle, setOptionGoalDropdownMenuAnchorEle] = useState<HTMLElement | null>(null);

    const openGoalOptionMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        setOptionGoalDropdownMenuAnchorEle(event.currentTarget)
    }

    const closeGoalOptionMenu = (event: MouseEvent) => {
        setOptionGoalDropdownMenuAnchorEle(null)
    }

    const GoalOptionDropdown = () => {
        return (
            <CustomMenuEvent
                data-test-id="custom-menu-goal"
                className="Custom_Menu_Goal"
                open={Boolean(optionGoalDropdownMenuAnchorEle)}
                onClose={(evt: MouseEvent) => closeGoalOptionMenu(evt)}
                anchorEl={optionGoalDropdownMenuAnchorEle}
                PaperProps={{
                    style: {
                        transform: "translateY(0.5rem)"
                    }
                }}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                getContentAnchorEl={null}
            >
                <StyledGoalOptionDropdown id="goal-options-menu" className="GoalOptionDropdown_Web">
                    <Box id="edit-goal-icon" className={`option-item ${goalData.attributes.status === "completed" ? "option-item-disabled cursor-none" : ""}`} onClick={() => {
                        if (redirectTo && goalData.attributes.status !== "completed") {
                            redirectTo("EditGoal", { id: goalData.id })
                        }
                    }}>
                        <svg id="edit-goal-icon-svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.99997 16H3.2615L13.4981 5.7634L12.2366 4.50188L1.99997 14.7385V16ZM0.5 17.5V14.1154L13.6904 0.930775C13.8416 0.793426 14.0086 0.687292 14.1913 0.612375C14.374 0.537458 14.5656 0.5 14.7661 0.5C14.9666 0.5 15.1608 0.535584 15.3488 0.60675C15.5368 0.6779 15.7032 0.791034 15.848 0.94615L17.0692 2.18268C17.2243 2.32754 17.3349 2.49424 17.4009 2.68278C17.4669 2.87129 17.5 3.05981 17.5 3.24833C17.5 3.44941 17.4656 3.64131 17.3969 3.82403C17.3283 4.00676 17.219 4.17373 17.0692 4.32495L3.88458 17.5H0.5ZM12.8563 5.1437L12.2366 4.50188L13.4981 5.7634L12.8563 5.1437Z" fill="white" />
                        </svg>
                        Edit
                    </Box>
                    <Box id="delete-goal-icon" className={`option-item ${goalData.attributes.status === "completed" ? "option-item-disabled cursor-none" : ""}`}
                        onClick={() => {
                            if (goalData.attributes.status !== "completed" && onDeleteClick) {
                                onDeleteClick(goalData.attributes.title, goalData.id);
                                setOptionGoalDropdownMenuAnchorEle(null);
                            }
                        }}
                    >
                        <svg id="delete-goal-icon-svg" width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.3077 17.5017C2.80898 17.5017 2.38302 17.3251 2.02982 16.9719C1.67661 16.6187 1.5 16.1928 1.5 15.694V3.00176H0.5V1.50179H4.99997V0.617188H11V1.50179H15.5V3.00176H14.5V15.694C14.5 16.1992 14.325 16.6267 13.975 16.9767C13.625 17.3267 13.1974 17.5017 12.6922 17.5017H3.3077ZM13 3.00176H2.99997V15.694C2.99997 15.7838 3.02883 15.8575 3.08652 15.9152C3.14422 15.9729 3.21795 16.0018 3.3077 16.0018H12.6922C12.7692 16.0018 12.8397 15.9697 12.9038 15.9056C12.9679 15.8415 13 15.771 13 15.694V3.00176ZM5.40385 14.0018H6.90382V5.00176H5.40385V14.0018ZM9.09613 14.0018H10.5961V5.00176H9.09613V14.0018Z" fill="white" />
                        </svg>
                        Delete
                    </Box>
                    <Box id="mark-complete-goal-icon" className={`option-item ${goalData.attributes.status === "completed" ? "option-item-disabled cursor-none" : ""}`}
                        onClick={() => {
                            if (onMarkAsComplete && goalData.attributes.status !== "completed") {
                                onMarkAsComplete(goalData.id);
                                setOptionGoalDropdownMenuAnchorEle(null);
                            }
                        }}
                    >
                        <img src={markAsCompleteIconWhite} alt="Mark complete" />
                        Mark As Completed
                    </Box>
                </StyledGoalOptionDropdown>
            </CustomMenuEvent>
        )
    }

    return (
        <StyledBucketListCard style={{ opacity: goalData.attributes.status === "completed" ? 0.5 : 1 }} className="Goals_BucketListCard_Web">
            <Grid container direction="row">
                <Grid className="top-title" item xs={12}>
                    <Box>
                        <Tooltip title={goalData.attributes.title} placement="top">
                            <Typography component={"span"}>{goalData.attributes.title}</Typography>
                        </Tooltip>
                    </Box>
                    <img id="open-goal-options" className="cursor-pointer" src={MoreIcon} onClick={openGoalOptionMenu} alt="more-option" />
                    {GoalOptionDropdown()}
                </Grid>
                <Grid item xs={12} className="title_divider"></Grid>
                <Grid item xs={12}>
                    <Grid className="image_and_title_container">
                        <Grid item>
                            {goalData.attributes.image_url ? <img className="bucket-list-goal-img" data-test-id="bucket-list-goal-img" src={goalData.attributes.image_url} alt="" /> :
                                <img className="bucket-list-goal-img" src={bucketEmptyIcon} alt="" />}
                        </Grid>
                        <Grid item>
                            <Box display={"flex"} flexDirection={"column"} gridGap={"1px"}>
                                <Typography className="info-header" component={"span"}>Location</Typography>
                                <Typography className="info-detail" component={"span"}>{goalData.attributes.location.city}</Typography>
                            </Box>
                            <Box marginTop={"10px"} display={"flex"} flexDirection={"column"} gridGap={"1px"}>
                                <Typography className="info-header" component={"span"}>Target Date</Typography>
                                <Typography className="info-detail" component={"span"}>{helper.formatDate(goalData.attributes.target_date, "DD MMMM YYYY")}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="title_divider"></Grid>
                <Grid item xs={12}>
                    <Box className="info-desc">
                        <Typography className="info-header" component={"span"}>{goalData.attributes.description}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </StyledBucketListCard>
    )
}

const StyledBucketListCard = styled("div")({
    "&.Goals_BucketListCard_Web": {
        width: "100%",
        height: "280px",
        borderRadius: "1rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid #19B5B945",
        boxShadow: "0px 0px 16px 0px #0000001F",
        padding: "12px",
        boxSizing: "border-box",
        position: "relative",
        overflow: "hidden",
        "& .top-title": {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& div": {
                color: "#FFFFFF",
                fontWeight: 500,
                fontSize: "16px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "block",
                maxWidth: "250px"
            },
        },
        "& .info-header": {
            fontSize: "12px",
            color: "rgba(255, 255, 255, 0.8)"
        },
        "& .info-detail": {
            fontSize: "16px",
            color: "rgba(255, 255, 255, 1)"
        },
        "& .info-desc": {
            display: "-webkit-box",
            WebkitLineClamp: 4,
            WebkitBoxOrient: "vertical",  
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "rgba(255, 255, 255, 0.8)"
        },
        "& .bucket-list-goal-img": {
            width: "104px",
            height: "105px",
            borderRadius: "1rem",
        },
        "& p": {
            marginBottom: "0.5rem"
        },
        "& .title_divider": {
            height: "1px",
            backgroundColor: "#FFFFFF73",
            opacity: 0.1,
            margin: "13px 0px"
        },
        "& .image_and_title_container": {
            display: "flex",
            gap: "1.5rem",
        }

    }
});

const StyledGoalOptionDropdown = styled("div")({
    "&.GoalOptionDropdown_Web": {
        display: "flex",
        flexDirection: "column",
        width: "13.8125rem",
        border: "1px solid #19B5B945",
        borderRadius: "0.5rem",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        padding: "0.25rem 0rem",
        "& .option-item": {
            display: "flex",
            alignItems: "center",
            color: "#ffffff",
            fontSize: "1rem",
            fontWeight: 400,
            gap: "0.5rem",
            cursor: "pointer",
            padding: "0.25rem 0.7rem",
            backgroundColor: "transparent",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
                backgroundColor: "#0000001A"
            }
        },
        "& .option-item-disabled": {
            opacity: 0.4,
        }
    }
});

const CustomMenuEvent = styled(Menu)({
    "&.Custom_Menu_Goal": {
        "& .MuiList-padding": {
            padding: 0,
        },
        "& .MuiPaper-root": {
            background: "transparent"
        }
    }
});

export default BucketListCard;
// Customizable Area End