// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IComment, IMentionInputType, IMetaPagination, IReply } from "../../../components/src/CommonTypes";
import _ from "lodash";
const {
  HelperFunctions: helper,
} = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  commentsList?: IComment[];
  isCommentsLoading?: boolean;
  userId: number | undefined;
  handleFetchNextPageComments?: () => void;
  onDeleteComment?: (commentId: string | number) => void;
  handleUpdateRepliesCount: (commentId: number | string, action: "increase" | "decrease") => void;
  handleReplySectionShow: (commentId: number | string) => void;
  handleReplySectionHide: (commentId: number | string) => void;
  commentsMetaData?: IMetaPagination | null;
  mentionsList?: IMentionInputType[];
  handleLikeDislikeComment?: (commentId: number | string) => void;
  fetchLikedUsersList?: (commentId: number | string) => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string | null;
  optionMenuAnchor: HTMLElement | null;
  selectedCommentItem: IComment | null;
  replyPageNumber: number;
  repliesList: IReply[];
  isLoadingReplies: boolean;
  deletedReplyId: number | string;
  replyMetaData: IMetaPagination | null;
  currentReplyCommentedId: number | string;
  loggedInUser: {
    image_url: string | null,
    full_name: string,
    id: number,
  } | null;
  currentLikedReplyId: number | string;
  replyLikedUserList: { id: string | number, attributes: { like_by_name: string} }[] | undefined;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommentsListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRepliesListApiCallId: string = "";
  deleteReplyApiCallId: string = "";
  addReplyApiCallId: string = "";
  likeDislikeReplyApiCallId: string = "";
  replyLikedUsersListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      optionMenuAnchor: null,
      selectedCommentItem: null,
      repliesList: [],
      replyPageNumber: 1,
      replyMetaData: null,
      deletedReplyId: "",
      isLoadingReplies: false,
      currentReplyCommentedId: "",
      loggedInUser: null,
      currentLikedReplyId: "",
      replyLikedUserList: undefined
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getTokenOfUser();
    // Customizable Area Start
    const userData = await helper.getUserData();
    if(userData) {
      this.setState({ loggedInUser: userData });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getTokenOfUser = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };


  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (!token) {
        token = await getStorageData("authToken");
      }
      this.setUserToken(token);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const commentsApiCallIds = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      switch (commentsApiCallIds) {
        case this.getRepliesListApiCallId:
          this.handleGetCommentRepliesListApiCall(message);
          break;
        case this.addReplyApiCallId:
          this.handleAddCommentReplyApiCall(message);
          break;
        case this.deleteReplyApiCallId:
          this.handleDeletePostGroupCommentApiCall(message);
          break;
        case this.replyLikedUsersListApiCallId:
          this.handleGetReplyLikedUsersListCall(message);
          break;
        case this.likeDislikeReplyApiCallId:
          this.likeReplyApiCallHandler(message);
          break;
      }
    }
    // Customizable Area End
  };

  setUserToken = async (token: string | null) => {
    this.setState({ token });
  };

  closeCommentListOptionMenu = () => {
    this.setState({ optionMenuAnchor: null, selectedCommentItem: null })
  }

  openCommentListOptionMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ optionMenuAnchor: event.currentTarget })
  }

  handleHideRepliesSection = (postId: number |  string) => {
    this.setState({ currentReplyCommentedId: "", repliesList: [], replyPageNumber: 1, replyMetaData: null })
    this.props.handleReplySectionHide(postId)
  }

  handleOpenRepliesSection = async(commentId: number |  string) => {
    this.setState({ currentReplyCommentedId: commentId, repliesList: [], replyPageNumber: 1, replyMetaData: null }, async() => {
      await this.getCommentReplies(commentId)
      this.focusOnReplyField();
    })
  }

  focusOnReplyField = () => {
    const { currentReplyCommentedId } = this.state;
    const replyField = document.getElementById(`comment_reply_text_field_${currentReplyCommentedId}`);
    if (replyField) {
      replyField.scrollIntoView({
        behavior: 'smooth', block: 'nearest', inline: 'center'
      })
      replyField.focus();
    }
  }

  scrollToTopOnReplies = () => {
    const topDiv = document.getElementById("group-post-comments-reply-top-div");
    if (topDiv) {
      topDiv.scrollIntoView({
        behavior: 'smooth', block: 'nearest', inline: 'start'
      })
    }
  }

  handleFetchNextRepliesForCurrentComment = (commentId: number | string) => {
    this.setState({ replyPageNumber: this.state.replyPageNumber + 1}, () => this.getCommentReplies(commentId))
  }

  onLikeReply = async (replyId: string | number) => {
    const body = {
      likeable_id: replyId,
      like_type: "comment_reply",
    };
    this.likeDislikeReplyApiCallId = await helper.apiCall({
      method: configJSON.commentPOSTAPiMethod,
      contentType: configJSON.exampleApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.likePostEndPoint}`,
      body,
    });
    this.setState({
      currentLikedReplyId: replyId,
    });
  };

  likeReplyApiCallHandler = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson.message || responseJson.data)) {
      const { currentLikedReplyId, repliesList } = this.state;
      const updatedRepliesList = repliesList.map((reply) => {
        if (reply.id === currentLikedReplyId) {
          if (reply.attributes.liked_by_me) {
            reply.attributes.likes_count -= 1;
          } else {
            reply.attributes.likes_count += 1;
          }
          reply.attributes.liked_by_me = !reply.attributes.liked_by_me;
        }
        return reply;
      });
      this.setState({
        repliesList: updatedRepliesList,
      });
    } else {
      const likeCommentErrMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(likeCommentErrMsg);
    }
    helper.hideLoader();
  };

  handleGetReplyLikedUsersListCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.data) {
      this.setState({
        replyLikedUserList: [...responseJson.data.data],
      });
    } else {
      this.setState({ replyLikedUserList: [] });
    }
    helper.hideLoader();
  };

  getReplyLikedUsersList = async(replyId: number | string) => {
    helper.showLoader();
    this.replyLikedUsersListApiCallId = await helper.apiCall({
      method: configJSON.commentsApiMethodType,
      contentType: configJSON.exampleApiContentType,
      token: this.state.token,
      endPoint: `${configJSON.repliesLikedListUsersApiEndpoint}?comment_reply_id=${replyId}`,
    });
  }

  setReplyLikedUserList = () => {
    this.setState({
      replyLikedUserList: undefined,
    });
  };

  getCommentReplies = async(commentId: number | string) => {
    this.props.handleReplySectionShow(commentId)
    this.setState({
      isLoadingReplies: true,
    }, async() => {
      this.getRepliesListApiCallId = await helper.apiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.commentsApiMethodType,
        token: this.state.token,
        endPoint: `${configJSON.replyEndpoint}?comment_id=${commentId}&order=desc&page_no=${this.state.replyPageNumber}&per_page=15`,
      });
    });
  }

  handleGetCommentRepliesListApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.meta) {
      this.setState({ replyMetaData: responseJson.meta })
    }
    if(responseJson?.data?.length){
      this.setState({
        repliesList: _.uniqBy([...this.state.repliesList, ...responseJson.data], "id")
      })
    }
    this.setState({
      isLoadingReplies: false
    })
  };

  addGroupCommentReply = async(commentId: number | string, replyText: string) => {
    this.setState({
      isLoadingReplies: true,
      currentReplyCommentedId: commentId
    }, async() => {
      const formData = new FormData();
      formData.append("reply[descripation]", replyText);
      formData.append("reply[comment_id]", commentId.toString());
      this.addReplyApiCallId = await helper.apiCall({
        method: configJSON.commentPOSTAPiMethod,
        token: this.state.token,
        endPoint: `${configJSON.replyEndpoint}`,
        body: formData,
        type: "formData"
      });
    })
  }

  handleAddCommentReplyApiCall = (message: Message)=>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson?.data){
      const { repliesList, currentReplyCommentedId } = this.state;
      let updatedRepliesList = [ { ...responseJson.data}, ...repliesList];
      this.setState({
        repliesList: _.uniqBy(updatedRepliesList, "id"),
      })
      this.props.handleUpdateRepliesCount(currentReplyCommentedId, "increase")
      this.scrollToTopOnReplies();
    } else {
      const errorAddReplyToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorAddReplyToastMsg);
    }
    this.setState({
      isLoadingReplies: false
    })
  }

  onDeleteCommentReply = async (replyId: string | number, commentId: string | number) => {
    this.setState({
      isLoadingReplies: true,
      currentReplyCommentedId: commentId,
      deletedReplyId: replyId,
    });
    const { token } = this.state;
    this.deleteReplyApiCallId = await helper.apiCall({
      method: configJSON.commentDELETEAPiMethod,
      endPoint: `${configJSON.replyEndpoint}/${replyId}`,
      contentType: configJSON.exampleApiContentType,
      token: token,
    });
  };

  handleDeletePostGroupCommentApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.message) {
      const { deletedReplyId, repliesList , currentReplyCommentedId} = this.state;
      const updatedRepliesList = repliesList.filter((item) => item.id !== deletedReplyId)
      this.setState({
        repliesList: _.uniqBy(updatedRepliesList, "id"),
      });
      this.props.handleUpdateRepliesCount(currentReplyCommentedId, "decrease")
    } else {
      const errorDelReplyToastMsg = responseJson?.errors?.[0]?.message
        ? responseJson.errors[0].message
        : "Something went wrong, please try again!";
      helper.showErrorToast(errorDelReplyToastMsg);
    }
    this.setState({
      isLoadingReplies: false,
      deletedReplyId: "",
      currentReplyCommentedId: "",
    });
  };

  // Customizable Area End
}
