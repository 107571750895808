// Customizable Area Start
import React, { useState } from "react";
import { styled, Box } from "@material-ui/core";
import { PlusIcon } from "../../../appointmentmanagement/src/assets";
import SearchField from "../../../groups/src/components/SearchField.web";

interface BucketListHeaderProps {
    redirectTo: (destination: string, params?: { [key: string]: string | number }) => void,
    onChangeTab?: (tabValue: "all" | "todo" | "completed") => void;
    onSearch?: (search: string) => void;
    allItemsCount: number;
    todoItemsCount: number;
    completedItemsCount: number;
    searchText: string;
}

const BucketListHeader: React.FC<BucketListHeaderProps> = (props: BucketListHeaderProps) => {
    const {
        redirectTo,
        onChangeTab,
        onSearch,
        allItemsCount = 0,
        completedItemsCount = 0,
        todoItemsCount = 0,
        searchText
    } = props;

    const [tabValue, setTabValue] = useState<"all" | "todo" | "completed">("all");

    const handleChangeTab = (value: "all" | "todo" | "completed") => {
        setTabValue(value);
        onChangeTab?.(value)
    }

    const handleSearch = (text: string) => {
        const sanitizedValue = text.replace(/^\s+/, '');
        onSearch?.(sanitizedValue);
    }

    const HeaderTabs = () => {
        return (
            <StyledHeaderTabs className="BucketList_StyledHeaderTabs">
                <Box className={`tab ${tabValue === "all" && "active"}`} onClick={() => handleChangeTab("all")}>{`All (${allItemsCount < 10 && "0"}${allItemsCount})`}</Box>
                <Box className={`tab ${tabValue === "todo" && "active"}`} onClick={() => handleChangeTab("todo")}>{`To Do (${todoItemsCount < 10 && "0"}${todoItemsCount})`}</Box>
                <Box className={`tab ${tabValue === "completed" && "active"}`} onClick={() => handleChangeTab("completed")}>{`Completed (${completedItemsCount < 10 && "0"}${completedItemsCount})`}</Box>
            </StyledHeaderTabs>
        )
    }

    return (
        <StyledBucketListHeader className="StyledBucketListHeader_Web">
            <p>Bucket List</p>
            <Box className="actions">
                {HeaderTabs()}
                <Box width={"auto"}>
                    <SearchField placeholder="Search" onChange={handleSearch} value={searchText} />
                </Box>
                <button className="add-btn" onClick={() => redirectTo("AddGoal")}>
                    <img src={PlusIcon} alt="" />
                    <p>Add Goal</p>
                </button>
            </Box>
        </StyledBucketListHeader>
    )
}

const StyledBucketListHeader = styled("div")({
    "&.StyledBucketListHeader_Web": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "8px",
        "& p": {
            fontSize: "1.25rem",
            fontWeight: 500,
            lineHeight: "1.875rem",
            color: "rgba(255, 255, 255, 1)"
        },
        "& .actions": {
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            "& button": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "3rem",
                padding: "0.75rem",
                gap: "0.25rem",
                borderRadius: "62.5rem",
                background: "transparent",
                outline: "none",
                border: "none",
                cursor: "pointer",
                minWidth: "9.125rem",
                "& p": {
                    fontSize: "1rem",
                    fontWeight: 500
                },
                "&.outlined": {
                    border: "1px solid #F75367",
                    color: "#F75367",
                    backgroundColor: "transparent",
                    "& p": {
                        color: "#F75367",
                        transform: "translateY(1px)",
                    },
                    "& > .dot": {
                        width: "0.4rem",
                        height: "0.4rem",
                        background: "#F75367",
                        position: "absolute",
                        right: 8,
                        top: 12,
                        borderRadius: "100%"
                    }
                },
                "&.calendar-btn": {
                    "& p": {
                        transform: "translateY(1px)",
                    }
                },
                "&.add-btn": {
                    background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                    "& p": {
                        color: "rgba(255, 255, 255, 1)",
                    }
                }
            },
            [`@media screen and (max-width: 600px)`]: {
                flexDirection: "column",
            },
        },
        [`@media screen and (max-width: 600px)`]: {
            flexDirection: "column",
            gap: "1.5rem"
        },
    }
});

const StyledHeaderTabs = styled("div")({
    "&.BucketList_StyledHeaderTabs": {
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        borderRadius: "3.125rem",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        padding: "18px 8px",
        height: "2.25rem",
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        boxSizing: "border-box",
        "& > .tab": {
            color: "rgba(255, 255, 255, 0.5)",
            fontSize: "0.875rem",
            fontWeight: 400,
            padding: "0rem 0.75rem",
            boxSizing: "border-box",
            display: "flex",
            height: "1.75rem",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "center",
            borderRadius: "3.125rem",
            "&.active": {
                fontSize: "0.875rem",
                color: "rgba(255, 255, 255, 1)",
                fontWeight: 500,
                background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
                [`@media screen and (max-width: 1024px)`]: {
                    fontSize: "10px",
                },
                [`@media screen and (max-width: 600px)`]: {
                    fontSize: "14px !important",
                },
            },
            [`@media screen and (max-width: 1024px)`]: {
                height: "2.75rem",
                fontSize: "10px",
            },
            [`@media screen and (max-width: 600px)`]: {
                fontSize: "14px !important",
            },
        },
        [`@media screen and (max-width: 1024px)`]: {
            height: "3.25rem",
            fontSize: "10px",
        },
        [`@media screen and (max-width: 600px)`]: {
            fontSize: "14px !important",
        },
    }
});

export default BucketListHeader;
// Customizable Area End