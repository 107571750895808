import React from "react";
import { styled } from "@material-ui/styles";
import { Box, CircularProgress } from "@material-ui/core";
import { IGroup } from "../types";
import { Button } from "../../../../components/src";
import GroupImage from "./GroupImage.web";
import { trendingGroupIcon } from "../assets";
interface Props {
    trendingGroups: IGroup[];
    goBackToSharedGroups: () => void;
    onSelectGroup: (group: IGroup) => void;
    selectedTrendingGroup: IGroup | null;
    isTrendingGroupsLoading?: boolean;
}

const TrendingGroups: React.FC<Props> = (props: Props) => {
    const { trendingGroups, goBackToSharedGroups, selectedTrendingGroup, onSelectGroup, isTrendingGroupsLoading } = props;

    const NoTrendingGroupMessage = () => {
        return (
            <StyledNoTrendingGroupMessage className="TrendingGroups_StyledNoTrendingGroupMessage">
                <p>No Trending Groups</p>
            </StyledNoTrendingGroupMessage>
        )
    }
    return (
        <Box id="trending-groups-box" height={"350px"} position={"relative"}>
            {
                trendingGroups && trendingGroups.length > 0 && (
                    <StyledTrendingGroups className="TrendingGroups_StyledTrendingGroups">
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <p id="trending-group">Trending Groups</p>
                            <Button text="Shared Groups" buttonStyles={{ fontSize: "12px" }} buttonId="go_to_shared_groups" type="link" onClick={goBackToSharedGroups} />
                        </Box>
                        <Box className="trendingGroups">
                            {
                                trendingGroups.map(trendingGroup => {
                                    return (
                                        <Box className={`trendingGroup ${!!selectedTrendingGroup && selectedTrendingGroup.id === trendingGroup.id ? "selected" : ""}`} key={trendingGroup.id} onClick={() => onSelectGroup(trendingGroup)}>
                                            <Box display={"flex"} gridGap={"10px"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                                                <GroupImage image_url={trendingGroup.image_url} />
                                                <Box className="details">
                                                    <p className="name">{trendingGroup.name}</p>
                                                    <p className="description ellipsis">{trendingGroup.description}</p>
                                                </Box>
                                            </Box>
                                            <Box width={"99%"} display={"flex"} justifyContent={"flex-end"}>
                                                <img src={trendingGroupIcon} alt="Trending Group" />
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    </StyledTrendingGroups>
                )
            }
             {isTrendingGroupsLoading && <Box className="common-circular-progress-loader">
                <CircularProgress />
            </Box>}
            {
                (!isTrendingGroupsLoading && trendingGroups.length === 0) && (
                    NoTrendingGroupMessage()
                )
            }
        </Box>
    )
}

const StyledTrendingGroups = styled("div")({
    "&&.TrendingGroups_StyledTrendingGroups": {
        flexDirection: "column",
        display: "flex",
        gap: "1rem",
        "& #trending-group": {
            fontSize: "0.75rem",
            color: "rgba(255, 255, 255, 1)",
            fontWeight: 500,
            marginLeft: "1rem"
        },
        "& .trendingGroups": {
            display: "flex",
            flexDirection: "column",
            maxHeight: "350px",
            overflowY: "scroll",
            justifyContent: "space-between",
            "& img": {
                width :"24px",
                height: "24px",
                objectFit: "contain",
            },            
            "& .trendingGroup": {
                display: "flex",
                padding: "10px",
                gap: "1rem",
                transition: "background-color 0.3s ease-in-out",
                cursor: "pointer",
                "&.selected": {
                    backgroundColor: "rgba(0, 0, 0, 0.15)",
                    borderLeft: "3px solid rgba(255, 103, 118, 1)"
                },
                "& .details": {
                    display: "flex",
                    flexDirection: "column",
                    "& .name": {
                        fontWeight: 500,
                        fontSize: "1rem",
                        color: "rgba(255, 255, 255, 1)"
                    },
                    "& .description": {
                        width: "200px",
                        fontSize: "0.625rem",
                        fontWeight: 400,
                        color: "rgba(255, 255, 255, 0.7)"
                    }
                },
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.15)"
                }
            }
        }
    }
})

const StyledNoTrendingGroupMessage = styled("div")({
    "&.TrendingGroups_StyledNoTrendingGroupMessage": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        flex: 1,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        gap: "0.5rem",
        "& > p": {
            fontSize: "1rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 0.8)"
        }
    }
})

export default TrendingGroups;