import React from "react";

// Customizable Area Start
import { Button, Typography, styled } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { edit_icon, otpLogo, poster_1, poster_2, poster_3 } from "./assets";
import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import "./OTPInputAuth.css";
import { Timer } from "../../../components/src";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderError = () => {
    if (
      this.state.apiResponseError ===
      "You have entered wrong code. Please try again"
    ) {
      return;
    }
    return (
      <Typography data-test-id="timer" style={{...webStyle.timer, display: this.state.resendOtpClickCount === 0 ? "none" : "block"}}>
        {this.getFormattedTime()}
      </Typography>
    );
  };

  renderOTPInputs = () => {
    return (
      <form autoComplete="off" className="otpContainer">
        {this.state.otp.map((digit, index) => (
          <input
            key={index}
            className="otpInput"
            type="text"
            id={`otp-text-field${index + 1}`}
            maxLength={1}
            value={digit}
            onChange={(event) => this.handleOTPInputChange(event, index)}
            onKeyDown={(event) => this.handleKeyDown(event, index)}
            onPaste={this.handlePasteOtp}
            ref={this.otpInputs[index]}
          />
        ))}
      </form>
    );
  };

  renderErrorMessageForTimer = () => {
    return (
      <StyledErrorMessage id="contact-us-error">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.99965 11.9419C8.19036 11.9419 8.35022 11.8774 8.47922 11.7484C8.60822 11.6194 8.67272 11.4596 8.67272 11.2689C8.67272 11.0782 8.60822 10.9183 8.47922 10.7893C8.35022 10.6603 8.19036 10.5958 7.99965 10.5958C7.80895 10.5958 7.64909 10.6603 7.52009 10.7893C7.39109 10.9183 7.32659 11.0782 7.32659 11.2689C7.32659 11.4596 7.39109 11.6194 7.52009 11.7484C7.64909 11.8774 7.80895 11.9419 7.99965 11.9419ZM7.37468 8.89709H8.62463V3.89709H7.37468V8.89709ZM8.00105 15.9163C6.9061 15.9163 5.87691 15.7085 4.91347 15.293C3.95001 14.8774 3.11194 14.3135 2.39926 13.6011C1.68656 12.8887 1.12235 12.051 0.706612 11.088C0.290876 10.125 0.0830078 9.09599 0.0830078 8.00105C0.0830078 6.90611 0.290786 5.87691 0.706341 4.91347C1.1219 3.95001 1.68586 3.11194 2.39822 2.39926C3.11059 1.68656 3.94829 1.12235 4.91132 0.706613C5.87433 0.290877 6.90331 0.0830078 7.99826 0.0830078C9.0932 0.0830078 10.1224 0.290787 11.0858 0.706342C12.0493 1.1219 12.8874 1.68586 13.6 2.39822C14.3127 3.11059 14.877 3.94829 15.2927 4.91132C15.7084 5.87434 15.9163 6.90331 15.9163 7.99826C15.9163 9.0932 15.7085 10.1224 15.293 11.0858C14.8774 12.0493 14.3135 12.8874 13.6011 13.6001C12.8887 14.3127 12.051 14.877 11.088 15.2927C10.125 15.7084 9.09599 15.9163 8.00105 15.9163ZM7.99965 14.6663C9.86076 14.6663 11.4372 14.0205 12.7288 12.7288C14.0205 11.4372 14.6663 9.86077 14.6663 7.99965C14.6663 6.13854 14.0205 4.56215 12.7288 3.27049C11.4372 1.97882 9.86076 1.33299 7.99965 1.33299C6.13854 1.33299 4.56215 1.97882 3.27049 3.27049C1.97882 4.56215 1.33299 6.13854 1.33299 7.99965C1.33299 9.86077 1.97882 11.4372 3.27049 12.7288C4.56215 14.0205 6.13854 14.6663 7.99965 14.6663Z" fill="white" />
        </svg>
        Oops! Having trouble generating OTP!
        <span>Contact Us</span>
      </StyledErrorMessage>
    )
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={webStyle.mainDiv}>
          <div style={webStyle.leftDiv} className="LeftBox">
            <div style={webStyle.titleDiv}>
              <Typography style={webStyle.title}>
                "Seize the moment,
                <br /> Join{" "}
                <span style={{ color: "#F37D77" }}>Time is Everything</span>"
              </Typography>
              <Typography style={webStyle.titleDescrip}>
                {" "}
                Our platform brings together the moments that matter most, from
                unforgettable events to essential reminders. Join us and make
                every moment count.
              </Typography>
            </div>
            <div style={{ marginTop: "100px" }}>
              <div style={webStyle.leftImgDiv}>
                <img src={poster_2} alt="Poster 1" />
                <img src={poster_3} alt="Poster 3" />
                <div style={webStyle.topImgDiv}>
                  <img
                    style={{ alignSelf: "center" }}
                    src={poster_1}
                    alt="Poster 2"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="rightBox" style={webStyle.rightDiv}>
            <div style={{ maxWidth: "424px" }}>
              <div style={webStyle.logoDiv}>
                <img alt="" src={otpLogo} style={webStyle.logoImg} />
              </div>
            </div>

            <div style={webStyle.otpDiv}>
              <Typography style={webStyle.otpVeri}>OTP Verification</Typography>
              <div style={webStyle.numbDiv}>
                <label style={webStyle.enterOtp}>Enter the OTP sent to</label>

                <label data-test-id="mobile-no" style={webStyle.mobNumb}>
                  {
                    this.state.loginPayloadData && (
                      `${this.state.loginPayloadData?.countryCode+this.state.loginPayloadData?.phoneNumber}`
                    )
                  }
                </label>

                <div style={webStyle.editIconDiv}>
                  <img
                    data-test-id="mobileNoEditIcon"
                    alt="edit"
                    style={{ cursor: "pointer" }}
                    src={edit_icon}
                    onClick={this.handleNavigateMobileRegister}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {this.renderOTPInputs()}
              {this.state.apiResponseError && !this.state.isFiveMinuteTimerActive && (
                <div
                  style={{
                    color: "#FF2F3F",
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    marginBottom: "0.75rem",
                  }}
                >
                  {this.state.apiResponseError}
                </div>
              )}
              {!this.state.isFiveMinuteTimerActive && this.renderError()}
              {this.state.isFiveMinuteTimerActive && (
                <Timer
                  style={{
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    color: "#ff2f3f",
                    display: "inline-block"
                  }}
                  isTimerOn={this.state.isFiveMinuteTimerActive}
                  onTimerEnd={this.onCloseFiveMinuteTimer}
                  lastAttemptAt={this.state.lastAttempt}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "0.75rem",
                  marginBottom: "1.62rem",
                }}
              >
                {this.state.apiResponseError !==
                  "You have entered wrong code. Please try again" && (
                  <label style={webStyle.didnotRecieve}>
                    {" "}
                    Didn't receive OTP?{" "}
                  </label>
                )}
                <Typography
                  style={{
                    ...webStyle.sendOtp,
                    cursor: this.state.isTimerActive || this.state.resendOTPApiLoading || this.state.isFiveMinuteTimerActive ? "default" : "pointer",
                    opacity: this.state.isTimerActive || this.state.resendOTPApiLoading || this.state.isFiveMinuteTimerActive ? 0.5 : 1,
                  }}
                  data-test-id="resendButton"
                  onClick={this.handleSendOtp}
                >
                  Resend OTP
                </Typography>
              </div>
              <div style={webStyle.submitButtonDiv}>
                <Button
                  data-test-id="submitButton"
                  className="submitButton"
                  disabled={this.state.otp.join("").length !== 6 || this.state.isFiveMinuteTimerActive}
                  onClick={() => this.submitOtp()}
                >
                  Submit
                </Button>
              </div>
            </div>
            {
              this.state.apiResponseError && this.state.apiResponseError.includes(this.fiveMinuteErrorMessage) && this.renderErrorMessageForTimer()
            }
          </div>
        </div>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  errorF: {
    color: "#FFFFFF",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamiliy: "Poppins",
  },
  errorss: {
    color: "#FF2F3F",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamiliy: "Poppins",
  },
  mainDiv: {
    width: "100%",
    height: "100%",
    background: "#0F2631",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row" as "row",
    padding:"1.25rem",
    boxSizing:"border-box" as "border-box"
  },
  leftDiv: {
    background: "linear-gradient(#23404B, #19282F)",
    width: "50%",
    borderRadius: "1.25rem",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-around",
    alignItems: "center" as "center",
    backgroundColor: "linear-gradient( #1C969A , #19B5B9 )",
    borderImageSlice: 1,
  },
  titleDiv: {
    maxWidth: "587px",
    textAlign: "center" as "center",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "60px",
    color: "#FFFFFF",
  },
  titleDescrip: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FFFFFF",
  },
  leftImgDiv: {
    display: "flex",
    gap: "80px",
    maxWidth: "630px",
    position: "relative" as "relative",
  },
  topImgDiv: {
    display: "flex",
    position: "absolute" as "absolute",
    top: "-7rem",
    left: "10rem",
  },
  rightDiv: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center" as "center",
    alignItems: "center" as "center",
  },
  logoDiv: {
    marginBottom: "1.5rem",
  },
  logoImg: {
    width: "6.5rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover" as "cover",
  },
  otpDiv: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
  },
  otpVeri: {
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: "normal",
    marginBottom: "0.75rem",
    color: "#FFFFFF",
  },
  numbDiv: {
    display: "flex",
    flexDirection: "row" as "row",
    color: "#FFFFFF",
    gap: "2px",
    alignItems: "center",
  },
  enterOtp: {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "poppins",
    lineHeight: "21px",
    color: "rgba(255, 255, 255, 0.8)",
  },
  mobNumb: {
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#FFFFFF",
  },
  editIconDiv: {
    width: "16px",
    height: "16px",
  },
  inputOuterDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputBox: {
    maxWidth: "456px",
    display: "flex",
    justifyContent: "start" as "start",
    gap: "10px",
  },
  timer: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    alignSelf: "center",
    color: "#FFFFFF",
  },
  didnotRecieve: {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "rgba(255, 255, 255, 0.9)",
    lineHeight: "18px",
  },
  sendOtp: {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: "#F37F78",
    cursor: "pointer",
  },
  submitButtonDiv: {
    width: "343px",
    height: "48px",
  },
  input: {},
};

const StyledErrorMessage = styled("div")({
  background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
  border:"1px solid #19B5B945",
  padding:"0.5rem",
  display:"flex",
  alignItems:"center",
  gap:"0.3rem",
  color:"#FFFFFF",
  fontWeight:400,
  fontSize:"0.75rem",
  marginTop:"3rem",
  borderRadius:"0.5rem",
  boxShadow:"0px 0px 12px -3px #00000014",
  "& span":{
    color:"#F75367",
    cursor:"pointer"
  }
})

// Customizable Area End
