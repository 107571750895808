import React from "react";
import { styled } from "@material-ui/styles";
import { Button, CardScroll, CustomNoDataComponent } from "../../../../components/src";
import { IReminderCard } from "../types";
import ReminderCard from "./ReminderCard.web";
interface Props {
    reminders: IReminderCard[];
    redirectTo: (endpoint: string, params?: { [key: string]: string | number }) => void;
    loading: boolean;
}


const ReminderList: React.FC<Props> = (props: Props) => {
    const { reminders, redirectTo, loading } = props;

    return (
        <StyledReminderList className="GlobalSearch_ReminderList_StyledReminderList">
            <div className="header">
                <p>Upcoming Reminder</p>
                <Button
                    text="View All"
                    type="link"
                    onClick={() => redirectTo("Reminders")}
                />
            </div>
            <div className="list-data">
                {
                    reminders && reminders.length > 0 && (
                        <CardScroll
                            prevStyles={{
                                transform: "translate(-50%, -50%)"
                            }}
                            nextStyles={{
                                transform: "translate(50%, -50%)"
                            }}
                        >
                            {
                                reminders.map((card, card_index) => {
                                    return (
                                        <ReminderCard
                                            reminder={card}
                                            key={card.id}
                                        />
                                    )
                                })
                            }
                        </CardScroll>
                    )
                }
                {
                    !loading && reminders && reminders.length === 0 && (
                        <CustomNoDataComponent
                            titleMsg={"No Reminders"}
                            subTitleMsg={"No reminders. Stay tuned for the updates."}
                            useCommonEmptyImg={true}
                        />
                    )
                }
            </div>
        </StyledReminderList>
    )
}

const StyledReminderList = styled("div")({
    "&.GlobalSearch_ReminderList_StyledReminderList": {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        "& > .header": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "& > p": {
                fontSize: "1rem",
                fontWeight: 500,
                color: "rgba(255, 255, 255, 1)"
            }
        },
    }
})

export default ReminderList;