Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.commentsContentType = "application/json";
exports.commentsApiMethodType = "GET";
exports.commentPOSTAPiMethod = "POST";
exports.commentDELETEAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Comments";
exports.labelBodyText = "Comments Body";
exports.hintCountryCode = "Select Country";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.commentEndPoint = "/comments/comments";
exports.likeCommentEndPoint = "/comments/like_comment";
exports.replyEndpoint = "bx_block_comments/comment_replies";
exports.likedUsersListApiEndpoint = "bx_block_upvotedownvote/upvotedownvotes/likes_list";
exports.likePostEndPoint = "bx_block_upvotedownvote/upvotedownvotes/upvote";
exports.repliesLikedListUsersApiEndpoint = "bx_block_upvotedownvote/upvotedownvotes/comment_reply_like_list"
// Customizable Area End
