// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IReply, IMetaPagination } from "../../../components/src/CommonTypes";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  repliesList?: IReply[];
  isRepliesLoading?: boolean;
  userId: number | undefined;
  handleFetchNextPageReplies?: () => void;
  onDeleteReply?: (replyId: string | number) => void;
  replyMetaData?: IMetaPagination | null;
  focusOnReplyTextfield: () => void;
  handleLikeDislikeReply?: (replyId: number | string) => void;
  fetchLikedUsersList?: (replyId: number | string) => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  token: string | null;
  optionReplyMenuAnchor: HTMLElement | null;
  selectedReplyItem: IReply | null;
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RepliesListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      optionReplyMenuAnchor: null,
      selectedReplyItem: null,
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    await super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };


  receive = async (from: string, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (!token) {
        token = await getStorageData("authToken");
      }
      this.setToken(token);
    }

    // Customizable Area End
  };

  setToken = async (token: string | null) => {
    this.setState({ token });
  };

  closeRepliesListOptionMenu = () => {
    this.setState({ optionReplyMenuAnchor: null, selectedReplyItem: null })
  }

  openRepliesListOptionMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ optionReplyMenuAnchor: event.currentTarget })
  }

  // Customizable Area End
}
