// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const { ApiCallFunction: apiCallFunction } = require("../../appointmentmanagement/src/ApiCallFunction");
export const configJSON = require("./config.js");
import { IBucketListGoal, IMetaPagination } from "../../../components/src/CommonTypes";
import _ from "lodash";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    bucketList: IBucketListGoal[];
    activeTab: string | null;
    selectedBucketListItemId: string | number;
    bucketListPageNum: number;
    bucketListMetadata: IMetaPagination | null;
    isLoadingNextPage: boolean;
    totalCountOfItems: {
        total_completed_count: number
        total_todo_count: number
    };
    selectedTab: "all" | "todo" | "completed";
    search: string;
    confirmationGoalDeletePopUpData: {
        header: string;
        message: string;
        type: string;
        eventId: string;
      } | null;
    deletedGoalId: string;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UpcomingEventsListController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getBucketListApiId: string = "";
    getSearchedBucketListApiId: string = "";
    deleteBucketListItemApiId: string = "";
    markBucketListItemAsCompleted: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            bucketList: [],
            activeTab: null,
            selectedBucketListItemId: "",
            bucketListPageNum: 1,
            bucketListMetadata: null,
            isLoadingNextPage: false,
            totalCountOfItems: {
                total_completed_count: 0,
                total_todo_count: 0,
            },
            selectedTab: "all",
            search: "",
            confirmationGoalDeletePopUpData: null,
            deletedGoalId: "",
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        await super.componentDidMount();
        this.setToken();
        // Customizable Area End
    }

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            switch (apiCallId) {
                case this.getBucketListApiId:
                    this.handleGetBucketListCall(message);
                    break;
                case this.deleteBucketListItemApiId:
                    this.handleDeleteGoalCall(message);
                    break;
                case this.markBucketListItemAsCompleted:
                    this.handleMarkGoalCompletedCall(message);
                    break;
            }
        }
        // Customizable Area End
    };

    // Customizable Area Start
    handleFetchNextGoals = () => {
        this.setState({ bucketListPageNum: this.state.bucketListPageNum + 1}, () => this.getBucketList(true))
    }

    onChangeTab = (value: "all" | "todo" | "completed") => {
        this.setState({ selectedTab: value, bucketListPageNum: 1, bucketListMetadata: null, bucketList: [] }, () => {
            this.getBucketList(true)
        })
    }

    getTabFilteredData = () => {
        const { selectedTab, bucketList } = this.state;
        if (selectedTab === "todo") {
            return bucketList.filter((item) => item.attributes.status === "todo")
        } else if (selectedTab === "completed") {
            return bucketList.filter((item) => item.attributes.status === "completed")
        } else {
            return bucketList;
        }
    }

    handleGetBucketListCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson?.meta) {
            this.setState({ bucketListMetadata: responseJson.meta })
        }
        if (responseJson?.counts) {
            this.setState({ totalCountOfItems: {
                total_completed_count: responseJson.counts?.total_completed_count || 0,
                total_todo_count: responseJson.counts?.total_todo_count || 0,
            } })
        }
        if (!responseJson?.errors && responseJson?.data) {
            this.setState({
                bucketList: _.uniqBy([...this.state.bucketList, ...responseJson.data], "id"),
            })
        }
        this.setState({ isLoadingNextPage: false })
        helper.hideLoader();
    }

    getBucketList = async (fromShowMore?: boolean) => {
        if(!fromShowMore){
            helper.showLoader();
        } else {
            this.setState({ isLoadingNextPage: true })
        }
        const { bucketListPageNum, search, selectedTab } = this.state;
        this.getBucketListApiId = await apiCallFunction({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.validationApiContentType,
            endPoint: `${configJSON.bucketListEndPoint}/search?query=${search}&bucket_type=${selectedTab}&page=${bucketListPageNum}&per_page=10`,
            token: this.state.token
        })
    }

    openGoalDeleteModal = (title: string, eventId: string | number) => {
        this.setState({
            confirmationGoalDeletePopUpData: {
                header: "Delete Goal",
                message: `Are you sure that you want to delete Goal?`,
                type: "delete",
                eventId: eventId as string,
            },
        });
    };

    onCloseDeleteGoalConfirmationModal = () => {
        this.setState({
            confirmationGoalDeletePopUpData: null,
        });
    };

    onAcceptGoalDeleteConfirmation = async () => {
        if (this.state.confirmationGoalDeletePopUpData) {
            const { type, eventId } = this.state.confirmationGoalDeletePopUpData;
            if (type === "delete") {
                this.onDeleteGoal(eventId);
            }
        }
    };

    handleDeleteGoalCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (!responseJson?.errors) {
            helper.showSuccessToast("Goal deleted successfully!");
            const { bucketList, deletedGoalId } = this.state;
            const updatedGoalsList = bucketList.filter(
                (item) => item.id !== deletedGoalId
            );
            this.setState({ bucketList: _.uniqBy(updatedGoalsList, 'id'), totalCountOfItems: {
                total_completed_count: this.state.totalCountOfItems.total_completed_count > 0 ? this.state.totalCountOfItems.total_completed_count - 1 : 0,
                total_todo_count:  this.state.totalCountOfItems.total_todo_count > 0 ? this.state.totalCountOfItems.total_todo_count - 1: 0,
            } });
        } else {
            const errorDelToastMsg = responseJson?.errors?.[0]?.message
                ? responseJson.errors[0].message
                : "Something went wrong, please try again!";
            helper.showErrorToast(errorDelToastMsg);
        }
        this.setState({ deletedGoalId: "" });
        helper.hideLoader();
    };

    onDeleteGoal = async (goalId: string) => {
        this.setState({
            confirmationGoalDeletePopUpData: null,
            deletedGoalId: goalId,
        });
        helper.showLoader();
        this.deleteBucketListItemApiId = await helper.apiCall({
            method: configJSON.deleteAPiMethod,
            endPoint: `${configJSON.bucketListEndPoint}/${goalId}`,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
        });
    };

    handleMarkGoalCompletedCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (!responseJson?.errors && responseJson?.data) {
            helper.showSuccessToast("Goal marked as completed successfully!");
            const { bucketList, selectedBucketListItemId } = this.state;
            const updatedGoalsList = bucketList.map(
                (item) => {
                    if (item.id === selectedBucketListItemId) {
                        item.attributes.status = "completed";
                    }
                    return item;
                }
            );
            this.setState({ bucketList: _.uniqBy(updatedGoalsList, 'id'), totalCountOfItems: {
                total_completed_count: this.state.totalCountOfItems.total_completed_count + 1,
                total_todo_count: this.state.totalCountOfItems.total_todo_count > 0 ? this.state.totalCountOfItems.total_todo_count - 1 : 0,
            } });
        } else {
            const errorToastMsg = responseJson?.errors?.[0]?.message
                ? responseJson.errors[0].message
                : "Something went wrong, please try again!";
            helper.showErrorToast(errorToastMsg);
        }
        this.setState({ selectedBucketListItemId: "" });
        helper.hideLoader();
    };

    onMarkGoalAsCompleted = async (goalId: string) => {
        helper.showLoader();
        this.setState({ selectedBucketListItemId: goalId })
        this.markBucketListItemAsCompleted = await helper.apiCall({
            method: "PUT",
            endPoint: `${configJSON.markGoalAsCompleted}?id=${goalId}`,
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
        });
    };

    onSearch = (search: string) => {
        this.setState({ bucketListPageNum: 1, bucketListMetadata: null, search: search, bucketList: [] }, () => {
            this.getBucketList(true);
        })
    }

    setToken = async () => {
        const token = await helper.getStorageData("authToken");
        this.setState({ token }, () => {
            this.getBucketList();
        })
    }

    redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
        this.props.navigation.navigate(endpoint, params)
    }

    // Customizable Area End
}