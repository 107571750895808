import React from "react";
import { Dialog } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { closeIcon } from "../../../appointmentmanagement/src/assets";
import Avatar from "./Avatar.web";
interface Props {
    list: { id: string | number, attributes: { like_by_name: string, image_url?: string} }[];
    open: boolean;
    onClose: () => void;
}


const LikedUserListModal: React.FC<Props> = (props: Props) => {
    const { list, onClose, open } = props;

    return (
        <Dialog
            open={open}
            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            onClose={onClose}
            PaperProps={{
                style: { background: "transparent" }
            }}
            BackdropProps={{
                style:{backgroundColor: "rgba(0, 0, 0, 0.1)"}
            }}
        >
            <StyledLikedUserListModal className="LikedUserListModal_StyledLikedUserListModal">
                <div className="header">
                    <p>Likes</p>
                    <img src={closeIcon} alt="close.svg" onClick={onClose} />
                </div>
                <div className="body">
                    {
                        list && list.length > 0 && (
                            <div className="likes">
                                {
                                    list.map((item) => {
                                        return (
                                            <div className="name" key={item?.id}>
                                                <Avatar 
                                                  text={item?.attributes?.like_by_name}
                                                  image_url={item.attributes.image_url}
                                                />
                                                {item?.attributes?.like_by_name}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    {
                        list && list.length === 0 && (
                            <div className="no-likes">No likes found</div>
                        )
                    }
                </div>
            </StyledLikedUserListModal>
        </Dialog>

    )
}

const StyledLikedUserListModal = styled("div")({
    "&.LikedUserListModal_StyledLikedUserListModal": {
        display: "flex",
        flexDirection: "column",
        width: "26.25rem",
        height: "34.3125rem",
        backgroundColor: "#23404B",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        "& .header": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "relative",
            padding: "1rem",
            borderBottom: "1px solid #2d4953",
            boxSizing: "border-box",
            userSelect: "none",
            "& p": {
                margin: 0,
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "white"
            },
            "& img": {
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            }
        },
        "& .body": {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            overflowX: "auto",
            gap: "1rem",
            padding: "1rem",
            "& .likes": {
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                "& .name": {
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.875rem",
                    color: "white",
                    gap: "0.5rem",
                    fontWeight: "400",
                    "& .avatar": {
                        alignItems: "center",
                        border: "1px solid #26EFF5",
                        color: "#26EFF5",
                        borderRadius: "100%",
                        textTransform: "capitalize",
                        display: "flex",
                        width: "2rem",
                        height: "2rem",
                        justifyContent: "center",
                    }
                },
            },
            "& .no-likes":{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "0.875rem",
                color: "white",
                fontWeight: "400",
                flex: 1
            }
        },
        [`@media screen and (max-width: 600px)`]: {
            width: "80%",
            height: "70%"
        },
    }
})

export default LikedUserListModal;