// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Wishlist";
exports.labelBodyText = "Wishlist Body";
exports.deleteAPiMethod = "DELETE"
exports.btnExampleTitle = "CLICK ME";
exports.urlGetWishlist = "bx_block_item/items";
exports.getWishlist = "bx_block_wishlist/wishlists";
exports.toggleWishlist = "bx_block_wishlist/toggle_wishlist";
exports.bucketListEndPoint = "bx_block_wishlist/bucketlists";
exports.recentLocationEndpoint = "bx_block_maps3/locations/recent_location?type=goal";
exports.locationEndpoint = "bx_block_maps3/locations";
exports.markGoalAsCompleted = "bx_block_wishlist/bucketlists/mark_as_completed";
// Customizable Area End
