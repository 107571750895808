// Customizable Area Start
import React from "react";
import GlobalSearchController, {
    Props
} from "./GlobalSearchController";
import { styled } from "@material-ui/styles";
import DashboardEvents from "../../dashboard/src/components/DashboardEvents.web";
import ReminderList from "./components/ReminderList.web";
import GroupList from "./components/GroupList.web";
// Customizable Area End

export default class GlobalSearch extends GlobalSearchController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            events,
            appointments,
            reminders,
            groups,
            appointmentsLoading,
            eventsLoading,
            remindersLoading,
            groupsLoading
        } = this.state;
        const {
            searchOptions
        } = this.props;

        return (
            <StyledGlobalSearchContainer className="GlobalSearch_StyledGlobalSearchContainer">
                <header>Search</header>
                <div className="search-item">
                    {
                        searchOptions && searchOptions["event"] && searchOptions["event"].selected && (
                            <DashboardEvents
                                redirectTo={this.redirectTo}
                                header="Events"
                                type="event"
                                events={events}
                                loading={eventsLoading}
                            />
                        )
                    }

                    {
                        searchOptions && searchOptions["appointment"] && searchOptions["appointment"].selected && (
                            <DashboardEvents
                                redirectTo={this.redirectTo}
                                header="Upcoming Appointments"
                                type="appointment"
                                events={appointments}
                                loading={appointmentsLoading}
                            />
                        )
                    }

                    {
                        searchOptions && searchOptions["reminder"] && searchOptions["reminder"].selected && (
                            <ReminderList
                                reminders={reminders}
                                redirectTo={this.redirectTo}
                                loading={remindersLoading}
                            />
                        )
                    }

                    {
                        searchOptions && searchOptions["group"] && searchOptions["group"].selected && (
                            <GroupList 
                              groups={groups}
                              redirectTo={this.redirectTo}
                              loading={groupsLoading}
                            />
                        )
                    }
                </div>
            </StyledGlobalSearchContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledGlobalSearchContainer = styled("div")({
    "&.GlobalSearch_StyledGlobalSearchContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "1.25rem",
        padding: "1.5rem 1rem",
        boxSizing: "border-box",
        "& > header": {
            fontSize: "1.5rem",
            fontWeight: 500,
            color: "rgba(255, 255, 255, 1)"
        },
        "& > .search-item": {
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
            "& > .header": {
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                "& > p": {
                    fontSize: "1rem",
                    fontWeight: 500,
                    color: "rgba(255, 255, 255, 1)"
                }
            },
            "& .DashboardEvents_StyledDashboardEvents_Container": {
                gap: "1rem"
            }
        }
    }
})
// Customizable Area End
