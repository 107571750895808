import React from "react";
import { styled } from "@material-ui/styles";
import { Button, CardScroll, CustomNoDataComponent } from "../../../../components/src";
import { IGroupCard } from "../types";
import ExploreCard from "../../../activityfeed/src/components/ExploreCard.web";
import { ReverseGroupType } from "../../../groups/src/types";
interface Props {
    groups: IGroupCard[];
    redirectTo: (endpoint: string, params?: { [key: string]: string | number }) => void;
    loading: boolean;
}


const GroupList: React.FC<Props> = (props: Props) => {
    const { groups, redirectTo, loading } = props;

    return (
        <StyledGroupList className="GlobalSearch_GroupList_StyledGroupList">
            <div className="header">
                <p>Groups</p>
                <Button
                    text="View All"
                    type="link"
                    onClick={() => redirectTo("Groups")}
                />
            </div>
            <div className="list-data">
                {
                    groups && groups.length > 0 && (
                        <CardScroll
                            prevStyles={{
                                transform: "translate(-50%, -50%)"
                            }}
                            nextStyles={{
                                transform: "translate(50%, -50%)"
                            }}
                        >
                            {
                                groups.map((group, card_index) => {
                                    return (
                                        <div className="list-card">
                                            <ExploreCard
                                                key={group.id}
                                                id={group.id}
                                                image={group.image_url}
                                                title={group.title}
                                                description={""}
                                                type="event"
                                                groupType={ReverseGroupType[group.type]}
                                            />
                                        </div>
                                    )
                                })
                            }
                        </CardScroll>
                    )
                }
                {
                    !loading && groups && groups.length === 0 && (
                        <CustomNoDataComponent
                            titleMsg={"No Groups"}
                            subTitleMsg={"No Groups. Stay tuned for the updates."}
                            useCommonEmptyImg={true}
                        />
                    )
                }
            </div>
        </StyledGroupList>
    )
}

const StyledGroupList = styled("div")({
    "&.GlobalSearch_GroupList_StyledGroupList": {
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        "& > .header": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& > p": {
                fontSize: "1rem",
                fontWeight: 500,
                color: "rgba(255, 255, 255, 1)"
            }
        },
        "& > .list-data .list-card": {
            width: "11.9375rem",
            height: "13.0625rem",
            marginRight: "1.5rem",
            flexShrink: 0,
            "&:last-child": {
                marginRight: 0
            }
        }
    }
})

export default GroupList;