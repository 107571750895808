// Customizable Area Start
import React from "react";
import Picker from "emoji-picker-react";
import EmojisPickerController, {
    Props
} from "./EmojisPickerController";
import { styled } from "@material-ui/styles";
import { Menu } from "@material-ui/core";
// Customizable Area End

export default class EmojisPicker extends EmojisPickerController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
   

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { onEmojiClick, emojiRefElement, onClose } = this.props;
        return (
            <StyledEmojisPickerContainer className="EmojisPicker_StyledEmojisPickerContainer">
                <Menu
                    open={Boolean(emojiRefElement)}
                    anchorEl={emojiRefElement}
                    anchorOrigin={{
                        vertical: 'top',      // Anchor at the top of the button
                        horizontal: 'right', // Align center horizontally
                    }}
                    transformOrigin={{
                        vertical: 'bottom',   // Menu expands from the bottom
                        horizontal: 'right', // Align center horizontally
                    }}
                    onClose={onClose}
                    MenuListProps={{
                        style: {
                            padding: "0",
                        }
                    }}
                    getContentAnchorEl={null}
                >
                    <Picker onEmojiClick={onEmojiClick} />
                </Menu>
            </StyledEmojisPickerContainer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledEmojisPickerContainer = styled("div")({
    "&.EmojisPicker_StyledEmojisPickerContainer": {
        // position: "absolute",
        // top: "54px", // Adjust this value as necessary
        // left: "76px", // Adjust this value as necessary,
        "& .emoji-picker-react": {
            boxShadow: "none",
        }
    }
})
// Customizable Area End
