// Customizable Area Start
import React from "react";
import { Dialog, Typography, styled } from "@material-ui/core";
import { closeIcon } from "../assets";
import { UserContact } from "../types";
import { Avatar } from "../../../../components/src";

interface IInvitedPersons {
    invitedUsersList: { owner_id: number, invited_users: UserContact[], invitation_members?: { [key: string]: string }[] } | null;
    onCloseModal: () => void;
    activeTab: string | null;
    setActiveTab: (activeTab: string | null) => void;
}

const InvitedPersonsModal: React.FC<IInvitedPersons> = (props: IInvitedPersons) => {
    const { invitedUsersList, onCloseModal, activeTab, setActiveTab } = props;
    return (
        <Dialog
            data-test-id="invited-persons-modal"
            open={!!invitedUsersList} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            PaperProps={{
                style: { background: "transparent", boxShadow: "none" }
            }}
            onClose={onCloseModal}
        >
            <StyledInvitedPersonsModal id="event-invited-users-modal" className='Event_StyledInvitedPersonModal'>
                <div className="modal-header">
                    <p>Invited Persons</p>
                    <img src={closeIcon} alt="close.svg" onClick={onCloseModal} />
                </div>
                <div className="modal-body">
                    <div className="modal-tabs">
                        <div data-test-id="all-tab" className={`tab ${activeTab === null ? "active" : ""}`} onClick={() => setActiveTab(null)}>All</div>
                        <div data-test-id="accepted-tab" className={`tab ${activeTab === "accepted" ? "active" : ""}`} onClick={() => setActiveTab("accepted")}>Yes</div>
                        <div data-test-id="rejected-tab" className={`tab ${activeTab === "rejected" ? "active" : ""}`} onClick={() => setActiveTab("rejected")}>No</div>
                    </div>
                    <div className="event-contact-list">
                        {
                            invitedUsersList && invitedUsersList.invited_users.filter(user => (activeTab === null || activeTab === user.rsvp_status)).length > 0 && invitedUsersList.invited_users.filter(user => (activeTab === null || activeTab === user.rsvp_status)).map(user => {
                                return (
                                    <div className="user-contact" key={user.id}>
                                        <div>
                                            <Avatar
                                                text={user.name}
                                                image_url={user.image_url}
                                                status={user.rsvp_status}
                                            />
                                            <div className="event-user-details">
                                                <div>
                                                    {user.name}
                                                    {
                                                        invitedUsersList.owner_id === user.id && (
                                                            <span>{" "}(Organizer)</span>
                                                        )
                                                    }
                                                </div>
                                                <div>{user.email ? user.email : user.full_phone_number}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <svg data-test-id="arrow-icon" width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.35925 7.99933L1.082 1.72208C0.916407 1.55648 0.83574 1.35937 0.840004 1.13074C0.844282 0.902106 0.929219 0.704996 1.09482 0.539412C1.26041 0.373815 1.45752 0.291016 1.68615 0.291016C1.91477 0.291016 2.11189 0.373815 2.27748 0.539412L8.6573 6.93204C8.80793 7.08268 8.91958 7.25147 8.99223 7.43843C9.06487 7.6254 9.10119 7.81237 9.10119 7.99933C9.10119 8.18629 9.06487 8.37325 8.99223 8.56022C8.91958 8.74718 8.80793 8.91598 8.6573 9.06662L2.26467 15.4592C2.09907 15.6248 1.90409 15.7055 1.67973 15.7012C1.45539 15.697 1.26041 15.612 1.09482 15.4464C0.929219 15.2808 0.846421 15.0837 0.846421 14.8551C0.846421 14.6265 0.929219 14.4294 1.09482 14.2638L7.35925 7.99933Z" fill="white" fillOpacity="0.7" />
                                            </svg>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {
                            activeTab === null && invitedUsersList && invitedUsersList.invitation_members && invitedUsersList.invitation_members.filter(user => user.status === "pending").map(user => {
                                return (
                                    <div className="user-contact" key={user.id}>
                                        {
                                            user.invite_record.includes("@") ? (
                                                <div>
                                                    <div className="avatar">
                                                        {user.invite_record[0]}
                                                    </div>
                                                    <div className="event-user-details">
                                                        <div>{user.invite_record}</div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="avatar">
                                                        {user.contact_name[0]}
                                                    </div>
                                                    <div className="event-user-details">
                                                        <div>{user.contact_name}</div>
                                                        <div>{user.invite_record}</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div>
                                            <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.35925 7.99933L1.082 1.72208C0.916407 1.55648 0.83574 1.35937 0.840004 1.13074C0.844282 0.902106 0.929219 0.704996 1.09482 0.539412C1.26041 0.373815 1.45752 0.291016 1.68615 0.291016C1.91477 0.291016 2.11189 0.373815 2.27748 0.539412L8.6573 6.93204C8.80793 7.08268 8.91958 7.25147 8.99223 7.43843C9.06487 7.6254 9.10119 7.81237 9.10119 7.99933C9.10119 8.18629 9.06487 8.37325 8.99223 8.56022C8.91958 8.74718 8.80793 8.91598 8.6573 9.06662L2.26467 15.4592C2.09907 15.6248 1.90409 15.7055 1.67973 15.7012C1.45539 15.697 1.26041 15.612 1.09482 15.4464C0.929219 15.2808 0.846421 15.0837 0.846421 14.8551C0.846421 14.6265 0.929219 14.4294 1.09482 14.2638L7.35925 7.99933Z" fill="white" fill-opacity="0.7" />
                                            </svg>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        {
                            invitedUsersList && invitedUsersList.invited_users.filter(user => (activeTab === null || activeTab === user.rsvp_status)).length === 0 && (
                                <div className="no-invited-person">
                                    <Typography component={"span"}>No invited persons found</Typography>
                                </div>
                            )
                        }
                    </div>
                </div>
            </StyledInvitedPersonsModal>
        </Dialog>
    )
}

const StyledInvitedPersonsModal = styled("div")({
    "&.Event_StyledInvitedPersonModal": {
        display: "flex",
        flexDirection: "column",
        height: "34.3125rem",
        width: "26.25rem",
        maxWidth: "95%",
        maxHeight: "80%",
        backgroundColor: "#23404B",
        boxShadow: "0px 0px 20px 0px #00000040",
        borderRadius: "1rem",
        "& .modal-header": {
            position: "relative",
            padding: "1rem",
            display: "flex",
            borderBottom: "1px solid #2d4953",
            justifyContent: "center",
            alignItems: "center",
            "& p": {
                margin: 0,
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "white"
            },
            "& img": {
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translate(-50%,-50%)",
                cursor: "pointer"
            }
        },
        "& .modal-body": {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            overflowY: "auto",
            padding: "1rem",
            "& .modal-tabs": {
                alignSelf: "center",
                background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                display: "flex",
                border: "1px solid rgba(25, 181, 185, 0.27)",
                alignItems: "center",
                justifyContent: "space-around",
                borderRadius: "3.125rem",
                padding: "0.25rem 0.25rem",
                boxSizing: "border-box",
                "& .tab": {
                    height: "1.75rem",
                    width: "2.625rem",
                    display: "flex",
                    justifyContent: "center",
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "0.875rem",
                    alignItems: "center",
                    fontWeight: 400,
                    borderRadius: "3.125rem",
                    cursor: "pointer",
                    transition: "all 0.3s ease-in-out",
                    "&.active": {
                        background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
                        fontWeight: 500
                    }
                }
            },
            "& .event-contact-list": {
                display: "flex",
                flexDirection: "column",
                "& .user-contact": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #2d4953",
                    padding: "0.5rem 0rem",
                    "&:last-child": {
                        borderBottom: "none"
                    },
                    "& > div": {
                        display: "flex",
                        gap: "0.5rem",
                        alignItems: "center",
                        "& .avatar": {
                            width: "2rem",
                            height: "2rem",
                            border: "1px solid #25ECF2",
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                            color: "#25ECF2",
                            borderRadius: "100%",
                            position: "relative",
                            zIndex: 1,
                            fontSize: "0.875rem",
                            textTransform: "uppercase",
                            "& .icon": {
                                width: "0.75rem",
                                height: "0.75rem",
                                display: "flex",
                                position: "absolute",
                                justifyContent: "center",
                                right: 0,
                                bottom: 0,
                                transform: "translate(25%,25%)",
                                alignItems: "center",
                                backgroundColor: "#00FF7F",
                                borderRadius: "100%",
                                zIndex: 4,
                                "&.not-going": {
                                    backgroundColor: "#FF2F3F"
                                }
                            },
                            "&.extra": {
                                background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                                color: "white",
                                border: "1px solid #F75367"
                            }
                        },
                        "& .event-user-details": {
                            "& > div": {
                                color: "#ffffff",
                                fontSize: "1rem",
                                fontWeight: 500,
                                "& span": {
                                    fontSize: "0.875rem",
                                    fontWeight: 400,
                                    color: "rgba(255, 255, 255, 0.8)"
                                },
                                "&:last-child": {
                                    fontSize: "0.875rem",
                                    color: "rgba(255, 255, 255, 0.8)",
                                    fontWeight: 400
                                }
                            }
                        }
                    }
                }
            },
            "& .no-invited-person": {
                padding: "120px 0px",
                textAlign: "center",
                "& span": {
                    fontSize: "16px",
                    color: "#FFFFFF",
                    fontWeight: 500,
                }
            }
        }
    }
})

export default InvitedPersonsModal;
// Customizable Area End