// Customizable Area Start
import React from "react";
import AddRoutineController, {
    Props,
    RecurrenceEnum,
    days
} from "./AddRoutineController";
import { styled } from "@material-ui/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import {
    Button,
    Chip,
    GoBack,
    InputText,
    RadioButton,
    SelectInterest,
    TextArea,
    ErrorMessage
} from "../../../components/src";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { DropDownIcon } from "../../user-profile-basic/src/assets";
// Customizable Area End

export default class AddRoutine extends AddRoutineController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    InterestsField = () => {
        const {
            isInterestModalOpen
        } = this.state;
        return (
            <StyledInterestsField
                className="AddRoutine_StyledInterestsField"
                onClick={this.toggleInterestModal}
            >
                <p>Select Interest</p>
                <img src={DropDownIcon} className={isInterestModalOpen ? "rotate" : ""} />
            </StyledInterestsField>
        )
    }

    Days = () => {
        const {
            selectedWeekDay
        } = this.state;
        return (
            <StyledDays className="AddRoutine_StyledDays">
                <label>Days to Remind*</label>
                <div className="days">
                    {
                        days.map(day => {
                            return (
                                <div
                                    className={`day ${selectedWeekDay && selectedWeekDay.find(item=> item.value === day.value) ? "selected" : ""}`}
                                    key={day.value}
                                    onClick={() => this.onSelectWeekDay(day)}
                                >
                                    {day.label}
                                </div>
                            )
                        })
                    }
                </div>
            </StyledDays>
        )
    }

    AddRoutineContainer = () => {
        const {
            title,
            start_date,
            end_date,
            start_time,
            end_time,
            description,
            recurrence,
            selectedInterests,
            isInterestModalOpen,
            interests,
            currentSelectedInterests,
            isNewInterestAddApiRunning
        } = this.state;
        return (
            <StyledAddRoutineContainer className="AddRoutine_StyledAddRoutineContainer">
                <GoBack displayText="Add Routine" goBack={this.goBack} />
                <form
                    autoComplete="off"
                    onSubmit={(event)=>{
                        event.preventDefault();
                        this.onSubmit()
                    }}
                >
                    <div className="form-row">
                        <div className="form-col">
                            <InputText
                                value={title}
                                type="text"
                                onChange={(event) => {
                                    const sanitizedValue = event.target.value.replace(/^\s+/, '');
                                    this.onInputChange("title", sanitizedValue);
                                }}
                                label="Title*"
                                maxLength={42}
                            />
                        </div>
                        <div className="form-col">
                            <InputText
                                value={start_date ? helper.formatDate(start_date, "D MMMM YYYY") : ""}
                                label="Select Start Date*"
                                type="text"
                                readonly={true}
                                onChange={() => { }}
                                onClick={() => this.openDatePicker(1)}
                            />
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    format="DD MMMM YYYY"
                                    margin="normal"
                                    id="text_id"
                                    value={start_date}
                                    PopoverProps={{
                                        className: "add-appointment-date-picker"
                                    }}
                                    autoOk={true}
                                    DialogProps={{
                                        className: "datePickerDialog",
                                    }}
                                    onChange={this.onDateChangeHandler}
                                    className="customDatePicker"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        id: "mui-date-picker1"
                                    }}
                                    style={{ display: "none" }}
                                    disablePast
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <InputText
                                onChange={() => { }}
                                value={start_time}
                                label="Select Start Time*"
                                type="text"
                                readonly={true}
                                isContainerFocused={true}
                                isTimePicker={true}
                                onTimeChange={(event) => this.onInputChange("start_time", event)}
                                format="hh:mm A"
                            />
                            <ErrorMessage
                                error={this.validate().start_time}
                            />
                        </div>
                        <div className="form-col">
                            <InputText
                                value={end_date ? helper.formatDate(end_date, "D MMMM YYYY") : ""}
                                label="Select End Date"
                                type="text"
                                readonly={true}
                                onChange={() => { }}
                                onClick={() => this.openDatePicker(2)}
                                isContainerFocused={true}
                            />
                            <ErrorMessage
                                error={this.validate().end_date}
                            />
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="text_id"
                                    value={end_date}
                                    onChange={this.onDateChangeHandler}
                                    className="customDatePicker"
                                    variant="inline"
                                    format="DD MMMM YYYY"
                                    margin="normal"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        id: "mui-date-picker2"
                                    }}
                                    autoOk={true}
                                    DialogProps={{
                                        className: "datePickerDialog",
                                    }}
                                    style={{ display: "none" }}
                                    PopoverProps={{
                                        className: "add-appointment-date-picker"
                                    }}
                                    disablePast
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="form-row" style={{ alignItems: "flex-start" }}>
                        <div className="form-col" style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                            <div style={{ width: "100%" }}>
                                <InputText
                                    onChange={() => { }}
                                    value={end_time}
                                    label="Select End Time"
                                    type="text"
                                    readonly={true}
                                    isContainerFocused={true}
                                    isTimePicker={true}
                                    onTimeChange={(event) => this.onInputChange("end_time", event)}
                                    format="hh:mm A"
                                />
                                <ErrorMessage
                                    error={this.validate().end_time}
                                />
                            </div>
                            <div className="recurrence">
                                <label>Routine Recurrence</label>
                                <div>
                                    <RadioButton
                                        label="Daily"
                                        selected={RecurrenceEnum.Daily === recurrence}
                                        onSelect={() => this.onInputChange("recurrence", RecurrenceEnum.Daily)}
                                    />
                                    <RadioButton
                                        label="Weekly"
                                        selected={RecurrenceEnum.Weekly === recurrence}
                                        onSelect={() => this.onInputChange("recurrence", RecurrenceEnum.Weekly)}
                                    />
                                    <RadioButton
                                        label="Monthly"
                                        selected={RecurrenceEnum.Monthly === recurrence}
                                        onSelect={() => this.onInputChange("recurrence", RecurrenceEnum.Monthly)}
                                    />
                                    <RadioButton
                                        label="Annual"
                                        selected={RecurrenceEnum.Annual === recurrence}
                                        onSelect={() => this.onInputChange("recurrence", RecurrenceEnum.Annual)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-col">
                            <TextArea
                                value={description}
                                onChange={(event) => {
                                    const sanitizedValue = event.target.value.replace(/^\s+/, '');
                                    this.onInputChange("description", sanitizedValue);
                                }}
                                label="Description"
                                isContainerFocused={true}
                                maxLength={1024}
                            />
                        </div>
                    </div>
                    <div className="form-row" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <div className="form-col" style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                            {this.InterestsField()}
                            {
                                selectedInterests.length > 0 && (
                                    <div style={{ display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>
                                        {
                                            selectedInterests.map(interest => {
                                                return (
                                                    <Chip
                                                        onRemove={this.removeInterestFromSelectedInterest}
                                                        key={interest.id}
                                                        displayText={interest.attributes.name}
                                                        id={interest.id}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                            {
                                recurrence === RecurrenceEnum.Weekly && (
                                    this.Days()
                                )
                            }
                        </div>
                    </div>
                    <div className="form-row" style={{ justifyContent: 'center', alignItems: "center", marginTop: "2rem" }}>
                        <Button
                            text="Submit"
                            buttonStyles={{
                                background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
                                width: "21.4375rem"
                            }}
                            buttonType="submit"
                            disabled={this.disableSubmit()}
                        />
                    </div>
                </form>
                {
                    isInterestModalOpen && (
                        <SelectInterest
                            currentSelectedInterest={currentSelectedInterests}
                            open={isInterestModalOpen}
                            interests={interests}
                            onClose={this.toggleInterestModal}
                            setCurrentSelectInterest={this.setCurrentSelectedInterest}
                            onAddInterests={this.onAddInterests}
                            isNewInterestAdding={isNewInterestAddApiRunning}
                            onSubmitNewInterest={this.onSubmitNewInterest}
                        />
                    )
                }
            </StyledAddRoutineContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <NavigationMenu {...this.props} children={this.AddRoutineContainer()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledAddRoutineContainer = styled("div")({
    "&.AddRoutine_StyledAddRoutineContainer": {
        display: "flex",
        flexDirection: "column",
        padding: "1.5rem 1rem 0rem",
        gap: "3rem",
        "& > form": {
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            padding: "0rem 5rem",
            "& > .form-row": {
                display: "flex",
                width: "100%",
                gap: "2%",
                "& > .form-col": {
                    width: "49%",
                    "& > .recurrence": {
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                        "& > label": {
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "rgba(242, 242, 242, 1)"
                        },
                        "& > div": {
                            display: "flex",
                            gap: "1.2rem",
                            alignItems: "center",
                            flexWrap: "wrap"
                        }
                    }
                }
            },
            [`@media screen and (max-width: 900px)`]: {
                padding: 0,
                "& > .form-row": {
                    flexDirection: "column",
                    gap: "1.5rem",
                    "& > .form-col": {
                        width: "100% !important",
                    }
                },
            },
        },
        "& .rotate": {
            transform: "rotate(180deg)"
        }
    }
})

const StyledInterestsField = styled("div")({
    "&.AddRoutine_StyledInterestsField": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
        cursor: "pointer",
        width: "100%",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        height: "3rem",
        borderRadius: "6.25rem",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        padding: "0 1rem",
        "& > p": {
            fontSize: "1rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 1)"
        },
    }
})

const StyledDays = styled("div")({
    "&.AddRoutine_StyledDays": {
        display: "flex",
        gap: "0.5rem",
        flexDirection: "column",
        marginTop: "1rem",
        "& > label": {
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "rgba(242, 242, 242, 1)"
        },
        "& > .days": {
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
            "& > .day": {
                width: "2.5625rem",
                height: "2.25rem",
                borderRadius: "0.25rem",
                border: "1px solid rgba(25, 181, 185, 0.27)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "1rem",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 1)",
                cursor: "pointer",
                "&.selected": {
                    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                    color: "rgba(255, 105, 118, 1)",
                    borderColor: "rgba(247, 83, 103, 1)"
                }
            }
        }
    }
})
// Customizable Area End
