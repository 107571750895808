// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
import { IGroup } from "./types";
import { UserContact } from "../../../components/src/CommonTypes";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
export const webConfigJSON = require("./config.js");
// Customizable Area End

export interface Props {
    // Customizable Area Start
    groupDetail: IGroup | null;
    onClose: () => void;
    onDeleteGroup?: ()=> void;
    navigateTo: (endpoint: string, params?:{[key:string]:string | number})=> void;
    onAcceptRejectPendingRequest?: () => void;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    selectedGroup: IGroup | null;
    groupDeleteConfirmationPopup: {
        header: string;
        message: string;
        type: string;
        groupId: number;
    } | null;
    requestStatus: "accepted" | "rejected" | "",
    pendingRequestId: string | number;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class GroupInfoController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getGroupDetailApiCallId?: string = "";
    acceptRejectPendingRequestApiCallId?: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            selectedGroup: null,
            groupDeleteConfirmationPopup: null,
            requestStatus: "",
            pendingRequestId: "",
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start
        // Customizable Area End
    }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    receive = async (from: string, message: Message) => {
        // Customizable Area Start
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!token) {
                token = await getStorageData("authToken");
            }
            this.setToken(token);
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch(webApiRequestCallId) {
                case this.getGroupDetailApiCallId:
                    this.handleGetGroupDetailApiCall(message);
                    break;
                case this.acceptRejectPendingRequestApiCallId:
                    this.handlerAcceptGroupPendingRequestApiCall(message);
                    break;
                
            }
        }

        // Customizable Area End
    };

    // Customizable Area Start

    setToken = async (token: string | null) => {
        this.setState({ token }, () => {
            this.getGroupDetail();
        })
    }

    getGroupDetail = async () => {
        const {
            groupDetail
        } = this.props;
        if (groupDetail) {
            helper.showLoader();
            this.getGroupDetailApiCallId = await helper.apiCall({
                method: "GET",
                contentType: webConfigJSON.validationApiContentType,
                token: this.state.token,
                endPoint: `${webConfigJSON.groupApiEndPoint}/${groupDetail.id}`
            })
        }
    }

    handleGetGroupDetailApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data) {
            const { attributes } = responseJson.data;
            const selectedGroup: IGroup = {
                id: attributes.id,
                name: attributes.name,
                group_type: attributes.group_type,
                image_url: attributes.image_url,
                description: attributes.description,
                members_count: attributes.members_count,
                members: attributes.members,
                user_contacts: attributes.user_contacts,
                invitation_members: attributes.invitation_members,
                request_pending: attributes.request_pending,
                request_pending_count: attributes.request_pending_count,
                is_admin: attributes.is_admin,
                is_current_user_member: attributes.is_current_user_member,
            }
            this.setState({
                selectedGroup
            })
        }
        helper.hideLoader();
    }

    acceptRejectGroupPendingRequest = async (pendingRequestId: string | number, status: "accepted" | "rejected") => {
        const { token } = this.state;
        this.setState({ requestStatus: status, pendingRequestId: pendingRequestId }, async () => {
            this.acceptRejectPendingRequestApiCallId = await helper.apiCall({
                contentType: webConfigJSON.validationApiContentType,
                method: "PUT",
                token,
                endPoint: `${webConfigJSON.acceptRejectPendingRequest}?group_account_id=${pendingRequestId}&status=${status}`
            })
        })
    }

    handlerAcceptGroupPendingRequestApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const { pendingRequestId, requestStatus, selectedGroup } = this.state;
        if (responseJson?.data || responseJson?.message) {
            if(selectedGroup?.request_pending) {
                const updatedPendingRequest = selectedGroup.request_pending.find((item) => item.id === pendingRequestId)
                const updatedPendingRequestList = selectedGroup.request_pending.filter((item) => item.id !== pendingRequestId);
                const updatedSelectedGroup = { ...selectedGroup};
                updatedSelectedGroup.request_pending = [ ...updatedPendingRequestList];
                if(requestStatus === "accepted" && selectedGroup?.user_contacts && updatedPendingRequest) {
                    const addedUserContact: UserContact = {
                        email: "",
                        full_phone_number: updatedPendingRequest.attributes.account_info.full_phone_number,
                        id: updatedPendingRequest?.id,
                        name: updatedPendingRequest.attributes.account_info.full_name,
                        rsvp_status: null,
                    }
                    if(updatedPendingRequest){
                        updatedSelectedGroup.user_contacts = [ ...selectedGroup.user_contacts, {...addedUserContact}]
                    }
                }
                this.setState({ selectedGroup: updatedSelectedGroup })
                this.props.onAcceptRejectPendingRequest?.();
            }
        } else {
            let errorMsg = "Something went wrong while accepting the request, please try again!";
            if (requestStatus === "rejected") {
                errorMsg = "Something went wrong while rejecting the request, please try again!"
            }
            helper.showErrorToast(errorMsg);
        }
        this.setState({ requestStatus: "", pendingRequestId: "" })
    }


    onDeleteGroupHandler = () => {
        const {
            selectedGroup
        } = this.state;
        if (selectedGroup) {
            this.setState({
                groupDeleteConfirmationPopup: {
                    header: "Delete Group",
                    message: `Are you sure that you want to delete ${selectedGroup.name}?`,
                    type: "delete",
                    groupId: selectedGroup.id
                }
            })
        }
    }

    onAccept = async () => {
        const {
            onDeleteGroup
        } = this.props;
        if (onDeleteGroup) {
            onDeleteGroup();
        }
        this.setState({
            groupDeleteConfirmationPopup: null
        })
    }

    onCloseConfirmationPopup = ()=>{
        this.setState({
            groupDeleteConfirmationPopup: null
        })
    }

    // Customizable Area End
}