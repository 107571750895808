import React from "react";
import { styled } from "@material-ui/styles";
const leftArrow = require("./left-arrow.svg")
interface Props {
    displayText: string;
    goBack: () => void;
}


const GoBack: React.FC<Props> = (props: Props) => {
    const { displayText, goBack } = props;

    return (
        <StyledGoBack onClick={goBack} className="GoBack_StyledGoBack">
            <img src={leftArrow} alt="" />
            <p>{displayText}</p>
        </StyledGoBack>
    )
}

const StyledGoBack = styled("div")({
  "&.GoBack_StyledGoBack": {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    cursor: "pointer",
    width: "fit-content",
    "& p": {
      color: "white",
      fontWeight: 500,
      fontSize: "1.25rem"
    }
  }
})

export default GoBack;