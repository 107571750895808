import React, { useState } from "react";
import { styled } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Moment } from "moment/moment";
interface StartAndEndTimeProps {
    startTime: string;
    endTime: string;
    format?: string;
    onChange: (pickerIndex: number, time: string) => void;
    startTile: string;
    endTitle: string;
}


const StartAndEndTime: React.FC<StartAndEndTimeProps> = (props: StartAndEndTimeProps) => {
    const { startTime, endTime, format = "LT", onChange, startTile, endTitle } = props;
    const [timePickerIndex, setTimePickerIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const openTimePicker = (pickerIndex: number) => {
        setTimePickerIndex(pickerIndex);
        setOpen(true);
    }
    const onTimeSelect = (value: Date | Moment | null) => {
        if (value) {
            const moment = new MomentUtils();
            const formattedTime = moment.date(value).format(format)
            onChange(timePickerIndex, formattedTime)
        }
    }
    return (
        <StyledStartAndEndTime className="StartAndEndTime_StyledStartAndEndTime">
            <div className="connector-container">
                <div className="dot"></div>
                <div className="dashed"></div>
                <div className="dot"></div>
            </div>
            <div className="time-container">
                <div className="time" onClick={() => openTimePicker(0)}>
                    <p>{startTile}</p>
                    {
                        startTime && <p>{startTime}</p>
                    }
                </div>
                <div className="divider"></div>
                <div className="time" onClick={() => openTimePicker(1)}>
                    <p>{endTitle}</p>
                    {
                        endTime && <p>{endTime}</p>
                    }
                </div>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                        open={open}
                        onClose={() => setOpen(false)}
                        variant="dialog"
                        format="HH:mm"
                        margin="normal"
                        id="time-picker-inline"
                        value={startTime}
                        onChange={(event) => onTimeSelect(event)}
                        KeyboardButtonProps={{
                            id: "appointment-time"
                        }}
                        style={{ display: "none" }}
                        onAccept={(event) => onTimeSelect(event)}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </StyledStartAndEndTime>
    )
}

const StyledStartAndEndTime = styled("div")({
    "&.StartAndEndTime_StyledStartAndEndTime": {
        borderRadius: "1.5rem",
        height: "7rem",
        padding: "1rem 0rem 1rem 1rem",
        gap: "0.5rem",
        display: "flex",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        "& .connector-container": {
            padding: "0.5rem 0rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& .dot": {
                width: "0.5rem",
                height: "0.5rem",
                backgroundColor: "#FFFFFF26",
                borderRadius: "100%"
            },
            "& .dashed": {
                display: "flex",
                flex: 1,
                border: "1px dashed #FFFFFF26"
            }
        },
        "& .time-container": {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            flexDirection: "column",
            "& .divider": {
                height: "1px",
                backgroundColor: "#FFFFFF33"
            },
            "& .time": {
                fontSize: "1rem",
                alignItems: "center",
                paddingRight: "1rem",
                cursor: "pointer",
                color: "#ffffff",
                display: "flex",
                justifyContent: "space-between"
            },
        }
    }
})

export default StartAndEndTime;