// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { IGroup } from "./types";
import { IGroupPendingRequest, IPost } from "../../../components/src/CommonTypes";

export interface Group{
  name:string;
  description:string;
  group_name:string | undefined | null;
  id:number;
}

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: string;
  groupSearchValue: string;
  groups: IGroup[];
  sharedGroups: IGroup[];
  trendingGroups: IGroup[];
  selectedGroup: IGroup | null;
  groupPostslist: IPost[];
  pageNum: number;
  isAllGroupFetched: boolean;
  pendingGroupRequests: IGroupPendingRequest[];
  totalPendingRequestsCount: number;
  openPendingRequestsModal: boolean;
  errorOccurredInAcceptingRejectingRequest: string;
  statusSelected: string;
  acceptedRejectedId: number | string;
  matches: boolean;
  showTrendingGroups: boolean;
  isTrendingGroupsLoading: boolean;
  isMyGroupsLoading: boolean;
  isSharedGroupsLoading: boolean;
  onScreenLoad: boolean;
}

interface SS {
  id: any;
}

export default class GroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGroupsApiCallId: string = "";
  getSharedGroupsApiCallId: string = "";
  getTrendingGroupsApiCallId: string = "";
  searchedGroupsApiCallId: string = "";
  deleteGroupApiCallId: string = "";
  getGroupPendingRequestCallId: string = "";
  getPendingRequestCountApiCallId: string = "";
  acceptPendingRequestApiCallId: string = "";
  searchPendingRequestApiCallId: string = "";
  joinGroupRequestApiCallId: string = "";
  mediaQuery = window.matchMedia("(max-width: 960px)");

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleMediaQueryChange = this.handleMediaQueryChange.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      groupSearchValue: "",
      groups: [],
      selectedGroup: null,
      sharedGroups: [],
      groupPostslist: [],
      pageNum: 1,
      isAllGroupFetched: false,
      pendingGroupRequests: [],
      totalPendingRequestsCount: 0,
      openPendingRequestsModal: false,
      errorOccurredInAcceptingRejectingRequest: "",
      statusSelected: "",
      acceptedRejectedId: "",
      matches: this.mediaQuery.matches,
      showTrendingGroups: false,
      isTrendingGroupsLoading: false,
      trendingGroups: [],
      isMyGroupsLoading: false,
      isSharedGroupsLoading: false,
      onScreenLoad: false,
      };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (!token) {
        token = await helper.getStorageData("authToken");
      }
      this.setState({
        token: token
      })
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      switch (webApiRequestCallId) {
        case this.getGroupsApiCallId:
          this.handlerGetGroupsApiCall(message)
          break;
        case this.getSharedGroupsApiCallId:
          this.handlerGetSharedGroupsApiCall(message)
          break;
        case this.getTrendingGroupsApiCallId:
          this.handlerGetTrendingGroupsApiCall(message)
          break;
        case this.deleteGroupApiCallId:
          this.handleDeleteGroupApiCall(message);
          break;
        case this.getGroupPendingRequestCallId:
          this.handlerGetGroupPendingRequestsApiCall(message);
          break;
        case this.getPendingRequestCountApiCallId:
          this.handlerGetGroupPendingRequestsCountApiCall(message);
          break;
        case this.acceptPendingRequestApiCallId:
          this.handlerAcceptGroupPendingRequestApiCall(message);
          break;
        case this.searchPendingRequestApiCallId:
          this.handlerGetSearchedGroupPendingRequestsCountApiCall(message);
          break;
        case this.joinGroupRequestApiCallId:
          this.handlerJoinGroupRequestApiCall(message);
          break;
        case this.searchedGroupsApiCallId:
          this.handlerGetSearchedGroupsApiCall(message);
          break;
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getGroups(true);
    this.getSharedGroups();
    this.getGroupPendingRequestsCount();
    this.mediaQuery.addEventListener('change', this.handleMediaQueryChange);
  }

  async componentWillUnmount() {
    this.mediaQuery.removeEventListener('change', this.handleMediaQueryChange);
  }

  handleMediaQueryChange(e: { matches: boolean }) {
    this.setState({ matches: e.matches });
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  redirectTo = (endpoint: string, params?: { [key: string]: string | number }) => {
    this.props.navigation.navigate(endpoint, params)
  }

  onClosePendingRequestsModal = () => {
    this.setState({ openPendingRequestsModal: false, pendingGroupRequests: [] })
  }

  onSearchGroupPendingRequests = async (searchString?: string) => {
    const { token } = this.state;
    let endPoint = webConfigJSON.searchPendingRequest;
    if(searchString) {
      endPoint = `${endPoint}?query=${searchString}`;
      this.searchPendingRequestApiCallId = await helper.apiCall({
        method: "GET",
        contentType: webConfigJSON.validationApiContentType,
        token,
        endPoint
      })
    }else{
      this.getGroupPendingRequests(true)
    }
    
  }

  handlerGetSearchedGroupPendingRequestsCountApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data) {
      this.setState({ pendingGroupRequests: responseJson.data })
    } else {
      this.setState({
        pendingGroupRequests: []
      })
    }
  }

  joinGroupRequest = async (groupId?: number) => {
    helper.showLoader();
    const { token } = this.state;
    this.joinGroupRequestApiCallId = await helper.apiCall({
      method: "POST",
      contentType: webConfigJSON.validationApiContentType,
      token,
      endPoint: `${webConfigJSON.joinGroupEndpoint}?group_id=${groupId}`
    })
  }

  handlerJoinGroupRequestApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson?.data) {
      if (responseJson.data?.attributes?.joined_status === "accepted") {
        const updatedSharedGroups = this.state.sharedGroups.filter((item) => item.id !== responseJson.data?.attributes?.group_id);
        const updatedGroup = this.state.sharedGroups.find((item) => item.id === responseJson.data?.attributes?.group_id)
        this.setState({ sharedGroups: updatedSharedGroups })
        if (updatedGroup) {
          updatedGroup.type = "my-group";
          updatedGroup.is_current_user_member = true;
          this.setState({ groups: [{ ...updatedGroup }, ...this.state.groups], selectedGroup:  updatedGroup})
        }
      } else if (responseJson.data?.attributes?.joined_status === "pending") {
        helper.showSuccessToast("Your request has been sent to admin.");
      }
    } else {
      const toastMsg = responseJson?.errors?.[0]?.message ? responseJson.errors[0].message : "Something went wrong, please try again!";
      helper.showErrorToast(toastMsg);
    }
    helper.hideLoader();
  }

  acceptRejectGroupPendingRequest = async (groupAccountId: string | number, status: string) => {
    const { token } = this.state;
    this.setState({ statusSelected: status, acceptedRejectedId: groupAccountId }, async() => {
      this.acceptPendingRequestApiCallId = await helper.apiCall({
        method: "PUT",
        contentType: webConfigJSON.validationApiContentType,
        token,
        endPoint: `${webConfigJSON.acceptRejectPendingRequest}?group_account_id=${groupAccountId}&status=${status}`
      })
    })
  }

  handlerAcceptGroupPendingRequestApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson?.data || responseJson?.message) {
      const updatedPendingRequestList = this.state.pendingGroupRequests.filter((item) => item.id !== this.state.acceptedRejectedId);
      this.setState({ pendingGroupRequests: updatedPendingRequestList, totalPendingRequestsCount: this.state.totalPendingRequestsCount - 1 })
    } else {
      const { statusSelected } = this.state;
      let errorMsg = "Something went wrong while accepting the request, please try again!";
      if(statusSelected === "reject") {
        errorMsg = "Something went wrong while rejecting the request, please try again!"
      }
      this.setState({ errorOccurredInAcceptingRejectingRequest: errorMsg })
      setTimeout(() => {
        this.setState({ errorOccurredInAcceptingRejectingRequest: ""})
      }, 3000)
    }
    this.setState({ statusSelected: "", acceptedRejectedId: "" })
  }

  getGroupPendingRequestsCount = async () => {
    const token = await helper.getStorageData("authToken")
    this.getPendingRequestCountApiCallId = await helper.apiCall({
      method: "GET",
      contentType: webConfigJSON.validationApiContentType,
      token,
      endPoint: `${webConfigJSON.getPendingRequestCount}`
    })
  }

  handlerGetGroupPendingRequestsCountApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.pending_request) {
      this.setState({
        totalPendingRequestsCount: responseJson.pending_request
      })
    } else {
      this.setState({
        totalPendingRequestsCount: 0
      })
    }
  }

  getGroupPendingRequests = async (hideLoader?: boolean) => {
    if(!hideLoader){
      helper.showLoader();
    }
    const token = await helper.getStorageData("authToken")
    this.getGroupPendingRequestCallId = await helper.apiCall({
      method: "GET",
      contentType: webConfigJSON.validationApiContentType,
      token,
      endPoint: `${webConfigJSON.groupPendingRequests}`
    })
  }

  changeSearchFieldValue = (event: string) => {
    this.setState({ groupSearchValue: event }, () => {
      if (this.state.groupSearchValue) {
        this.getSearchedGroups();
      } else {
        this.getGroups();
        this.getSharedGroups();
      }
    })
  }

  handlerGetGroupPendingRequestsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson?.data?.length) {
      this.setState({ pendingGroupRequests: responseJson.data, totalPendingRequestsCount: responseJson?.data?.length }, () => {
        this.setState({ openPendingRequestsModal: true })
      })
    } else {
      this.setState({
        pendingGroupRequests: [], totalPendingRequestsCount: 0,
      }, () => {
        this.setState({ openPendingRequestsModal: true })
      })
    }
    helper.hideLoader();
  }

  getSearchedGroups = async () => {
    const { groupSearchValue } = this.state;
    this.setState({ isMyGroupsLoading: true, isSharedGroupsLoading: true })
    this.getGroupsApiCallId = "";
    this.getSharedGroupsApiCallId = "";
    const token = await helper.getStorageData("authToken")
    this.searchedGroupsApiCallId = await helper.apiCall({
      method: "GET",
      contentType: webConfigJSON.validationApiContentType,
      token,
      endPoint: `${webConfigJSON.groupApiEndPoint}/search_group?query=${groupSearchValue}`
    })
  }

  handleGetGroupItem = (groupAttribute: IGroup) => {
    return {
      id: groupAttribute.id,
      name: groupAttribute.name,
      description: groupAttribute.description,
      group_type: groupAttribute.group_type,
      image_url: groupAttribute.image_url,
      members_count: groupAttribute.members_count,
      members: groupAttribute.members,
      is_admin: groupAttribute.is_admin,
      is_current_user_member: groupAttribute.is_current_user_member,
    }
  }

  handlerGetSearchedGroupsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && (responseJson.my_groups || responseJson.shared_groups)) {
      const myGroups: IGroup[] = responseJson.my_groups.data?.map((myGroupItem: { attributes: IGroup }) => {
        const { attributes } = myGroupItem;
        return { ...this.handleGetGroupItem(attributes), type: "my_group"}
      })
      const sharedGroups: IGroup[] = responseJson.share_groups.data?.map((sharedItem: { attributes: IGroup }) => {
        const { attributes } = sharedItem;
        return { ...this.handleGetGroupItem(attributes), type: "shared_group"}
      })
      const { selectedGroup } = this.state;
      if(selectedGroup) {
        let isSelectedGroupPresentInSearchedMyGroupItems: IGroup | undefined = undefined;
        let isSelectedGroupPresentInSearchedSharedGroupItems: IGroup | undefined = undefined;
        isSelectedGroupPresentInSearchedMyGroupItems = myGroups.find((item) => item.id === selectedGroup.id);
        isSelectedGroupPresentInSearchedSharedGroupItems = sharedGroups.find((item) => item.id === selectedGroup.id);
        if((!isSelectedGroupPresentInSearchedMyGroupItems && !isSelectedGroupPresentInSearchedSharedGroupItems)) {
          this.setState({ selectedGroup: null })
        }
      }
      this.setState({
        groups: [...myGroups],
        sharedGroups: [...sharedGroups]
      })
    }
    this.setState({ isMyGroupsLoading: false, isSharedGroupsLoading: false })
  }

  getGroups = async (firstLoad?: boolean) => {
    this.setState({ isMyGroupsLoading: true, onScreenLoad: !!firstLoad })
    this.searchedGroupsApiCallId = "";
    const token = await helper.getStorageData("authToken")
    this.getGroupsApiCallId = await helper.apiCall({
      method: "GET",
      contentType: webConfigJSON.validationApiContentType,
      token,
      endPoint: `${webConfigJSON.groupApiEndPoint}?query=my_groups`
    })
  }

  handlerGetGroupsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      const groups = responseJson.data.map((item: { attributes: IGroup }) => {
        const { attributes } = item;
        return {
          id: attributes.id,
          name: attributes.name,
          description: attributes.description,
          group_type: attributes.group_type,
          image_url: attributes.image_url,
          members_count: attributes.members_count,
          members: attributes.members,
          type: "my-group",
          is_admin: attributes.is_admin,
          is_current_user_member: attributes.is_current_user_member,
        }
      })
      this.setState({
        groups,
      }, () => {
        if(!this.state.selectedGroup && this.state.onScreenLoad) {
          this.setState({ selectedGroup: groups[0] })
        }
      })
    } else {
      this.setState({
        groups: []
      })
    }
    this.setState({ isMyGroupsLoading: false })
  }

  getSharedGroups = async ()=> {
    this.setState({ isSharedGroupsLoading: true })
    this.searchedGroupsApiCallId = "";
    const token = await helper.getStorageData("authToken")
    this.getSharedGroupsApiCallId = await helper.apiCall({
      method: "GET",
      contentType: webConfigJSON.validationApiContentType,
      token,
      endPoint: `${webConfigJSON.groupApiEndPoint}?query=shared_groups`
    })
  }

  handlerGetSharedGroupsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson && responseJson.data) {
      const groups = responseJson.data.map((item: { attributes: IGroup }) => {
        const { attributes } = item;
        return {
          id: attributes.id,
          name: attributes.name,
          group_type: attributes.group_type,
          image_url: attributes.image_url,
          description: attributes.description,
          members_count: attributes.members_count,
          members: attributes.members,
          type: "shared-group",
          is_admin: attributes.is_admin,
          is_current_user_member: attributes.is_current_user_member,
        }
      })
      this.setState({
        sharedGroups: groups
      })
    } else {
      this.setState({
        sharedGroups: []
      })
    }
    this.setState({ isSharedGroupsLoading: false })
  }

  getTrendingGroups = async ()=> {
    this.searchedGroupsApiCallId = "";
    this.setState({ groupSearchValue: "", selectedGroup: null, isTrendingGroupsLoading: true });
    const token = await helper.getStorageData("authToken")
    this.getTrendingGroupsApiCallId = await helper.apiCall({
      method: "GET",
      contentType: webConfigJSON.validationApiContentType,
      token,
      endPoint: `${webConfigJSON.trendingGroupsEndpoint}`
    })
  }

  handlerGetTrendingGroupsApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (responseJson.length) {
      const groups = responseJson.map((item: {groups: { data: { attributes : IGroup} }}) => {
        const { attributes } = item.groups.data;
        return {
          id: attributes.id,
          name: attributes.name,
          group_type: attributes.group_type,
          image_url: attributes.image_url,
          description: attributes.description,
          members_count: attributes.members_count,
          members: attributes.members,
          type: "trending-group",
          is_admin: attributes.is_admin,
          is_current_user_member: attributes.is_current_user_member,
        }
      })
      this.setState({
        trendingGroups: groups
      })
    } else {
      this.setState({
        trendingGroups: []
      })
    }
    this.setState({ isTrendingGroupsLoading: false })
  }

  handleShowTrendingGroups = () => {
    const { selectedGroup, sharedGroups } = this.state;
    const findSelectedGroupInShared = sharedGroups.find((item) => item.id === selectedGroup?.id);
    if(findSelectedGroupInShared) {
      this.setState({ selectedGroup: null })
    }
    this.setState({ showTrendingGroups: true }, () => {
      this.getTrendingGroups();
    })
  }

  handleBackToSharedGroups = () => {
    this.setState({ showTrendingGroups: false }, () => {
      const findSelectedGroupInShared = this.state.trendingGroups.find((item) => item.id === this.state.selectedGroup?.id);
      if (findSelectedGroupInShared) {
        this.setState({ selectedGroup: null })
      }
      if (!this.state.groupSearchValue) {
        this.getSharedGroups();
      } else {
        this.getSearchedGroups();
      }
    })
  }

  onSelectGroup = (group: IGroup)=>{
    this.setState({
      selectedGroup: group
    })
  }

  onDeleteGroup = async () => {
    const {
      selectedGroup
    } = this.state;
    if (selectedGroup) {
      helper.showLoader();
      this.deleteGroupApiCallId = await helper.apiCall({
        method: "DELETE",
        contentType: webConfigJSON.validationApiContentType,
        token: this.state.token,
        endPoint: `${webConfigJSON.groupApiEndPoint}/delete_group?id=${selectedGroup.id}`
      })
    }
  }

  handleDeleteGroupApiCall = (message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(responseJson && responseJson.message){
      helper.showSuccessToast(responseJson.message)
      const updatedGroupList = this.state.groups.filter((item) => item.id !== this.state.selectedGroup?.id)
      this.setState({
        groups: updatedGroupList,
        selectedGroup: null
      })
    }else{
      const {
        errors
      } = responseJson;
      if(errors && errors.length){
        helper.showErrorToast(errors[0].message);
      }else{
        helper.showSuccessToast("Something went wrong! please try again")
      }
    }
    helper.hideLoader();
  }
   
}

// Customizable Area End
