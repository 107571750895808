import React from "react";
import { CustomDateField } from "../../blocks/scheduling/src/components/CustomDateField";
import DatePicker from "react-datepicker";
import { styled } from "@material-ui/styles";

interface Props {
    open: boolean;
    selectedDate: Date;
    onChange?: (event: Date | null) => void;
    hasEventInThisData?: (event: Date) => boolean;
    onClose?: () => void;
    onOpen?: () => void;
}


const CustomDatePicker: React.FC<Props> = (props: Props) => {
    const {
        open,
        selectedDate,
        onChange,
        hasEventInThisData,
        onClose,
        onOpen
    } = props;

    const onChangeDate = (event: Date | null) => {
        onChange?.(event)
    }

    const IsEqualDate = (dateValue1: Date, dateValue2: Date) => {
        return (dateValue1 && dateValue2 && dateValue1.getDate() === dateValue2.getDate() && dateValue1.getMonth() === dateValue2.getMonth() && dateValue1.getFullYear() === dateValue2.getFullYear())
    }

    const isPastDate = (dateValue: Date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return dateValue < today;
    }

    const dayClassName = (date: Date) => {
        return hasEventInThisData?.(date) ? 'event-date' : '';
    };

    const onDatePickerChange = (currentDate: Date) => {
    }

    const onDateSelectFromDatePicker = (selectedDate: Date) => {
        onChange?.(selectedDate);

    }

    const renderDayContents = (dayValue: number, dateValue: Date) => {
        const selected = IsEqualDate(dateValue, selectedDate) ? "selected" : "";
        const past = isPastDate(dateValue) ? "past" : "";
        const today = IsEqualDate(dateValue, new Date()) ? "today" : "";
        return (
            <StyledCustomDay className={`DatePicker_StyledCustomDay ${selected} ${dayClassName(dateValue)} ${past} ${today}`}>
                {dayValue}
                <span></span>
            </StyledCustomDay>
        )
    };

    const clickedOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        const datePicker = document.getElementById("date-picker");

        if (datePicker && !datePicker.contains(event.target as Node)) {
            onClose?.()
        }
    }

    const toggleDatePicker = () => {
        if (open) {
            onClose?.();
        } else {
            onOpen?.();
        }
    }

    return (
        <DatePicker
            open={open}
            selected={selectedDate}
            onChange={onChangeDate}
            customInput={
                <CustomDateField
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.25rem",
                        color: "white",
                        fontSize: "1.125rem",
                        fontWeight: 500,
                        cursor: "pointer"
                    }}
                    currentDate={selectedDate}
                />
            }
            renderDayContents={renderDayContents}
            onCalendarOpen={() => onDatePickerChange(selectedDate)}
            onMonthChange={onDatePickerChange}
            onSelect={onDateSelectFromDatePicker}
            onClickOutside={clickedOutside}
            onInputClick={toggleDatePicker}
        />
    )
}

export default CustomDatePicker;

const StyledCustomDay = styled("div")({
    "&.DatePicker_StyledCustomDay": {
        position: "relative",
        color: "#FFFFFFCC",
        fontWeight: 400,
        fontSize: "0.875rem",
        borderRadius: "100%",
        width: "2rem",
        height: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transition: "background-color 0.3s ease-in-out",
        "&.event-date span": {
            position: "absolute",
            width: "0.25rem",
            height: "0.25rem",
            backgroundColor: "#F5686F",
            right: 3,
            top: 6,
            borderRadius: "100%",
        },
        "&.today": {
            border: "1px solid #F5686F",
        },
        "&.event-date.past span": {
            backgroundColor: "#808080"
        },
        "&.selected.event-date span, &.today.event-date span": {
            display: "none"
        },
        "&.selected": {
            backgroundImage: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
            backgroundColor: "#F5686F",
        },
        "&:hover": {
            fontWeight: 500,
            backgroundImage: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
            backgroundColor: "#F5686F",
            borderRadius: "100%",
            color: "#ffffff",
            "& span": {
                display: "none"
            }
        },
    }
})