import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/styles";
import moment from "moment";
import { IconButton } from "@material-ui/core";
interface Props {
    selectedDate: Date;
    onChangeDate?: (event: Date) => void;
}


const WeekDayComponent: React.FC<Props> = (props: Props) => {
    const {
        selectedDate,
        onChangeDate
    } = props;

    // Start the week with the current date
    const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf('week'));


    // Get the current month to check if a day belongs to it
    const currentMonth = moment(moment().startOf('week')).month();
    const currentYear = moment(moment().startOf('week')).year();

    // Function to get the days of the current week with desired structure
    const getWeekDays = () => {
        const days = [];
        for (let i = 0; i < 7; i++) {
            const date = moment(currentWeekStart).add(i, 'days');
            days.push({
                date: date.toDate(),
                day_of_month: date.format('DD'),
                day: date.format('dddd'),
                isCurrentMonth: currentMonth === date.month() && currentYear === date.year()
            });
        }
        return days;
    };

    // Handler to go to the previous week
    const goToPreviousWeek = () => {
        setCurrentWeekStart(prev => moment(prev).subtract(1, 'week'));
    };

    // Handler to go to the next week
    const goToNextWeek = () => {
        setCurrentWeekStart(prev => moment(prev).add(1, 'week'));
    };

    // Handler to select a date
    const handleDateClick = (date: Date) => {
        onChangeDate?.(date); // Set the clicked date as the selected date
    };


    // Effect to update the week when the parent-selected date changes
    useEffect(() => {
        if (selectedDate) {
            const newWeekStart = moment(selectedDate).startOf('week');
            setCurrentWeekStart(newWeekStart); // Reset the week to start from the new selected date's week
        }
    }, [selectedDate]);
    return (
        <StyledWeekDayComponent className="WeekDayComponent_StyledWeekDayComponent">
            <div className="prev">
                <IconButton onClick={goToPreviousWeek}>
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.00002 11.6514L0.346191 5.99758L6.00002 0.34375L7.05384 1.39758L2.45384 5.99758L7.05384 10.5976L6.00002 11.6514Z" fill="white" />
                    </svg>
                </IconButton>
            </div>
            <div className="weekdays">
                {
                    getWeekDays().map(weekday => {
                        return (
                            <div
                                className={`weekday ${weekday.date.toDateString() === selectedDate.toDateString() ? "selected" : ""} ${!weekday.isCurrentMonth ? "disabled" : ""}`}
                                key={weekday.date.toDateString()}
                                onClick={() => handleDateClick(weekday.date)}
                            >
                                <p className="day_of_month">{weekday.day_of_month}</p>
                                <p className="day">{weekday.day}</p>
                            </div>
                        )
                    })
                }
            </div>
            <div className="next">
                <IconButton onClick={goToNextWeek}>
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.00001 0.348599L7.65384 6.00242L2.00001 11.6562L0.946188 10.6024L5.54619 6.00242L0.946188 1.40242L2.00001 0.348599Z" fill="white" />
                    </svg>
                </IconButton>
            </div>
        </StyledWeekDayComponent>
    )
}

const StyledWeekDayComponent = styled("div")({
    "&.WeekDayComponent_StyledWeekDayComponent": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "2rem",
        "& > .weekdays": {
            display: "flex",
            alignItems: "center",
            overflow: "auto",
            "& > .weekday": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
                height: "5.5625rem",
                gap: "0.5rem",
                padding: "0rem 1rem",
                boxSizing: "border-box",
                cursor: "pointer",
                fontSize: "1rem",
                fontWeight: 400,
                "& > .day_of_month": {
                    color: "rgba(255, 255, 255, 1)"
                },
                "& > .day": {
                    color: "rgba(255, 255, 255, 0.7)"
                },
                "&.selected": {
                    border: "2px solid rgba(244, 114, 115, 1)",
                    borderRadius: "1rem"
                },
                "&.disabled": {
                    "& > .day_of_month": {
                        opacity: 0.7
                    }
                }
            }
        }
    }
})

export default WeekDayComponent;